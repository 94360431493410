import React, { useMemo, useCallback } from 'react';
import axios from 'axios';
import {isMobile} from 'react-device-detect';
import { AreaClosed, BarGroup, LinePath, Bar, Line } from '@visx/shape';
import { SeriesPoint } from '@visx/shape/lib/types';
import { Group } from '@visx/group';
import { curveMonotoneX } from '@visx/curve';
import { AxisBottom, AxisLeft, AxisRight } from '@visx/axis';
import cityTemperature, { CityTemperature } from '@visx/mock-data/lib/mocks/cityTemperature';
import { scaleTime, scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { localPoint } from '@visx/event';
import { timeParse, timeFormat } from 'd3-time-format';
import { max, extent, bisector } from 'd3-array';
import { withTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';

import {
  Legend,
  LegendLinear,
  LegendQuantile,
  LegendOrdinal,
  LegendSize,
  LegendThreshold,
  LegendItem,
  LegendLabel,
} from '@visx/legend';

import { LinearGradient } from '@visx/gradient';

import TooltipModal from './TooltipModal';
import tooltips from './assets/tooltips'

const color1 = '#0A9393';
const color2 = '#F97167';
const color3 = '#EDD926';
const color4 = '#A5230E';
const color5 = '#111111';
const background = '#333333';
const background2 = '#72421A';
const accentColor = '#2eff8b';
const accentColorDark = '#75daad';
const defaultMargin = { top: 40, left: 50, right: 40, bottom: 80 };
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

export const greens = ['#ecf4f3', '#68b0ab', '#006a71'];

function addCommas(nStr)
{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

const olddata = cityTemperature.slice(0, 12);

const legendGlyphSize = 15;

export default class PetrolGeneralUnifiedChart extends React.Component {
    constructor(props) {
      super(props);
    }

    state = {
        isLoaded: false,
        width: isMobile ? window.innerWidth - 40 : window.innerWidth - 100,
        height: 400,
    }

    async getData() {

        let res = await axios.get(`https://collector.oiegt.org/api/petroleo-unificado`);

        let finalData = [];

        let finalDataBarriles = [];

        if (res.data.length > 0) {
            const keys = Object.keys(res.data[0]).filter(function(d) {
                if (d !== 'mes' && d !== 'id_contrato') {
                    return d;
                }
            });

            let data = res.data.map(function(item) {
                let newItem = {}
                keys.forEach(function(key) {
                    newItem[key] = item[key];
                });
                return newItem;
            });

            let years = [...new Set(data.map(item => item.anio))];
            years.sort();

            years.forEach(function(year) {
                let singleYear = data.filter(function(item) {
                    if (item.anio === year) {
                        return item;
                    }
                });
                let newItem = {
                    anio: year
                }
                keys.forEach(function(key) {
                    if (key !== "anio" && key !== "total_barriles") {
                        let singleYearProfit = singleYear.reduce((acc, obj) => {
                            acc += Number(obj[key]);
                            return acc;
                        }, 0);
                        newItem[key] = singleYearProfit;
                    }
                });

                finalData.push(newItem);
            });

            years.forEach(function(year) {
                let singleYear = data.filter(function(item) {
                    if (item.anio === year) {
                        return item;
                    }
                });
                let newItem = {
                    anio: year
                }
                keys.forEach(function(key) {
                    if (key !== "anio" && key !== "ingresos" && key !== "costos_recuperables") {
                        let singleYearProfit = singleYear.reduce((acc, obj) => {
                            acc += Number(obj[key]);
                            return acc;
                        }, 0);
                        newItem[key] = singleYearProfit;
                    }
                });

                finalDataBarriles.push(newItem);
            });

        }
        this.setState({
            isLoaded: true,
            dataset: res.data,
            finalData: finalData,
            finalDataBarriles: finalDataBarriles
        });
    }

    componentDidMount() {
        this.getData();
    }

    render () {
        if (this.state.isLoaded) {
            if (this.state.dataset.length > 0) {
                return (
                    <ChartWithTooltip data={this.state.finalData} dataBarriles={this.state.finalDataBarriles} width={this.state.width} height={this.state.height} />
                )

            }
            else {
                return (
                    <div className="still-looking">
                        <img src={require('./assets/images/oie-stilllooking.svg')} />
                    </div>
                )
            }
        }
        else {
            return (
                <div>Cargando...</div>
            )
        }
    }
}

const ChartWithTooltip = withTooltip(
  ({
    data,
    dataBarriles,
    width,
    height,
    events = true,
    margin = defaultMargin,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  }) => {

      const keys = Object.keys(data[0]).filter(function(d) {
          if (d !== 'anio' && d !== 'mes') {
              return d;
          }
      });

      const totals = data.reduce((obj, val) => {
        const totalSingle = keys.reduce((total, i) => {
          total += Number(val[i]);
          return total;
        }, 0);
        obj.push(totalSingle);
        return obj;
      }, []);

      // accessors
      const getDate = (d) => d.anio;

      const cityScale = scaleBand({
        domain: keys,
        padding: 0.1,
      });
      // scales
      const yScale = scaleLinear({
        domain: [0, Math.max(...totals)],
        nice: true,
      });

      const xScale = scaleBand({
        domain: data.map(getDate),
        padding: 0.2,
      });

      const colorScale = scaleOrdinal({
        domain: keys,
        range: [color1, color2, color3],
      });

      let keysCombined = [...keys];
      keysCombined.push("Barriles");

      const colorScaleCombined = scaleOrdinal({
          domain: keysCombined,
          range: [color1, color2, "#000000"]
      })

      let tooltipTimeout: number;

      const getStockValue = (d) => d.total_barriles;


    // bounds
    const xMax = width - margin.left - margin.right - 70;
    const yMax = height - margin.top - margin.bottom;

    const yLineScale = useMemo(
        () =>
        scaleLinear({
            range: [yMax, 0],
            domain: [0, (max(dataBarriles, getStockValue) || 0) + yMax / 3],
            nice: true,
        }),
        [0, yMax],
    );

    yScale.rangeRound([yMax, 0]);
    xScale.rangeRound([50, xMax]);
    cityScale.rangeRound([0, xScale.bandwidth()]);

    const ran = () => Math.random();

    let chartKey = ran();

    const dateScale = useMemo(
        () =>
        scaleTime({
            range: [margin.left, xMax + margin.left],
            domain: extent(data, getDate),
        }),
        [xMax, margin.left],
    );

    const bisectDate = bisector(d => d.anio).left;

    return width < 10 ? null : (
        <div>
            <div className="project-module-header">
                <h1 className="section-title white">Datos unificados</h1>
                <TooltipModal title="Grafica comparativa de ingresos, costos recuperables y producción" text={tooltips.petrol_unified} />
            </div>
          <svg width={width} height={height}>
              <LinearGradient id={`${chartKey}_area-background-gradient`} from={background} to={background2} />
              <LinearGradient id="area-gradient" from={accentColor} to={accentColor} toOpacity={0.1} />
              <rect width={width} height={height} fill={`url(#${chartKey}_area-background-gradient)`} rx={14} />
            <Group top={margin.top} left={margin.left}>
                <AreaClosed
                    data={dataBarriles}
                    x={d => xScale(getDate(d)) ?? 0}
                    y={d => yLineScale(getStockValue(d)) ?? 0}
                    yScale={yLineScale}
                    strokeWidth={2}
                    stroke={color4}
                />
                <AreaClosed
                    data={dataBarriles}
                    x={d => xScale(getDate(d)) ?? 0}
                    y={d => yLineScale(getStockValue(d)) ?? 0}
                    yScale={yLineScale}
                    strokeWidth={2}
                    stroke={color3}
                />
              <AxisBottom
                top={yMax}
                scale={xScale}
                stroke={color5}
                tickStroke={color5}
                tickLabelProps={() => ({
                  fill: color3,
                  fontSize: 11,
                  textAnchor: 'middle',
                  dy: '0.33em',
                })}
              />
              <AxisLeft
                  left={50}
                  label={"USD"}
                  labelOffset={70}
                scale={yScale}
                stroke={color1}
                tickStroke={color1}
                tickTransform={"translate(-30,0)"}
                tickLabelProps={() => ({
                  fill: color3,
                  fontSize: 11,
                  textAnchor: 'middle',
                })}
              />
          <AxisRight
              scale={yLineScale}
              left={xMax}
              numTicks={4}
              label="Barriles"
              labelOffset={70}
              />
            </Group>
          </svg>
          <div
            style={{
              position: 'absolute',
              top: margin.top / 2 - 10,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              fontSize: '14px',
            }}
          >
          </div>
          {tooltipOpen && tooltipData && (
            <Tooltip className="visxTooltip" top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
              <div style={{ color: colorScale(tooltipData.key) }}>
                <strong>{tooltipData.key.replace(/_/g, " ")}</strong>
              </div>
              <div>USD {addCommas(tooltipData.value.toFixed(2))}</div>
              <div>
                <small>{tooltipData.year}</small>
              </div>
            </Tooltip>
          )}

          <LegendDemo title="Indicadores">
              <LegendOrdinal scale={colorScaleCombined} labelFormat={label => `${label.toUpperCase()}`}>
                  {labels => (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                          {labels.map((label, i) => (
                              <LegendItem
                                  key={`legend-quantile-${i}`}
                                  margin="0 5px"
                                  onClick={() => {
                                      if (events) alert(`clicked: ${JSON.stringify(label)}`);
                                  }}
                                  >
                                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                                      <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                                  </svg>
                                  <LegendLabel align="left" margin="0 0 0 4px">
                                      {label.text.replace(/_/g, " ")}
                                  </LegendLabel>
                              </LegendItem>
                          ))}
                      </div>
                  )}
              </LegendOrdinal>
          </LegendDemo>

        </div>
    );
  },
);

function LegendDemo({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <div className="visxLegend">
      <div className="visxLegendTitle">{title}</div>
      {children}
    </div>
  );
}

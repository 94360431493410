import React from 'react';    
import PropTypes from 'prop-types';  
import './SourceBox.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';

class SourceBox extends React.Component {
  constructor(props) {
    super(props);
    this.sourceFeedback = React.createRef();
  }

  state = {
    copied: false,
    fade: "fadeOut"
  }

  showFeedback = () => {
    const sourceFeedback = this.sourceFeedback.current;

    sourceFeedback.style.display = "flex";

    this.timer = setTimeout(() => {
      sourceFeedback.style.display = "none";
    }, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {

    return(
      <div>
        <div className="sourceFeedback" ref={this.sourceFeedback}>
          <div className="sourceFeedbackInner">
            <p>Información copiada</p>
          </div>
        </div>
        <div className="source-wrapper">
          <CopyToClipboard text={this.props.source} onCopy={() => this.setState({copied: true})}>
            <p onClick={this.showFeedback} className="source-text"><b>Fuente: </b>{this.props.source} <span className="material-symbols-outlined">content_copy</span></p>  
          </CopyToClipboard>
        </div>
      </div>
    )  
 }
}    
export default SourceBox;  
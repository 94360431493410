import React from 'react';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  MarkSeries,
  Hint,
  ChartLabel,
  DiscreteColorLegend
} from 'react-vis';

import axios from 'axios';
import { toPng } from 'html-to-image';
import SourceBox from './SourceBox';
import {isMobile} from 'react-device-detect';
import TooltipModal from './TooltipModal'
import tooltips from './assets/tooltips';
import './colorLegend.css';

import { 
    primaryPalette,
    primaryBackground,
    secondaryBackground,
    primaryStroke
} from './Styles';

import { 
    addCommas
} from './Tools';

export default class ExtractionsMarkSeries extends React.Component {
    constructor(props) {
        super(props);
        this.graphToImg = React.createRef();
        this.graphHeader = React.createRef();
    }

    state = {
        dataset: {},
        perYear: [],
        legends: [],
        years: [],
        highest: 0,
        vbs: [],
        value: {x:null, y:null, label: ""},
        hoveredCell: false
    }

    async getData() {
        let res = await axios.get(`https://collector.oiegt.org/api/extracciones/${this.props.projectId}`);

        const perYear = [];
        var printables = [];
        let legends = new Set();
        for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < res.data[i].informacion_explotacion.length; j++) {
                var anio = res.data[i].anio;
                var label = res.data[i].informacion_explotacion[j].producto_minero;
                legends.add(label)
                var cantidad = res.data[i].informacion_explotacion[j].cantidad_extraida;
                cantidad = cantidad !== null ? cantidad : 100;
                var size = (res.data[i].informacion_explotacion[j].cantidad_extraida)/100;
                size = size !== null ? size : 100;
                var costo = res.data[i].informacion_explotacion[j].costo_extraccion_quetzales;
                costo = costo !== null ? costo : "Información no disponible";
                perYear.push({x: anio, y: cantidad, size: size, costo: costo, label: label, color: ""});

            }
        }

        for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < res.data[i].informacion_explotacion.length; j++) {
                var anio = res.data[i].anio;
                var label = res.data[i].informacion_explotacion[j].producto_minero;
                var cantidad = res.data[i].informacion_explotacion[j].cantidad_extraida;
                cantidad = cantidad !== null ? cantidad : "Información no disponible";
                var size = res.data[i].informacion_explotacion[j].costo_extraccion_quetzales;
                size = size !== null ? size : "Información no disponible";
                var costo = res.data[i].informacion_explotacion[j].costo_extraccion_quetzales;
                costo = costo !== null ? `Q. ${addCommas(costo.toFixed(2))}` : "Información no disponible";
                printables.push(
                    <div key={`prtbl_${i}_${j}`}>
                        <h2>{anio}</h2>
                        <p>Cantidad: {cantidad}</p>
                        <p>Tamaño: {size}</p>
                        <p>Costo: {costo}</p>
                    </div>
                );
            }
        }

        legends = Array.from(legends)
        const formattedLegends = legends.map((x, index) => ({title: (x === ""? "No disponible":x), color:primaryPalette[index], strokeWidth:10}))
        for (let i = 0; i < perYear.length; i++){
            for (let j = 0; j < formattedLegends.length; j++){
                if(perYear[i].label === ""){
                    perYear[i].label = "No disponible"
                    if (perYear[i].label === formattedLegends[j].title){
                        perYear[i].color = formattedLegends[j].color;
                    }
                } else
                if (perYear[i].label === formattedLegends[j].title){
                    perYear[i].color = formattedLegends[j].color;
                }
            }
        }

        this.setState({
            dataset: res.data,
            perYear: perYear,
            printables: printables,
            legends: formattedLegends,
        });
    }

    componentDidMount() {
        this.getData();
    }

    graphToImgClick = () => {
        const graph = this.graphToImg.current;

        const header = this.graphHeader.current;

        header.style.display = "flex";

        let graphClone = graph.cloneNode(true);
        let headerClone = header.cloneNode(true);

        let div = document.createElement("div");

        div.appendChild(headerClone);
        div.appendChild(graphClone);

        let yyyy = new Date().YYYYMMDDHHMMSS();

        toPng(graph)
          .then((dataUrl) => {
            const link = document.createElement('a')
            // link.download = this.state.sitio + "_" + this.state.title + '.png'
            link.download = 'OIE_regalias_voluntarias_' + this.props.projectId + "_" + yyyy + '.png'
            link.href = dataUrl
            link.click()
            header.style.display = "none";
          })
          .catch((err) => {
            console.log(err)
        });
    }

    render() {
        const {hoveredCell} = this.state;
        const {perYear} = this.state;
        let legendWidth = 0;
        if (isMobile) {
            legendWidth = window.innerWidth;
        }
        else {
            legendWidth = (window.innerWidth - 100) / 2;

        }
        return (
            <div>
                <div className="project-module-header">
                    <h1 className="section-title white">Extracciones</h1>
                    <TooltipModal title="Extracciones" text={tooltips.tooltip_extracciones} />
                </div>
                <div ref={this.graphToImg} className="graphWrapper">
                    <div className="graphHeader" ref={this.graphHeader}>
                        <img src={require("./assets/images/oie-logo_big.svg")} width="120px" />
                        <div className="graphTitle">
                            Extracciones {this.props.projectId}
                        </div>
                    </div>
                    <div className="graphContainer">
                        {perYear.length > 0 ?
                            (
                                <XYPlot
                                    margin={{left: 100, top: 50, bottom: 50}}
                                    width={window.innerWidth - 200}
                                    height={window.innerHeight - 250}
                                    xType="ordinal"
                                    className="vis"
                                >
                                    <VerticalGridLines />
                                    <HorizontalGridLines />
                                    {/* Cambios a los AXES para tener Labels VER DOC DE ChartLabel*/}
                                    <XAxis />
                                    <YAxis />
                                    <ChartLabel
                                    text="Año"
                                    className="alt-x-label"
                                    includeMargin={true}
                                    xPercent={0.5}
                                    yPercent={0.77}
                                    style={{
                                        textAnchor: "start",
                                        fontWeight: "bold",
                                        fontSize: 16,
                                        }}
                                    />
                                    <ChartLabel
                                    text="Toneladas Métricas"
                                    className="alt-y-label"
                                    includeMargin={true}
                                    xPercent={0.015}
                                    yPercent={0.35}
                                    style={{
                                    textAnchor: "start",
                                    fontWeight: "bold",
                                    transform: 'rotate(-90)',
                                    fontSize: 16,
                                    }}
                                    />
                                    <MarkSeries
                                        sizeRange={[10, 25]}
                                        style={{stroke: 'white'}}
                                        colorType="literal"
                                        getNull={(d) => d.y !== null}
                                        data={this.state.perYear}
                                        onValueMouseOver={v =>
                                            this.setState({hoveredCell: v.x && v.y ? v : false})
                                        }
                                        onValueMouseOut={v =>
                                            this.setState({hoveredCell: false})
                                        }
                                    />
                                    {hoveredCell ? (
                                        <Hint
                                            value={this.state.hoveredCell}
                                            align={{horizontal: 'auto', vertical: 'auto'}}
                                        >
                                            <div className="hint">
                                                <h5>{hoveredCell.label}</h5>
                                                <p>
                                                    Cantidad extraída: <br/>
                                                    <span>{hoveredCell.y == 100 ? "Información no disponible" : addCommas(hoveredCell.y.toFixed(2))}</span> TM
                                                </p>
                                                <p>
                                                    Costo de extracción (en Q.): <br/>
                                                    <span>{hoveredCell.costo}</span>
                                                </p>
                                            </div>
                                        </Hint>
                                    ) : null}
                                </XYPlot>
                            ) :
                            (
                                <div className="still-looking">
                                    <img src={require('./assets/images/oie-stilllooking.svg')} />
                                </div>
                            )
                        }
                        {/* Agregando color Legend*/}
                        <DiscreteColorLegend
                            className="colorLegend"
                            width={legendWidth}
                            items={this.state.legends}
                            orientation="vertical"
                        />
                    </div>
                </div>    
                <SourceBox source={tooltips.source_mem} />
                <div className="printables">
                    {this.state.printables}
                </div>
                <a className="graphToImgBtn downloadBtn" onClick={this.graphToImgClick}>Descargar gráfica <span className="material-symbols-outlined">file_download</span></a>
            </div>
        );
    }
}

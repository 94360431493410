import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import DataTable from 'react-data-table-component';
import tooltips from './assets/tooltips';
import TooltipModal from "./TooltipModal";



const paginationOptions = {
    rowsPerPageText: 'No. items por pag.:',
    rangeSeparatorText: 'de',
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: 'All'
}

const customStyles = {
    table: {
        style: {
            color: "#2eff8b",
            backgroundColor: "#003c3c",
        },
    },
    header: {
        style: {
            fontSize: '3em',
            fontWeight: '200 !important',
            color: "#2eff8b",
            backgroundColor: "#003c3c",
            minHeight: '56px',
            paddingLeft: '16px',
            paddingRight: '8px',
        },
    },
    subHeader: {
        style: {
            fontWeight: 200,
        },
    },
    head: {
        style: {
            fontWeight: 200,
        },
    },
    headRow: {
        style: {
            backgroundColor: "#003c3c",
            minHeight: '56px',
            borderBottomWidth: '1px',
            borderBottomColor: "#2eff8b",
            borderBottomStyle: 'solid',
        },
        denseStyle: {
            minHeight: '32px',
        },
    },
    headCells: {
        style: {
            fontSize: '12px',
            fontWeight: 500,
            color: "#FFF",
            paddingLeft: '16px',
            paddingRight: '16px',
        },
        activeSortStyle: {
            color: "#FFF",
            '&:focus': {
                outline: 'none',
            },
            '&:hover:not(:focus)': {
                color: "#FFF",
            },
        },
        inactiveSortStyle: {
            '&:focus': {
                outline: 'none',
                color: "#FFF",
            },
            '&:hover': {
                color: "#FFF",
            },
        },
    },
    rows: {
        style: {
            color: "#2eff8b",
            backgroundColor: "#003c3c",
        }
    },
    pagination: {
        style: {
            color: "#2eff8b",
            fontSize: '13px',
            minHeight: '56px',
            backgroundColor: "#003c3c",
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
        },
        pageButtonsStyle: {
            borderRadius: '50%',
            height: '40px',
            width: '40px',
            padding: '8px',
            margin: 'px',
            cursor: 'pointer',
            transition: '0.4s',
            color: "#2eff8b",
            fill: "#2eff8b",
            backgroundColor: 'transparent',
            '&:disabled': {
                cursor: 'unset',
                color: "#2eff8b",
                fill: "#CCC",
            },
            '&:hover:not(:disabled)': {
                backgroundColor: "#003c3c",
            },
            '&:focus': {
                outline: 'none',
                backgroundColor: "#003c3c",
            },
        },
    },
};

const columns = [
    {
        name: 'Film No.',
        selector: 'film_number',
        sortable: true,
    },
    {
        name: 'Corporación',
        selector: 'company_id',
        sortable: true,
    },
    {
        name: 'Fecha de publicación',
        selector: 'filing_date',
        sortable: true,
    },
    {
        name: 'Tipo de publicación',
        selector: 'filing_type',
        sortable: true
    },
    {
        name: 'Enlace de publicación',
        button: true,
        cell: row => <a href={row.href} target="_blank" rel="noopener noreferrer">Link</a>,
        right: true
    }
];

export default class CorporationsSEC extends React.Component {
    state = {
        isLoaded: false
    }

    constructor(props) {
        super(props);
    }

    async getData() {
        let res = await axios.get(`https://collector.oiegt.org/api/sec`);

        this.setState({
            dataset: res.data,
            isLoaded: true
        });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        let isLoaded = this.state.isLoaded;
        return (
            isLoaded ?
            <div id="SEC">
                <div className="project-module-header">
                    <h1 className="section-title">Security Exchange Commission <TooltipModal title="Informes Corporativos de la Comisión de Seguridad de la Bolsa (SEC)" text={tooltips.tooltip_sec} /></h1>
                </div>
                <DataTable
                    title="Security Exchange Commision"
                    noHeader={true}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    overflowY={true}
                    overflowYOffset={"200px"}
                    columns={columns}
                    data={this.state.dataset}
                    customStyles={customStyles}
                />
            </div>:
            <div>Cargando...</div>
        )
    }
};

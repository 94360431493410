module.exports = {
  source_empleados: "OIE. (2022). Con datos del Ministerio de Energía y Minas, Ministerio de Ambiente y Recursos Naturales  e Instituto Guatemalteco de Seguridad Social. Guatemala.",
  source_empleados_igss: "OIE. (2022).  Con datos del Instituto Guatemalteco de Seguridad Social, Guatemala.",
  source_mem: "OIE. (2022).  Con datos Informe de producción de proyectos extractivos presentado anualmente al Ministerio de Energía y Minas. Guatemala.",
  source_licensing: "OIE. (2022).  Vizualización realizada con datos del expediente del proyecto extractivo, Ministerio de Energía y Minas. Guatemala.",
  source_petrol_earnings: "OIE (2020).  Cajas Fiscales, Ministerio de Energía y Minas. Guatemala.",
  source_petrol_recoverablecosts: "OIE (2020).  Cajas Fiscales, Ministerio de Energía y Minas. Guatemala.",
  source_petrol_production: "OIE (2020). Ministerio de Energía y Minas. Guatemala. ",
  source_petrol_unified: "OIE (2020). Ministerio de Energía y Minas. Guatemala. ",
  tooltip_regalias: `Las regalías son los pagos anuales que las empresas extractivas realizan a la municipalidad o municipalidades en donde extraen los minerales y al Estado central por el producto minero comercializado que extraen y exportan. El cálculo de las regalías se determina en base a una declaración jurada de cuánto producto una empresa exporta, de eso el 1% del valor de mercado de esos metales va 0.5% a nivel municipal y 0.5% a nivel central (<a href="https://www.mem.gob.gt/wp-content/uploads/2015/06/1._Ley_de_Mineria_y_su_Reglamento.pdf" target="_blank">Ley de Minería</a>, Art. 61-64).`,
  tooltips_regalias_voluntarias: `Las regalías denominadas como voluntarias son producto del Acuerdo Marco de Entendimiento (<a href="https://www.mem.gob.gt/wp-content/uploads/2015/06/1._Ley_de_Mineria_y_su_Reglamento.pdf" target="_blank">Acuerdo Gubernativo No. 105-2012</a>) para el pago de aporte adicional y voluntario entre el gobierno central y la gremial de industrias extractivas (GREMIEXT). Las regalías equivalen al 4% del valor del producto minero comercializado. Un 3% al estado central, el 1% restante se divide en un 80% a las municipalidades, 10% al Ministerio de Energía y Minas y 10% al Ministerio de Ambiente y Recursos Naturales.`,
  tooltip_canones: "Los cánones son pagos que las empresas extractivas que adquieren un derecho minero de reconocimiento, exploración y explotación hacen al Ministerio de Energía y Minas. Se pagan cánones por el otorgamiento, por la superficie en kilómetros cuadrados del derecho otorgado o cesión de un derecho minero.",
  tooltip_exportaciones: "Las exportaciones de los productos explotados provienen de los informes de producción que las empresas extractivas reportan anualmente. Podrás observar el destino de exportación de los diferentes metales, la cantidad que se exporta de cada uno de ellos y el precio de venta.",
  tooltip_extracciones: "Esta visualización te permite obtener datos sobre las extracciones por metal y ver por año el volumen total extraído por cada metal.",
  tooltip_otorgamiento: "El proceso de otorgamiento de licencias es administrado por el Viceministerio de Energía y Minas y la Dirección General de Minería. Estas son las entidades gubernamentales responsables del otorgamiento y regulación de la minería en Guatemala. Esta visualización permite comparar los momentos de toma decisión regulados en la Ley de minería frente a cuánto demoran estos procesos en la práctica.",
  tooltip_empleados: "La visualización de empleados nos permite comparar datos de tres diferentes fuentes oficiales de los empleados reportados por año de un mismo proyecto extractivo.",
  tooltip_empleados_igss: "Esta gráfica te permitirá tener un desglose mensual de empleados  de la empresa nacional y filtrarlas por año.",
  tooltip_empleados_general: "La visualización de empleados nos permite ver la cantidad de empleados por año del sector extractivo, es decir, de todas las empresas que tienen proyectos extractivos vigentes a lo largo de los años y por cada una de las empresas.",
  tooltip_sec: `<p>Las corporaciones participan en múltiples bolsas de valores a nivel global. En el OIE estamos dando seguimiento a las publicaciones que estas corporaciones realizan en la Comisión de Seguridad de la Bolsa o SEC  por sus siglas en Inglés.</p>

  <p>Todas las corporaciones  que tienen al menos $10 millones  y que tienen más de 500 inversionistas o propietarios están obligadas a presentar informes anuales y otros formularios que tienen que ver con su desempeño general y financiero. Estos informes están disponibles para el público a través de la base de datos EDGAR de la SEC <a href='https://www.sec.gov/edgar/search-and-access' target='_blank'>https://www.sec.gov/edgar/search-and-access</a></p>

  <p><i>El OIE seleccionó 3 formularios importantes: </i></p>

  <p><strong>I. Formulario 13F: </strong></p>

  <p>Este es presentado por los administradores de inversiones institucionales, es decir aquellos que son fondos grandes de inversión como algunos fondos de pensión, de conformidad con la Sección 13 (f) de la Ley de Bolsa de Valores de 1934.</p>

   <p><strong>Las presentaciones del formulario 13F deben incluir:</strong></p>

  <p>El nombre del emisor de todos los valores de la Sección 13 (f) (que deben enumerarse en orden alfabético);
  Una descripción de la clase de valor listado (por ejemplo, acciones ordinarias, opción de compra / venta, acciones de clase A, obligaciones convertibles);
  El número de acciones que posee; y
  El valor justo de mercado de los valores cotizados, al final del trimestre calendario.</p>

  <p><strong>II. Formulario 6-K</strong></p>

  <p>El formulario 6-K, es utilizado por  emisores privados extranjeros (FPI), es decir, empresas que son de otros países pero que tienen parte de su operación basada en Estados Unidos. Este formulario es utilizado para actualizar de manera  continua sobre noticias corporativas. </p>

  <p><strong>III. Formulario 20F o 40k</strong></p>

  <p>Estos son reportes anuales de los emisores privados extranjeros.</p>`,
  puertas_giratorias: "Las puertas giratorias pueden ser definidas como «la posible influencia corporativa en la arena política que no implica el intercambio de bienes tangibles o intangibles, sino el movimiento de personas. Los empleados corporativos pueden y ocupan puestos en las ramas ejecutivas del gobierno. Por el contrario, al completar su servicio, muchos ex funcionarios del gobierno encuentran empleo en el mundo corporativo» (Etzion y Davis, 2008:157).",
  tooltip_commodities: "",

  tooltip_petrolero: `“La industria petrolera en Guatemala incluye todos aquellos procesos de exploración, extracción, refinamiento, transformación, transporte y comercialización de petróleo y productos derivados (MEM, 2007).”`,

  petrol_earnings: `<p>Los ingresos a la Nación con relación a la producción de hidrocarburos incluyen las siguientes categorías: Regalías, Regalías especiales, Participación Estatal, Participación Estatal Especia y otros ingresos.</p>

  <p><strong>Regalías</strong></p>
  <p>Según el artículo 61 de la Ley de Hidrocarburos, y para petróleo proveniente de yacimientos comerciales, las regalías son aplicables a la producción neta5 o al valor monetario de esta, y están basadas en el promedio mensual de gravedad API6, de la siguiente forma:</p>

  <p><strong>a)</strong> Para crudo con 30º API, la regalía será de 20%.</p>
  <p><strong>b)</strong> A este valor del 20% anterior, se le incrementará o disminuirá 1% por cada grado API que aumente o disminuya respectivamente, con respecto a los 30º API.</p>
  <p><strong>c)</strong> Regalía no puede ser menor al 5%.</p>

  <p><strong>Regalías especiales</strong></p>

  <p>Regalía que proviene de yacimientos en los cuales se produzca petróleo y no se haya hecho una declaración de comercialidad, los contratistas deben pagar el 35% de la producción neta desde que se inició la producción hasta que se declare la comercialidad del yacimiento.</p>

  <p><strong>Participación Estatal</strong></p>

  <p>A partir de los Hidrocarburos Compartibles se determina la Participación Estatal y la Participación del Contratista de la siguiente forma, según lo establece el artículo 66 de la Ley de Hidrocarburos: La participación estatal será, como mínimo, de un 30% de los Hidrocarburos Compatibles en cada área de explotación, que podrá aumentar con base en la tasa de producción o al valor monetario de los hidrocarburos, de acuerdo con las escalas que se establezcan para cada tipo de hidrocarburos en los contratos.</p>

  <p><strong>Participación Estatal Especial</strong></p>

  <p>El reglamento de la Ley de Hidrocarburos en su artículo 214, define la Participación Estatal Especial como la “producción proveniente de cualquier descubrimiento no declarado comercial, en virtud de que no se pueden atribuir costos recuperables a dicha producción por no existir área de explotación”. La determinación del monto de la Participación Estatal Especial en estos casos se realiza con base a la escala de participación estatal en la producción de petróleo crudo establecida en el respectivo contrato.</p>`,

  petrol_recoverable_costs: `<p>“Los Costos Recuperables son definidos en el Reglamento de la Ley de Hidrocarburos como “todos los desembolsos en costos de capital, exploración, explotación y desarrollo, gastos de operación atribuible al área de contrato y los gastos administrativos, donde se convenga la recuperación de estos”. Estos costos recuperables son devueltos por el Estado de Guatemala a los Contratistas, e incluyen 43 rubros siguientes:</p>

  <p><strong>1</strong> Sueldos y Salarios.</p>
  <p><strong>2</strong> Sueldos transitorios y por contrato.</p>
  <p><strong>3</strong> Tiempo extraordinario.</p>
  <p><strong>4</strong> Prestaciones laborales que dicte las leyes vigentes de la materia.</p>
  <p><strong>5</strong> Honorarios médicos para atención de la clínica establecida en el área de contrato.</p>
  <p><strong>6</strong> Seguros de vida y médico.</p>
  <p><strong>7</strong> Geología y geoquímica.</p>
  <p><strong>8</strong> Geofísica.</p>
  <p><strong>9</strong> Carreteras.</p>
  <p><strong>10</strong> Logística y transporte.</p>
  <p><strong>11</strong> Perforación, Obra Civil, Fluidos de perforación, Registros de pozo, Cementación de pozo, Prueba de pozo.</p>
  <p><strong>12</strong> Completación de pozos.</p>
  <p><strong>13</strong> Reacondicionamiento de pozos.</p>
  <p><strong>14</strong> Mantenimiento de instalaciones, accesos, equipo y maquinaria del área de contrato.</p>
  <p><strong>15</strong> Primas de Seguros y Fianzas a favor del Estado.</p>
  <p><strong>16</strong> Consultoría legal que no sea para dirimir procesos contra el Estado o asuntos laborales.</p>
  <p><strong>17</strong> Consultoría de auditoria interna y fiscal.</p>
  <p><strong>18</strong> Gastos de alimentación en los campamentos del área de contrato.</p>
  <p><strong>19</strong> Vestuario para trabajadores de campo.</p>
  <p><strong>20</strong> Equipo y accesorios para seguridad industrial.</p>
  <p><strong>21</strong> Productos metálicos.</p>
  <p><strong>22</strong> Productos no metálicos.</p>
  <p><strong>23</strong> Productos químicos.</p>
  <p><strong>24</strong> Productos medicinales y farmacéuticos para el área de contrato.</p>
  <p><strong>25</strong> Costo de Combustibles y lubricantes para maquinaria, equipo y vehículos para las operaciones del área de contrato, registrados como costos de capital, excluye el Impuesto al Valor Agregado.</p>
  <p><strong>26</strong> Costos de capital de campo y oficinas centrales debidamente registrados.</p>
  <p><strong>27</strong> Adquisición y renta de edificios.</p>
  <p><strong>28</strong> Gastos de medio ambiente.</p>
  <p><strong>29</strong> Gastos administrativos dentro de la República, para la realización de funciones de planificación, organización, integración y control a cargo de la administración se reconocerá hasta el ochenta por ciento (80%) de los mismos y distribuidos conforme el Anexo Contable.</p>
  <p><strong>30</strong> Gastos administrativos fuera de la República incurridos por la Casa Matriz del Contratista por administración y gerencia el uno por ciento (1%) calculados sobre la totalidad de los costos recuperables aprobados y ejecutados.</p>
  <p><strong>31</strong> Maquinaria y equipo de exploración y producción, arrendamiento y/o adquisición.</p>
  <p><strong>32</strong> Maquinaria y equipo para obra civil a ser usado en el área de contrato, arrendamiento y/o adquisición.</p>
  <p><strong>33</strong> Equipo de almacenaje y distribución.</p>
  <p><strong>34</strong> Equipo médico–quirúrgico.</p>
  <p><strong>35</strong> Equipo de ingeniería.</p>
  <p><strong>36</strong> Equipo de laboratorio de uso en la exploración y/o explotación petrolera.</p>
  <p><strong>37</strong> Vehículos y transporte pesado, arrendamiento y/o adquisición, a ser usado en el área de contrato.</p>
  <p><strong>38</strong> Adquisición de equipo de computación con sus sistemas operativos utilitarios y software especializado relacionado con las actividades de exploración y/o explotación.</p>
  <p><strong>39</strong> Adquisición o renta de inmuebles para el uso de la operación.</p>
  <p><strong>40</strong> Capacitación y cargos anuales por hectárea.</p>
  <p><strong>41</strong> Contribuciones para el desarrollo de las comunidades ubicadas dentro del área de contrato del cero punto cinco por ciento (0.5%) del valor fiscal de la producción del año anterior.</p>
  <p><strong>42</strong> Estudios relacionados directamente con las operaciones petroleras objeto del contrato.</p>
  <p><strong>43</strong> Los costos de arbitraje, indicados en el artículo 242 de este Reglamento y en el contrato respectivo, si el resultado no es favorable al Estado.</p>`,

  petrol_production: "La producción nacional de hidrocarburos se divide en producción de crudo, y producción de derivados del petróleo",

  petrol_unified: "De 2009 al 2018 ha existido una disminución de la producción de petróleo, mientras que los ingresos a la Nación disminuyen a partir del 2011; en cuanto a los Costos Recuperables han sido mayores a los ingresos a la Nación a partir del año 2013. De lo anterior, puede decirse que a partir del 2013 la extracción de hidrocarburos representa pérdidas para el país, por lo que deberían considerarse lesivos para los intereses de la Nación.",

  calidad_de_agua: `
  <span>
      <strong>Arsénico:</strong> Partes por billón (ppb) que equivale a microgramo/litro (μg/L).<br />

      <strong>Cloro:</strong> El  cloro residual total directo se mide en unidades de ppm en un rango de 0.01ppm a 10ppm.<br />

      <strong>pH:</strong> Las unidades de pH son “logarítmicas” lo que significa que cada unidad que cambie implica un cambio de 10 veces su valor original.<br />

      <strong>Turbiedad:</strong> Se mide en NTU (Unidades Nefelométricas de Turbidez).<br />

      <strong>Conductividad eléctrica (CE):</strong> Se mide en microSiemens por centímetro μS/cm).
  </span>`,

  monitoreo_ph: `<p><strong>Unidad</strong></p>
  <p>pH</p>

  <p><strong>Descripción</strong></p>
  <p>El pH es una propiedad natural del agua. El pH se define en una escala logarítmica de 1 (lo más ácido) a 7 (neutro) a 14 (lo más básico). El pH se basa en la concentración de iones hidrógeno en solución. Así mismo, no es un contaminante, pero cuando el pH está fuera del rango recomendado, posee un riesgo para la salud humana y la ecología natural. Una medición de pH fuera de rango recomendado puede indicar un riesgo aumentado de la presencia de contaminantes adicionales en formas más riesgosas a la vida.</p>

  <p><strong>Riesgos humanos</strong></p>

  <p>Valores de pH más altos que 11 y menor a 4 pueden causar irritación y una sensación de ardor en los ojos y la piel de humanos. Un pH menor a 2.5 causa daño irreversible a los órganos incluyendo la piel. En niveles bajos ciertos metales tóxicos son más volátiles en el agua. Cuando el pH supera 8, la desinfección de agua con cloro es inútil. Niveles de pH extremo pueden dañar la infraestructura, como tubería Esto puede lixiviar metales desde la tubería al agua.</p>

  <p><strong>Riesgos para el medio ambiente</strong></p>

  <p>Cambios de pH más allá de los límites normales, pueden resultar en la muerte de organismos acuáticos. Los niveles de pH también afectan la solubilidad de químicos y metales en agua causando estrés ecológico.</p>`,

  monitoreo_conductividad: `<p><strong>Unidad</strong></p>
  <p>µS/cm</p>

  <p><strong>Descripción</strong></p>
  <p>La conductividad eléctrica indica la presencia de sólidos disueltos (ionizados) en agua incluyendo sales, usualmente a una temperatura estándar de 25°C. La conductividad eléctrica a veces se usa como un sustituto de sólidos disueltos totales y así determinar las sales totales contenidas en el agua. La conductividad eléctrica se afecta por la geología, así como la disolución de rocas, barro y tierra que contienen minerales en los arroyos. La alta conductividad eléctrica no necesariamente es un riesgo para la salud humana, pero puede causar un sabor metálico/mineral en el agua potable el cual no es deseable. Más importante, la alta conductividad y el agua corrosiva pueden degradar la infraestructura y/o la geología natural, liberando así , contaminantes posiblemente perjudiciales al medio ambiente.</p>

  <p><strong>Riesgos para el medio ambiente</strong></p>

  <p>La conductividad eléctrica puede indicar niveles de sal en agua. Cuando la salinidad aumenta, el oxígeno disuelto baja. Los organismos acuáticos de agua dulce no pueden tolerar niveles altos de salinidad y reducción de oxígeno disuelto durante periodos extendidos de tiempo.</p>`,

  monitoreo_arsenico: `<p><strong>Unidad</strong></p>
  <p>ppm=mg/l</p>

  <p><strong>Descripción</strong></p>
  <p>Para medir el arsénico se utilizó el Rapid Arsenic Test Kit#481304 por Industrial Test System.</p>

  <p><strong>Riesgos humanos</strong></p>

  <p>El arsénico puede entrar al cuerpo humano por contacto directo con la piel, ingestión, y/o inhalación. Niveles de arsénico inorgánico desde 0.0003-0.03 mg/L en el agua pueden causar irritación en el estómago e intestino, con síntomas que incluyen: dolor, náuseas, vómito y diarrea; también otros síntomas como fatiga, taquicardia, daño a los vasos sanguíneos resultando en moretones, función anormal de los nervios causando sensación de hormigueo en las manos y pies. El efecto a largo plazo más común de la exposición oral, es un patrón de cambios de color de la piel, incluyendo áreas de piel oscurecida y la apariencia de callos o verrugas pequeñas en las palmas de la, planta del pie y torso. La ingestión de arsénico aumenta el riesgo de cáncer en el hígado, pulmones y piel. La inhalación aguda de arsénico inorgánico (nivel exacto desconocido, probablemente más de 0.0001 mg/L) probablemente causará irritación en la garganta y pulmones. La exposición prolongada a concentraciones bajas pueden causar irritación de la piel y desórdenes del sistema nervioso. Algunos datos sugieren que la inhalación puede interferir con el desarrollo fetal. Asimismo la inhalación de arsénico puede aumentar el riesgo de cáncer en los pulmones.</p>

  <p><strong>Riesgos para el medio ambiente</strong></p>

  <p>El arsénico se puede biocaumular en el hígado y riñones de organismos de vida acuática, incluyendo peces; resultando en la disfunción de enzimas y del sistema inmunológico, causando diversas enfermedades. La exposición aguda de 5-15 mg/L puede causar la muerte dentro de las primeras 48 horas para ciertas especies de peces. El arsénico también se bioacumula en plantas y se puede transmitir al ser humano cuando se consume.</p>`,

  monitoreo_solidos: `<p><strong>Unidad</strong></p>
  <p>mg/L</p>

  <p><strong>Descripción</strong></p>
  <p>Los sólidos disueltos totales consisten en la suma de todas las partículas ionizadas superiores a 0.0002 cm en una muestra de agua, incluyendo las sales y el material orgánico disuelto. Los sólidos disueltos totales se derivan de la conductividad eléctrica, entonces algunas agencias crean estándares sólo para una. Los sólidos disueltos totales tal como la conductividad eléctrica, se afectan por la geología y descargas antropogénicas- En agua limpia, pero no usada como agua potable, los sólidos disueltos totales son iguales a los niveles de salinidad. En aguas residuales o en áreas con mucha contaminación, los sólidos disueltos totales incluyen solutos orgánicos (como hidrocarburo y urea) tal como iones de sal. </p>

  <p><strong>Riesgos humanos</strong></p>

  <p>Los sólidos disueltos totales afectan el sabor del agua.</p>

  <p><strong>Riesgos para el medio ambiente</strong></p>

  <p>Los sólidos disueltos totales pueden afectar la densidad celular en organismos acuáticos. Altos niveles de sólidos disueltos totales causan reducción de tamaño de células, afectando el movimiento y flotación normal de organismos acuáticos. Cambios drásticos y agudos a los niveles de sólidos disueltos totales pueden resultar en matanzas de peces menos adaptables.</p>`

}

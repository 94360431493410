import React from 'react';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  Hint,
  ChartLabel,
  DiscreteColorLegend,
} from 'react-vis';

import axios from 'axios';
import {isMobile} from 'react-device-detect';
import SourceBox from './SourceBox';
import TooltipModal from "./TooltipModal"
import tooltips from './assets/tooltips';
import './colorLegend.css';
const colorArray = [
    "#2b5676",
    "#a34d48",
    "#3e729e",
    "#526728",
    "#711f2f",
    "#326440",
    "#692f1c",
    "#2a6c69",
    "#8d5154",
    "#17324d",
    "#856030",
    "#17324d",
    "#514522",
    "#17324d",
    "#2a425f",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d"
];
function sortProperties(obj) {
  // convert object into array
    var sortable=[];
    for(var key in obj)
        if(obj.hasOwnProperty(key))
            sortable.push([key, obj[key]]); // each item is an array in format [key, value]

    // sort items by value
    sortable.sort(function(a, b)
    {
      return b[1] - a[1]; // compare numbers
    });

    return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
}

export default class StackbarEmployees extends React.Component {
    state = {
        dataset: [],
        years: [],
        highest: 0,
        vbs: [],
        printables: [],
        value: {x:null, y:null, label: ""},
        hoveredCell: false,
        labels: [],
    }

    async getData() {
        let res = await axios.get(`https://collector.oiegt.org/api/empleados/${this.props.projectId}`);
        const years = res.data.reduce(function(obj, v) {
            obj[v.anio] = (obj[v.anio] || 0) + 1;
            // return the updated object
            return obj;
        }, {});

        const amountsperyear = res.data.reduce(function(obj, v) {
            obj[v.anio] = (obj[v.anio] || []).concat(v);
            // return the updated object
            return obj;
        }, {});

        const amounts = Object.entries(amountsperyear);

        var sortedYears = sortProperties(years);

        var highest = 0;

        if (sortedYears.length > 0) {
            highest = sortedYears[0][1];
        }
        let labels = new Set();
        var vbs = [];
        var printables = [];
        var x = Object.keys(amounts).length;

        if (res.data.length > 0) {
            for (let i = 0; i < 3; i++) {
                let vbars = [];
                let whatToBring = "";
                let whatToShow = "";

                switch (i) {
                    case 0:
                        whatToBring = "empleados_EIA";
                        whatToShow = "Empleados EIA";
                        break;
                    case 1:
                        whatToBring = "empleados_igss";
                        whatToShow = "Empleados IGSS";
                        break;
                    case 2:
                        whatToBring = "empleados_produccion";
                        whatToShow = "Empleados Informe MEM";
                        break;
                    default:
                        whatToBring = "";
                        whatToShow = "";
                        break;
                }
                for (let j = 0; j < x; j++) {
                    if (amounts[j][1][0][whatToBring] != null) {
                        vbars.push({x: amounts[j][1][0]["anio"], y: amounts[j][1][0][whatToBring], label: whatToShow});
                        labels = labels.add(whatToShow)
                    }
                    else {
                        vbars.push({x: parseInt(amounts[j][0]), y: 0});
                    }
                }
                vbs.push(
                    <VerticalBarSeries
                        key={`se_vbs_${i}`}
                        data={vbars.slice()}
                        onValueMouseOver={v =>
                            this.setState({hoveredCell: v.x && v.y ? v : false})
                        }
                        onValueMouseOut={v =>
                            this.setState({hoveredCell: false})
                        }
                        color={colorArray[i]}
                    />
                );
            }
        }

        for (var i = 0; i < res.data.length; i++) {
            printables.push(
                <div key={`prtbl_${i}`}>
                    <h2>{res.data[i].anio}</h2>
                    <p>Empleados IGGS: {res.data[i].empleados_igss}</p>
                    <p>Empleados producción: {res.data[i].empleados_produccion}</p>
                    <p>Empleados EIA: {res.data[i].empleados_EIA}</p>
                </div>
            );
        }

        labels = Array.from(labels)
        const formattedLegends = labels.map((x, index) => ({title: x, color:colorArray[index], strokeWidth:10}))

        this.setState({
            dataset: res.data,
            years: years,
            highest: highest,
            vbs: vbs,
            labels: formattedLegends,
            printables: printables
        });

    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const {hoveredCell} = this.state;
        const {vbs} = this.state;
        let legendWidth = 0;
        if (isMobile) {
            legendWidth = window.innerWidth - 40;
        }
        else {
            legendWidth = (window.innerWidth - 100) / 2;

        }
        return (
            <div>
                <div className="project-module-header">
                    <h1 className="section-title white">Empleados</h1>
                    <TooltipModal title="Empleados" text={tooltips.tooltip_empleados}  />
                </div>
                {vbs.length > 0 ?
                    (
                        <XYPlot
                            margin={{left: 100, top: 50, bottom: 50}}
                            width={window.innerWidth - 200}
                            height={window.innerHeight - 250}
                            xType="ordinal"
                            className="vis"
                            colorType="category"
                            colorRange={colorArray}
                        >
                            <VerticalGridLines />
                            <HorizontalGridLines />
                            {/* Cambios a los AXES para tener Labels VER DOC DE ChartLabel*/}
                            <XAxis />
                            <YAxis />
                            <ChartLabel
                            text="Año"
                            className="alt-x-label"
                            includeMargin={true}
                            xPercent={0.5}
                            yPercent={0.77}
                            style={{
                                textAnchor: "start",
                                fontWeight: "bold",
                                fontSize: 16,
                                }}
                            />
                            <ChartLabel
                            text="Empleados"
                            className="alt-y-label"
                            includeMargin={true}
                            xPercent={0.02}
                            yPercent={0.3}
                            style={{
                            textAnchor: "start",
                            fontWeight: "bold",
                            transform: 'rotate(-90)',
                            fontSize: 16,
                            }}
                            />
                            {this.state.vbs}
                            {hoveredCell ? (
                                <Hint
                                    value={this.state.hoveredCell}
                                    align={{horizontal: 'auto', vertical: 'auto'}}
                                >
                                    <div className="hint">
                                        <h5>{hoveredCell.label}</h5>
                                        <p>
                                            <span>{hoveredCell.y}</span> empleados
                                        </p>
                                    </div>
                                </Hint>
                            ) : null}
                        </XYPlot>
                    ) :
                    (
                        <div className="still-looking">
                            <img src={require('./assets/images/oie-stilllooking.svg')} />
                        </div>
                    )
                }
                {/* Agregando color Legend*/}
                <DiscreteColorLegend
                    className="colorLegend"
                    width={legendWidth}
                    items={this.state.labels}
                    orientation="horizontal"
                />
                <SourceBox source={tooltips.source_empleados} />
                <div className="printables">
                    {this.state.printables}
                </div>
            </div>
        );
    }
}

import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ReactPaginate from 'react-paginate';

import { Link } from "react-router-dom";

import './Library.css';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const Catalogue = () => {

    const [entries, setEntries] = useState([]);
    const [featured, setFeatured] = useState();

    const getData = async() => {
        let res = await axios.get(`https://collector.oiegt.org/api/catalog/library`);

        let resEntries = [];

        let resFeatured;

        for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].destacado) {
                resFeatured =
                <div id="libraryFeaturedContent">
                    <a className="libraryBannerImageA" href={res.data[i].url_link_descarga} target="_blank"><img className="libraryBannerImage" src={res.data[i].id_banner}></img></a>
                    <div>
                        <h1>{res.data[i].titulo}</h1>
                        <h2>{res.data[i].subtitulo}</h2>
                    </div>
                    <div>
                        <p>{res.data[i].descripcion}</p>
                        <a href={res.data[i].url_link_descarga} target="_blank">descargar</a>
                    </div>
                </div>
            }

            resEntries.push(
                <div class="library-entry">
                    <a href={res.data[i].url_link_descarga} target="_blank">
                        <img className="libraryEntryImage" src={res.data[i].id_imagen}></img>
                        <h1>{res.data[i].titulo}</h1>
                        <h2>{res.data[i].subtitulo}</h2>
                        <p><strong>Autor: {res.data[i].autor}</strong></p>
                        <p>{res.data[i].ciudad + " / " + res.data[i].fecha}</p>
                        <p>{res.data[i].no_pag} páginas</p>
                        <p><strong>Reseña</strong></p>
                        <p><strong>{res.data[i].descripcion}</strong></p>
                    </a>
                </div>
            );
        }

        setFeatured(resFeatured);
        setEntries(resEntries);
    }

    useEffect(() => {
        getData();
    }, []);

    function Items({ currentItems }) {
        return (
            <>
                <div class="library-entries">
                    {currentItems}
                </div>
            </>
        );
    }
    
    function PaginatedItems({ itemsPerPage, itemsList }) {
        // We start with an empty list of items.
        const [currentItems, setCurrentItems] = useState(null);
        const [pageCount, setPageCount] = useState(0);
        // Here we use item offsets; we could also use page offsets
        // following the API or data you're working with.
        const [itemOffset, setItemOffset] = useState(0);
      
        useEffect(() => {
          window.scrollTo(0, 0)
          // Fetch items from another resources.
          const endOffset = itemOffset + itemsPerPage;
        //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
          setCurrentItems(itemsList.slice(itemOffset, endOffset));
          setPageCount(Math.ceil(itemsList.length / itemsPerPage));
        }, [itemOffset, itemsPerPage]);
      
        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
          const newOffset = event.selected * itemsPerPage % itemsList.length;
        //   console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
          setItemOffset(newOffset);
        };
      
        return (
          <>
            <Items currentItems={currentItems} />
            <ReactPaginate
              nextLabel="&rarr;"
              onPageChange={handlePageClick}
              pageRangeDisplayed={1}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="&larr;"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </>
        );
    }

    return (
        <div id="library">
            <div className="libraryContent">
                <h1>Biblioteca</h1>
                <h2>En esta biblioteca encontrarás un repositorio de artículos, publicaciones y guías producidas por el equipo OIE. Además de otras lecturas cuyos contenidos abarcan distintas temáticas sobre el extractivismo en Guatemala. </h2><br/>
                <div className="libraryNavWrapper">
                    <nav className='libraryNav'>
                        <ul>
                            <li className='current'><Link to="/biblioteca">Publicaciones</Link></li>
                            <li><Link to="/biblioteca/mapas" className='current'>Mapas</Link></li>
                        </ul>
                    </nav>
                </div>
                <h3>Destacado</h3>
                <div id="libraryFeatured">
                    {featured}
                </div>
                <PaginatedItems itemsPerPage={8} itemsList={entries} key="whut" />
            </div>
        </div>
    );

}

export default Catalogue;
import React from "react";
import { ComposableMap, Geographies, Geography, Graticule, Line, ZoomableGroup } from "react-simple-maps";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import Select from "react-select";
import SourceBox from "./SourceBox";
import TooltipModal from "./TooltipModal";
import tooltips from './assets/tooltips';


// URL DEL LAYER DEL MAPA QUE SE ESTA USANDO
const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const rounded = num => {
    if (num > 1000000000) {
        return Math.round(num / 100000000) / 10 + "Bn";
    } else if (num > 1000000) {
        return Math.round(num / 100000) / 10 + "M";
    } else {
        return Math.round(num / 100) / 10 + "K";
    }
};

const colorArray = [
    "#2b5676",
    "#a34d48",
    "#3e729e",
    "#526728",
    "#711f2f",
    "#326440",
    "#692f1c",
    "#2a6c69",
    "#8d5154",
    "#17324d",
    "#856030",
    "#17324d",
    "#514522",
    "#17324d",
    "#2a425f",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d"
];

const colorsss = {
    oro: "#17324a",
    plata: "#17324d"
};

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : '#CCC',
        backgroundColor: '#1F2121'
    }),
    control: styles => ({
        ...styles,
        color: 'white',
        backgroundColor: '#1F2121',
        width: '120px'
    }),
    menu: styles => ({
        ...styles,
        backgroundColor: '#1F2121',
        border: '1px solid white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'white'
    })
}

export default class GovernanceExportationsFlowMap extends React.Component {
    state = {
        dataset: [],
        years: [],
        highest: 0,
        vbs: [],
        value: false,
        selectYearsList: [],
        selectedYear: {},
        lines: [],
        separatedYear: []
    }

    async getData() {
        let res = await axios.get(`https://collector.oiegt.org/api/exportaciones`);

        const amountsperyear = res.data.reduce(function(obj, v) {
            obj[v.anio] = (obj[v.anio] || []).concat(v);
            // return the updated object
            console.log("old old old old obj", obj);
            return obj;
        }, {});

        const years = Object.keys(amountsperyear).map(function(obj) {
            return obj;
        });

        console.log("reeling in the years", years);

        let newAmountsPerYear = [];
        for (let key in amountsperyear) {
            if (amountsperyear.hasOwnProperty(key)) {
                newAmountsPerYear.push({[key]: amountsperyear[key]});
            }
        }

        const selectYears = years.map(function(obj) {
            const options = {};

            options.value = obj.toString();
            options.label = obj.toString();

            return options;
        });

        const separatedYear = this.filterYear(amountsperyear, years[0]);

        this.drawMap(separatedYear);

        this.setState({
            dataset: res.data,
            amountsPerYear: amountsperyear,
            selectYears: selectYears,
            selectedYear: selectYears[0],
            separatedYear: separatedYear
        });
    }

    filterYear(object, year) {
        return object[year];
    }

    drawMap(dataset) {
        var lines = [];

        if (dataset.length > 0) {
            let i = 0;
            for (let item of dataset) {

                // export bruto
                item.export_bruto.forEach(function(subitem, i) {
                    if (subitem.destino.length > 0) {
                        let id = item.id_der_minero;
                        let material = subitem.producto_minero;
                        let quantity = rounded(subitem.cantidad_vendida);
                        let destino = subitem.destino[0].name;
                        let longitude = subitem.destino[0].longitude;
                        let latitude = subitem.destino[0].latitude + (i * 0.2);
                        lines.push(
                            <Line
                                key={`efm_lp_${i}`}
                                className="map-line"
                                data-tip={`
                                    Proyecto: ${id} <br/>
                                    Mineral: ${material} <br/>
                                    Cantidad: ${quantity} <br/>
                                    Destino: ${destino}
                                `}
                                from={[-89.522713,14.628434]}
                                to={[longitude, latitude]}
                                stroke={colorArray[Math.floor(Math.random() * Math.floor(10))]}
                                strokeWidth={2}
                                strokeLinecap="round"
                                fill="none"
                            />
                        );
                    }
                });

                // export procesado
                item.export_procesado.forEach(function(subitem, i) {
                    if (subitem.destino.length > 0) {
                        let id = item.id_der_minero;
                        let material = subitem.producto_minero;
                        let quantity = rounded(subitem.cantidad_vendida);
                        let destino = subitem.destino[0].name;
                        let longitude = subitem.destino[0].longitude;
                        let latitude = subitem.destino[0].latitude + (i * 0.2);
                        lines.push(
                            <Line
                                key={`efm_lp_${i}`}
                                className="map-line"
                                data-tip={`
                                    Proyecto: ${id} <br/>
                                    Mineral: ${material} <br/>
                                    Cantidad: ${quantity} <br/>
                                    Destino: ${destino}
                                `}
                                from={[-89.522713,14.628434]}
                                to={[longitude, latitude]}
                                stroke={colorArray[Math.floor(Math.random() * Math.floor(10))]}
                                strokeWidth={2}
                                strokeLinecap="round"
                                fill="none"
                            />
                        );
                    }
                });
            }
        }

        this.setState({
            lines: lines
        })
    }

    changeSelect(e) {
        let dataset = this.state.amountsPerYear;
        let year = parseInt(e.value);
        const separatedYear = this.filterYear(dataset, year);
        this.drawMap(separatedYear);
        this.setState({
            selectedYear: e,
            separatedYear: separatedYear
        })
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const value = this.state.selectedYear;

        return (
            <div>
                <div className="project-module-header">
                    <h1 className="section-title white">Exportaciones</h1>
                    <TooltipModal title="Exportaciones" text={tooltips.tooltip_exportaciones} />
                    <Select
                        className="project-module-select"
                        styles={customStyles}
                        options={this.state.selectYears}
                        placeholder={"Año"}
                        value={value}
                        onChange={e => this.changeSelect(e)}
                    />
                </div>
                <div>
                    <ComposableMap
                        projection="geoMercator"
                        projectionConfig={{ scale: 180 }}
                        width={window.innerWidth - 200}
                        height={window.innerHeight - 300}
                    >
                        <ZoomableGroup center={[ 0, 50 ]}>
                            <Graticule fillOpacity={0} />
                            <Geographies geography={geoUrl}
                                fill="#5c5f61"
                                stroke="rgb(40, 33, 33)"
                                strokeWidth={0.5}>
                                {({ geographies }) =>
                                    geographies.map(geo => <Geography key={geo.rsmKey} geography={geo} />)
                                }
                            </Geographies>
                            {this.state.lines}
                        </ZoomableGroup>
                    </ComposableMap>
                    <ReactTooltip multiline={true} />
                    <SourceBox source={tooltips.source_mem} />
                </div>
            </div>
        );
    }
}

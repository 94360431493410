import React from 'react';

import './Project.css';
import ProjectInformation from './ProjectInformation';
import ProjectMinerals from './ProjectMinerals';
import Timeline from './Timeline';
import CanonsStackbar from './CanonsStackbar';

import MiningCanonsChart from './MiningCanonsChart';

import RoyaltiesStackbar from './RoyaltiesStackbar';

import MiningRoyaltiesChart from './MiningRoyaltiesChart';
import MiningVoluntaryRoyaltiesChart from './MiningVoluntaryRoyaltiesChart';

import MiningLicensingChart from './MiningLicensingChart';

import EmployeesStackbar from './EmployeesStackbar';

import EmployeesGroupBar from './EmployeesGroupBar';

import EmployeesIgssChart from './EmployeesIgssChart';

import ExportationsFlowMap from './ExportationsFlowMap';

import ExportationsMap from './ExportationsMap';

import ExtractionsMarkSeries from './ExtractionsMarkSeries';

import ExtractionsChart from './ExtractionsChart';

import LicensingAreaChart from './LicensingAreaChart';
import LicensingHorizontalStackbar from './LicensingHorizontalStackbar';
import ProjectLinks from './ProjectLinks';
import ProjectLinksPetrol from './ProjectLinksPetrol';
import axios from 'axios';

import PetrolEarningsChart from './PetrolEarningsChart';
import PetrolRecoverableCostChart from './PetrolRecoverableCostChart';
import PetrolProductionChart from './PetrolProductionChart';
import PetrolUnifiedChart from './PetrolUnifiedChart';

String.prototype.replaceAt = function(index, replacement) {
    return this.substr(0, index) + replacement + this.substr(index + replacement.length);
}

export default class Project extends React.Component {
    state = {
        isLoaded: false,
        dataset: [],
        licenseType: "",
        projectType: "",
        projectId: "",
        isSolicitud: false
    }

    async getData() {
        let all = await axios.get(`https://collector.oiegt.org/api/proyecto-extractivo_`);

        let id = this.props.match.params.projectId;

        console.log("the project id: ", id);

        if (id.charAt(0) === "S") {
            console.log("we have a s");
            id = id.replaceAt(0, "L");
        }

        console.log("new id", id);

        let project = all.data.filter(function(obj) {
            return obj.id_proyecto === id;
        })

        let projectType = project[0].tipo_proyecto;

        let isSolicitud = project[0].solicitud;

        console.log("isSolicitud", isSolicitud);

        console.log("ID", id)

        let res = {};

        if (projectType === "Minero") {
            res = await axios.get(`https://collector.oiegt.org/api/proyecto-extractivo_/${id}`);
        }
        else if (projectType === "Petrolero") {
            res = await axios.get(`https://collector.oiegt.org/api/proyecto-extractivo-petrolero/${id}`);
        }

        let licenseType = res.data.tipo_licencia;

        this.setState({
            dataset: res.data,
            licenseType: licenseType,
            projectType: projectType,
            isSolicitud: isSolicitud,
            projectId: id,
            isLoaded: true
        });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        let renderContent;
        let isLoaded = this.state.isLoaded;
        let isSolicitud = this.state.isSolicitud;
        let projectId = this.state.projectId;
        let projectType = this.state.projectType;
        let licenseType = this.state.licenseType;

        if (this.state.projectType === "Minero") {
            if (isSolicitud) {
                if (this.state.licenseType === "Explotación") {
                    renderContent =
                    <div>
                        <div className="section">
                            <div className="wrapper projectInformationContainer">
                                <ProjectInformation projectId={projectId} projectType={projectType} licenseType={licenseType} isSolicitud={isSolicitud} />
                            </div>
                        </div>
                        <div className="section black-bg fp-auto-height">
                            <div className="wrapper">
                                <ProjectMinerals projectId={projectId} isSolicitud={isSolicitud} />
                            </div>
                        </div>
                        <div className="section black-bg">
                            <div className="wrapper">
                                <LicensingHorizontalStackbar projectId={projectId} />
                            </div>
                        </div>
                        <div className="section black-bg hide-on-print">
                            <div className="wrapper">
                                <ProjectLinks projectId={projectId} />
                            </div>
                        </div>
                    </div>

                }
                else {
                    renderContent =

                    <div>
                        <div className="section">
                            <div className="wrapper projectInformationContainer">
                                <ProjectInformation projectId={projectId} projectType={projectType} licenseType={licenseType} isSolicitud={isSolicitud} />
                            </div>
                        </div>
                        <div className="section black-bg fp-auto-height">
                            <div className="wrapper">
                                <ProjectMinerals projectId={projectId} isSolicitud={isSolicitud} />
                            </div>
                        </div>
                        <div className="section black-bg">
                            <div className="wrapper">
                                <LicensingHorizontalStackbar projectId={projectId} />
                            </div>
                        </div>
                        <div className="section black-bg hide-on-print">
                            <div className="wrapper">
                                <ProjectLinks projectId={projectId} />
                            </div>
                        </div>
                    </div>

                }
            }
            else {
                if (this.state.licenseType === "Explotación") {
                    renderContent =
                    <div>
                        <div className="section">
                            <div className="wrapper projectInformationContainer">
                                <ProjectInformation projectId={projectId} projectType={projectType} licenseType={licenseType} />
                            </div>
                        </div>
                        <div className="section black-bg fp-auto-height">
                            <div className="wrapper">
                                <ProjectMinerals projectId={projectId} />
                            </div>
                        </div>
                        {/*
                        <div className="section black-bg">
                            <div className="wrapper">
                                <CanonsStackbar projectId={projectId} />
                            </div>
                        </div>
                        */}
                        <div className="section black-bg">
                            <div className="wrapper">
                                <MiningCanonsChart projectId={projectId} />
                            </div>
                        </div>
                        {/*
                        <div className="section black-bg">
                            <div className="wrapper">
                                <RoyaltiesStackbar projectId={projectId} />
                            </div>
                        </div>
                        */}
                        <div className="section black-bg">
                            <div className="wrapper">
                                <MiningRoyaltiesChart projectId={projectId} />
                            </div>
                        </div>
                        <div className="section black-bg">
                            <div className="wrapper">
                                <MiningVoluntaryRoyaltiesChart projectId={projectId} />
                            </div>
                        </div>
                        {/*
                        <div className="section black-bg">
                            <div className="wrapper">
                                <EmployeesStackbar projectId={projectId} />
                            </div>
                        </div>
                        */}
                        <div className="section black-bg">
                            <div className="wrapper">
                                <EmployeesGroupBar projectId={projectId} />
                            </div>
                        </div>
                        <div className="section black-bg">
                            <div className="wrapper">
                                <EmployeesIgssChart projectId={projectId} />
                            </div>
                        </div>
                        {/*
                        <div className="section black-bg">
                            <div className="wrapper">
                                <ExportationsFlowMap projectId={projectId} />
                            </div>
                        </div>
                        */}
                        <div className="section black-bg">
                            <div className="wrapper">
                                <ExportationsMap projectId={projectId} />
                            </div>
                        </div>
                        <div className="section black-bg">
                            <div className="wrapper">
                                <ExtractionsMarkSeries projectId={projectId} />
                            </div>
                        </div>
                        <div className="section black-bg">
                            <div className="wrapper">
                                <LicensingHorizontalStackbar projectId={projectId} />
                            </div>
                        </div>
                        {/*
                        <div className="section black-bg">
                            <div className="wrapper">
                                <MiningLicensingChart projectId={projectId} />
                            </div>
                        </div>
                        */}
                        <div className="section black-bg hide-on-print">
                            <div className="wrapper">
                                <ProjectLinks projectId={projectId} />
                            </div>
                        </div>
                    </div>

                }
                else {
                    renderContent =
                    <div>
                        <div className="section">
                            <div className="wrapper projectInformationContainer">
                                <ProjectInformation projectId={projectId} projectType={projectType} licenseType={licenseType} />
                            </div>
                        </div>
                        <div className="section black-bg fp-auto-height">
                            <div className="wrapper">
                                <ProjectMinerals projectId={projectId} />
                            </div>
                        </div>
                        <div className="section black-bg">
                            <div className="wrapper">
                                <CanonsStackbar projectId={projectId} />
                            </div>
                        </div>
                        {/*
                        <div className="section black-bg">
                            <div className="wrapper">
                                <MiningCanonsChart projectId={projectId} />
                            </div>
                        </div>
                        */}
                        <div className="section black-bg">
                            <div className="wrapper">
                                <EmployeesGroupBar projectId={projectId} />
                            </div>
                        </div>
                        <div className="section black-bg">
                            <div className="wrapper">
                                <EmployeesStackbar projectId={projectId} />
                            </div>
                        </div>
                        <div className="section black-bg">
                            <div className="wrapper">
                                <EmployeesIgssChart projectId={projectId} />
                            </div>
                        </div>
                        <div className="section black-bg">
                            <div className="wrapper">
                                <LicensingHorizontalStackbar projectId={projectId} />
                            </div>
                        </div>
                        {/*
                        <div className="section black-bg">
                            <div className="wrapper">
                                <MiningLicensingChart projectId={projectId} />
                            </div>
                        </div>
                        */}
                        <div className="section black-bg hide-on-print">
                            <div className="wrapper">
                                <ProjectLinks projectId={projectId} />
                            </div>
                        </div>
                    </div>

                }
            }
        }
        else if (this.state.projectType === "Petrolero") {
            renderContent =
            <div>
                <div className="section">
                    <div className="wrapper projectInformationPetrolContainer">
                        <ProjectInformation projectId={projectId} projectType={projectType} licenseType={licenseType} />
                    </div>
                </div>
                <div className="section black-bg">
                    <div className="wrapper">
                        <PetrolEarningsChart projectId={projectId} />
                    </div>
                </div>
                <div className="section black-bg">
                    <div className="wrapper">
                        <EmployeesIgssChart projectId={projectId} />
                    </div>
                </div>
                <div className="section black-bg">
                    <div className="wrapper">
                        <PetrolRecoverableCostChart projectId={projectId} />
                    </div>
                </div>
                <div className="section black-bg">
                    <div className="wrapper">
                        <PetrolProductionChart projectId={projectId} />
                    </div>
                </div>
                <div className="section black-bg">
                    <div className="wrapper">
                        <PetrolUnifiedChart projectId={projectId} />
                    </div>
                </div>
                {/*
                <div className="section black-bg">
                    <div className="wrapper">
                        <ExtractionsChart projectId={projectId} />
                    </div>
                </div>
                */}
                <div className="section black-bg hide-on-print">
                    <div className="wrapper">
                        <ProjectLinksPetrol projectId={projectId} />
                    </div>
                </div>
            </div>

        }

        return (
            isLoaded ?
            <div id="project">
                {renderContent}
            </div> :
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "500px"}}>
                <p>Cargando...</p>
            </div>
        )
    }
}

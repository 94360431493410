import React from 'react';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  LineMarkSeries,
  VerticalBarSeries,
  Hint,
  ChartLabel
} from 'react-vis';
import axios from 'axios';
import SourceBox from './SourceBox';
import TooltipModal from "./TooltipModal"
import tooltips from './assets/tooltips';

export default class LicensingAreaChart extends React.Component {
    state = {
        licensingOficialList: [],
        licensingRealList: [],
        printables: [],
        hoveredCell: false
    }

    async getData() {
        let res = await axios.get(`https://collector.oiegt.org/api/otorgamiento/${this.props.projectId}`);

        let licensingOficialList = [];
        let ascii = 65;
        for (let key in res.data[0]) {
            if (res.data[0].hasOwnProperty(key)) {
                licensingOficialList.push(
                    {
                        x: String.fromCharCode(ascii),
                        y: res.data[0][key].oficial,
                        label: key
                    }
                );
            }
            ascii++;
        }

        let licensingRealList = [];
        ascii = 65;
        for (let key in res.data[0]) {
            if (res.data[0].hasOwnProperty(key)) {
                licensingRealList.push(
                    {
                        x: String.fromCharCode(ascii),
                        y: res.data[0][key].real,
                        label: key
                    }
                );
            }
            ascii++;
        }

        var printables = [];

        for (let key in res.data[0]) {
            if (res.data[0].hasOwnProperty(key)) {
                printables.push(
                    <div key={`prtbl_${key}`}>
                        <h2>{key}</h2>
                        <p>Tiempo oficial: {res.data[0][key].oficial}</p>
                        <p>Tiempo real: {res.data[0][key].real}</p>
                    </div>
                );
            }
        }

        this.setState({
            dataset: res.data,
            licensingOficialList: licensingOficialList,
            licensingRealList: licensingRealList,
            printables: printables
        });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const {hoveredCell} = this.state;
        const {licensingOficialList} = this.state;
        return (
            <div>
                <div className="project-module-header">
                    <h1 className="section-title white">Proceso de otorgamiento de licencia</h1>
                    <TooltipModal title="Proceso de otorgamiento de licencia" text={tooltips.tooltip_otorgamiento} />
                </div>
                {licensingOficialList.length > 0 ?
                    (
                        <XYPlot
                            margin={{left: 100, top: 50, bottom: 50}}
                            width={window.innerWidth - 200}
                            height={window.innerHeight - 250}
                            xType="ordinal"
                            className="vis"
                        >
                            <VerticalGridLines />
                            <HorizontalGridLines />
                            {/* Cambios a los AXES para tener Labels VER DOC DE ChartLabel*/}
                            <XAxis />
                            <YAxis />
                            <ChartLabel
                            text="Procesos de toma de decisiones dentro del MEM, Según plazos de la Ley de Minería"
                            className="alt-x-label"
                            includeMargin={true}
                            xPercent={0.35}
                            yPercent={0.73}
                            style={{
                                textAnchor: "start",
                                fontWeight: "bold",
                                fontSize: 16,
                                }}
                            />
                            <ChartLabel
                            text="Días"
                            className="alt-y-label"
                            includeMargin={true}
                            xPercent={0.015}
                            yPercent={0.3}
                            style={{
                            textAnchor: "start",
                            fontWeight: "bold",
                            transform: 'rotate(-90)',
                            fontSize: 16,
                            }}
                            />
                            <LineMarkSeries
                              style={{fill: 'none'}}
                              lineStyle={{stroke: 'blue'}}
                              markStyle={{stroke: 'blue', fill: 'blue'}}
                              getNull={(d) => d.y !== null}
                              data={this.state.licensingRealList}
                              onValueMouseOver={v =>
                                  this.setState({hoveredCell: v.x && v.y ? v : false})
                              }
                              onValueMouseOut={v =>
                                  this.setState({hoveredCell: false})
                              }
                            />
                            <VerticalBarSeries
                              getNull={(d) => d.y !== null}
                              data={this.state.licensingOficialList}
                              onValueMouseOver={v =>
                                  this.setState({hoveredCell: v.x && v.y ? v : false})
                              }
                              onValueMouseOut={v =>
                                  this.setState({hoveredCell: false})
                              }
                            />
                            {hoveredCell ? (
                                <Hint value={hoveredCell}
                                    align={{horizontal: 'auto', vertical: 'auto'}}>
                                    <div className="hint">
                                        <h5>{hoveredCell.label}</h5>
                                        <p>
                                            <span>{hoveredCell.y}</span> días
                                        </p>
                                    </div>
                                </Hint>
                            ) : null}
                        </XYPlot>
                    ) :
                    (
                        <div className="still-looking">
                            <img src={require('./assets/images/oie-stilllooking.svg')} />
                        </div>
                    )
                }
                <SourceBox source={tooltips.source_mem} />
                <div className="printables">
                    {this.state.printables}
                </div>
            </div>
        );
    }
}

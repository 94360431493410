import React from 'react';
import {
  Treemap,
  Hint,
  DiscreteColorLegend,
} from 'react-vis';
import 'react-vis/dist/style.css';
import {isMobile} from 'react-device-detect';
import axios from 'axios';
import './GovernanceExtractionsTreeMap.css';
import SourceBox from './SourceBox';
import tooltips from './assets/tooltips';
import TooltipModal from './TooltipModal';

export default class GovernanceExtractionsTreeMap extends React.Component {
    state = {
      dataset: {},
      treeMapFormattedData: {},
      legends: [],
      value: false
    }

    async getData() {
        let res = await axios.get(`https://collector.oiegt.org/api/extracciones`);

        const dataset = res.data.reduce(function(obj, v) {
            let informacion_explotacion = [];
            for (let i = 0; i < v.informacion_explotacion.length; i++) {
                if (
                    v.informacion_explotacion[i].producto_minero !== "mena de oro y plata" &&
                    v.informacion_explotacion[i].producto_minero !== "plata" &&
                    v.informacion_explotacion[i].producto_minero !== "oro"
                ) {
                    informacion_explotacion.push(v.informacion_explotacion[i]);
                }
            }
            v.informacion_explotacion = informacion_explotacion;
            if (informacion_explotacion.length > 0) {
                obj.push(v);
            }
            return obj;
        }, []);

        this.drawTreeMap(dataset);

        this.setState({
            dataset: dataset
        });
    }

    drawTreeMap(dataset) {
        let colorArray = ["red", "blue", "green", "teal", "orange", "gray", "purple", "gold", "silver", "brown", "cyan"];
        let treeMapFormattedData = {
            "Title": "Extracciones",
            "color": "#212529",
            "children": dataset.map((x, index) =>({
                "title": x.id_der_minero,
                 "color": "#212529",
                 "labelColor": colorArray[index],
                 "children": x.informacion_explotacion.map(y => ({
                     title: y.producto_minero,
                     color: colorArray[index],
                     style: {padding:1, borderColor:"black"},
                     size: y.cantidad_extraida
                 }))
             }))
         }

         const formattedLegends = treeMapFormattedData.children.map(x => ({title: x.title, color: x.labelColor, strokeWidth:10}))

         this.setState({
             treeMapFormattedData: treeMapFormattedData,
             legends: formattedLegends
         })
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const value = this.state.selectedYear;
        let myWidth = 0;
        let myHeight = 0;
        let legendWidth = 0;

        if (isMobile) {
            myWidth = (window.innerWidth - 40);
            myHeight = (window.innerWidth - 40);
            legendWidth = window.innerWidth - 40;
        }
        else {
            myWidth = window.innerWidth - 80;
            myHeight = (window.innerWidth - 100) / 2;
            legendWidth = (window.innerWidth - 100) / 2;
        }
        return (
            <div>
                <div className="project-module-header">
                    <h1 className="section-title white">Extracciones por proyecto</h1>
                    <TooltipModal title="Extracciones" text={tooltips.tooltip_extracciones} />
                </div>
                <Treemap
                    className="heatmap"
                    width={myWidth}
                    height={myHeight}
                    colorType="literal"
                    mode="squarify"
                    padding={1}
                    colorRange={['#212529']}
                    data={this.state.treeMapFormattedData}
                    onLeafMouseOver={x => this.setState({value: x})}
                    onLeafMouseOut={() => this.setState({value: false})}
                />
                <DiscreteColorLegend
                    className="treemapLegend"
                    width={legendWidth}
                    items={this.state.legends}
                    orientation="horizontal"
                />
                <SourceBox source={tooltips.source_mem} />
            </div>
        );
    }
}

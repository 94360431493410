import React from 'react';
import { Link } from "react-router-dom";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './Home.css';

export default class Project extends React.Component {
    render() {
        return (
            <div className="homepage">
                <div className="section">
                    <div className="banner-wrapper home-first-slide-one" style={{backgroundColor: "#FFF"}}>
                        <div className="banner-wrapper-content black">
                            <span className="subtitle">&nbsp;</span>
                            <h1 className="title">Explora, accede, genera y visualiza información de los proyectos extractivos en el territorio guatemalteco</h1>
                            <p className="paragraph">El OIE es una plataforma de investigación que busca contribuir desde una mirada independiente con datos sobre la industria extractiva en Guatemala</p>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="banner-wrapper" style={{background: "linear-gradient(180deg, rgba(168,113,51,1) 0%, rgba(230,118,0,1) 100%)"}}>
                        <div className="banner-wrapper-content home-first-slide-two">
                            <span className="subtitle">Proyectos extractivos</span>
                            <h1 className="title">Explora los distintos proyectos extractivos interactuando con el mapa</h1>
                            <p className="paragraph">OIE cuenta con un perfil por proyecto extractivo, en el cual puedes acceder a información específica y oficial  para generar reportes en formato de datos abiertos</p>
                            <Link to="/proyectos" className="banner-cta white-cta">Explora más</Link>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="banner-wrapper" style={{background: "linear-gradient(0deg, rgba(0,152,131,1) 0%, rgba(102,192,179,1) 100%)"}}>
                        <div className="banner-wrapper-content">
                            <span className="subtitle">Búsqueda frecuente</span>
                            <div className="projectInformation">
                                <div className="project-information-content">
                                    <h1>El Escobal</h1>
                                    <p className="content-large">LEXT-015-11</p>
                                    <div>
                                        <h3>Estatus</h3>
                                        <p className="content-large uppercase">Suspendido</p>
                                    </div>
                                    <div className="project-information-subcontent">
                                        <h3>Número de expediente</h3>
                                        <p>217-2011</p>
                                        <h3>Tipo de licencia</h3>
                                        <p>Explotación</p>
                                    </div>
                                </div>
                            </div>

                            <Link to="/proyectos/LEXT-015-11" className="banner-cta white-cta">Ver proyecto</Link>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="banner-wrapper home-second-slide">
                        <div className="banner-wrapper-content home-second-slide-content">
                            <span className="subtitle">&nbsp;</span>
                            <h1 className="title">Análisis de datos agregados sobre el sector extractivo</h1>
                            <p className="paragraph">En esta sección podrás visualizar y comparar indicadores de los diferentes sectores extractivos</p>
                            <Link to="/gobernanza" className="banner-cta white-cta">Explora más</Link>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="banner-wrapper home-third-slide">
                        <div className="banner-wrapper-content home-third-slide-content">
                            <span className="subtitle">&nbsp;</span>
                            <h1 className="title">Datos producidos por científicos comunitarios que buscan cerrar la brecha de información existente sobre el recurso hídrico en sus territorios.</h1>
                            <p className="paragraph">
                                Explora datos de calidad de agua producidos de manera periódica por los científicos comunitarios
                                <Link to="/gobernanza-socioambiental" className="banner-cta">Explora más</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

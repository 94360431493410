import React, { useCallback, useRef, useMemo } from 'react';
import { toPng } from 'html-to-image';
import axios from 'axios';
import {isMobile} from 'react-device-detect';
import { BarStack, Line } from '@visx/shape';
import {
    Axis,
    buildChartTheme,
    LineSeries,
    Tooltip,
    XYChart,
    AnnotationLineSubject
} from "@visx/xychart";
import { Annotation, HtmlLabel, Label, Connector, CircleSubject, LineSubject } from '@visx/annotation';
import { SeriesPoint } from '@visx/shape/lib/types';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import cityTemperature, { CityTemperature } from '@visx/mock-data/lib/mocks/cityTemperature';
import { scaleBand, scaleLinear, scaleOrdinal, scaleTime } from '@visx/scale';
import { timeParse, timeFormat } from 'd3-time-format';
import { withTooltip, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';

import { max, extent, bisector } from 'd3-array';

import Select from "react-select";

import ParentSize from '@visx/responsive/lib/components/ParentSize';

import SourceBox from './SourceBox';

import {
  Legend,
  LegendLinear,
  LegendQuantile,
  LegendOrdinal,
  LegendSize,
  LegendThreshold,
  LegendItem,
  LegendLabel,
} from '@visx/legend';

import { LinearGradient } from '@visx/gradient';

import TooltipModal from './TooltipModal';
import tooltips from './assets/tooltips';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? '#BDC8FF' : '#05001F',
        backgroundColor: '#4D4DFF'
    }),
    control: styles => ({
        ...styles,
        color: '#05001F',
        backgroundColor: '#BDC8FF',
        width: '300px'
    }),
    menu: styles => ({
        ...styles,
        backgroundColor: '#4D4DFF',
        border: '1px solid white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#FFF'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#05001F" // Custom colour
    })
}

const palette = [
    "#341700"
    , "#512700"
    , "#6B3800"
    , "#834A00"
    , "#985C01"
    , "#AA6E00"
    , "#B97F06"
    , "#CD9300"
    , "#E1AD00"
    , "#F5CB00"
];

const color1 = '#A5230E';
const color2 = '#E2281A';
const color3 = '#EDD926';
const color4 = '#A5230E';
const background = '#05001F';
const background2 = '#333333';
const accentColor = '#2eff8b';
const accentColorDark = '#75daad';
const defaultMargin = { top: 40, left: 50, right: 40, bottom: 100 };
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

const olddata = cityTemperature.slice(0, 12);

const legendGlyphSize = 15;

const getTemperature = (d) => Number(d.NCA001);

const accessors = () => ({
      y: {
        NCA001: getTemperature,
      }
  });

export default class CommunityMonitoringGraph extends React.Component {
    constructor(props) {
      super(props);
      this.graphToImg = React.createRef();
      this.graphHeader = React.createRef();
    }

    state = {
        isLoaded: false,
        width: isMobile ? window.innerWidth - 40 : window.innerWidth - 100,
        height: 400,
        dataset: [],
        printables: [],
        cuenca: "",
        sitiosWithNames: "",
        selectDisabled: false
    }

    async getData() {

        if (this.state.cuenca != this.props.cuenca) {

            let muestreo = 2019;

            let parametro = this.props.parametro;

            if (parametro == "oxigeno_disuelto_total" || parametro == "porcentaje_oxigeno_disuelto") {
                parametro = "oxigeno";
            }
            else {
                parametro = this.props.parametro;
            }

            let res = await axios.get(`https://collector.oiegt.org/api/agua-${parametro}?cuenca=${this.props.cuenca}`);

            let title = "";

            let par = this.props.parametro;

            switch (par) {
                case "ph":
                    title = "pH";
                    break;
                case "conductividad":
                    title = "Conductividad";
                    break;
                case "arsenico":
                    title = "Arsénico";
                    break;
                case "turbiedad":
                    title = "Turbiedad";
                    break;
                case "oxigeno_disuelto_total":
                    title = "Oxígeno Disuelto Total";
                    break;
                case "porcentaje_oxigeno_disuelto":
                    title = "Porcentaje Oxígeno Disuelto";
                    break;
                case "solidos":
                    title = "Sólidos disueltos totales";
                    break;
                case "temperatura":
                    title = "Temperatura";
                    break;
                default:
                    title = "/";
                    break;
            }

            if (par === "conductividad") {
                par = "conductividad_muestra"
            }

            if (par === "temperatura") {
                par = "temperatura_muestra"
            }

            if (par === "solidos") {
                par = "solidos_disueltos"
            }

            // let processedData = res.data.filter(function(obj) {
            //     if (obj[par]) {
            //         let tempObj = {
            //             key: obj.muestra_id.id_sitio,
            //             name: obj.muestra_id.nombre,
            //             x: obj.fecha_recoleccion,
            //             y: parseFloat(obj[par]),
            //             no_muestreo: obj.no_muestreo
            //         }
            //
            //         return tempObj;
            //     }
            // });

            let processedData = res.data.map(function(obj) {
                let tempObj = {
                    key: obj.muestra_id.id_sitio,
                    name: obj.muestra_id.nombre,
                    x: obj.fecha_recoleccion,
                    y: parseFloat(obj[par]),
                    no_muestreo: obj.no_muestreo
                }

                return tempObj;
            });

            let finalKeys = processedData.map(function(obj) {
                return obj.key;
            });

            // console.log("FIRST BATCH OF KEYS", finalKeys);

            let finalKeysWithName = processedData.map(function(obj) {
                return obj.key + " " + obj.name;
            });

            finalKeys = [...new Set(finalKeys)];

            finalKeysWithName = [...new Set(finalKeysWithName)];

            const selectSitios = finalKeys.map(function(obj, i) {
                const options = {};

                options.value = obj;
                options.label = finalKeysWithName[i];

                return options;
            });

            this.setState({
                par: par,
                parametro: parametro,
                muestreo: muestreo,
                isLoaded: true,
                processedData: processedData,
                selectSitios: selectSitios,
                dataset: res.data,
                cuenca: this.props.cuenca,
                title: title
            });
        }
    }

    createGraph(data, keys) {

        let finalGraphs = [];

        let maxY = 0;

        let tempMaxY = [];

        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < keys.length; j++) {
                tempMaxY.push(data[i][keys[j]]);
            }
        }

        maxY = max(tempMaxY);

        if (Array.isArray(keys)) {
            for (let i = 0; i < keys.length; i++) {
                finalGraphs.push(
                    <LineSeries
                      dataKey={keys[i]}
                      data={data}
                      xAccessor={(d) => d.x}
                      yAccessor={(d) => Number(d[keys[i]])}
                    />
                );
            }
        }
        else {
            finalGraphs.push(
                <LineSeries
                  dataKey={keys}
                  data={data}
                  xAccessor={(d) => d.x}
                  yAccessor={(d) => d.keys}
                />
            );
        }

        let printables = [];

        this.setState({
            finalGraphs: finalGraphs,
            maxY: maxY
        });
    }

    async changeSelect(e) {

        let par = this.state.par;

        this.setState({
            selectDisabled: true
        });

        let query = "";

        if (e) {
            if (Array.isArray(e)) {
                for (let i = 0; i < e.length; i++) {
                    if (i != e.length - 1) {
                        query += e[i].value + ",";
                    }
                    else {
                        query += e[i].value;
                    }
                }
            }
            else {
                query += e.value;
            }
        }

        let res = await axios.get(`https://collector.oiegt.org/api/agua-${this.state.parametro}?cuenca=${this.props.cuenca}&_id=${query}`);

        Promise.all([res]).then((responses) => {

            const processedData = responses[0].data.map(function(obj) {
                let tempObj = {
                    key: obj.muestra_id.id_sitio,
                    name: obj.muestra_id.nombre,
                    x: obj.fecha_recoleccion,
                    y: parseFloat(obj[par]),
                    no_muestreo: obj.no_muestreo
                }

                return tempObj;
            });

            let sitios = [];

            let sitiosWithNames = "";

            if (e) {
                if (Array.isArray(e)) {
                    for (let i = 0; i < e.length; i++) {
                        sitios.push(e[i].value);
                        if (i < e.length - 1) {
                            sitiosWithNames += e[i].label + ", ";
                        }
                        else {
                            sitiosWithNames += e[i].label;
                        }
                    }
                }
                else {
                    sitios.push(e.value);
                    sitiosWithNames += e.label;
                }
            }

            const conceptData = processedData.map(function(obj, index) {

                let arrObj = [];

                for (let i = 0; i < sitios.length; i++) {
                    let tempObj = {};
                    if (sitios[i] == obj.key) {
                        tempObj[sitios[i]] = obj.y
                    }
                    else {
                        tempObj[sitios[i]] = "null"
                    }
                    arrObj.push(tempObj);
                }

                let finalObj = Object.assign({}, ...arrObj);

                finalObj.x = obj.x;

                return finalObj;
            });

            this.createGraph(conceptData, sitios);

            this.setState({
                sitiosWithNames: sitiosWithNames,
                selectDisabled: false
            });
        });
    }

    componentDidMount() {
        // this.getData();
    }

    componentDidUpdate() {
        this.getData();
    }

    graphToImgClick = () => {
        const graph = this.graphToImg.current;

        const header = this.graphHeader.current;

        header.style.display = "flex";

        let graphClone = graph.cloneNode(true);
        let headerClone = header.cloneNode(true);

        let div = document.createElement("div");

        div.appendChild(headerClone);
        div.appendChild(graphClone);

        let yyyy = new Date().YYYYMMDDHHMMSS();

        toPng(graph)
          .then((dataUrl) => {
            const link = document.createElement('a')
            // link.download = this.state.sitio + "_" + this.state.title + '.png'
            link.download = 'OIE_monitoreo_comunitario_' + yyyy + '.png'
            link.href = dataUrl
            link.click()
            header.style.display = "none";
          })
          .catch((err) => {
            console.log(err)
        });
    }

    render () {

        if (this.state.isLoaded) {
            // if (this.state.finalGraphs.length > 0) {
                return (
                    <div>
                        <div className="project-module-header">
                            <div className="projectModuleHeaderTitleTooltip">
                                <h1 className="section-title white">{this.state.title}</h1>
                                {this.props.tooltip ? (
                                        <TooltipModal title={this.state.title} text={this.props.tooltip} />
                                ) : ("")}
                            </div>
                            <Select
                                className="project-module-select"
                                isDisabled={this.state.selectDisabled}
                                styles={customStyles}
                                options={this.state.selectSitios}
                                placeholder={"Selecciona un sitio"}
                                maxMenuHeight={200}
                                isClearable={true}
                                onChange={e => this.changeSelect(e)}
                            />
                        </div>
                        <ParentSize>
                            {({ width, height }) => {
                                return (
                                    <div ref={this.graphToImg} className="graphWrapper">
                                        <div className="graphHeader" ref={this.graphHeader}>
                                            <img src={require("./assets/images/oie-logo_big.svg")} width="120px" />
                                            <div className="graphTitle">
                                                {this.state.sitiosWithNames}
                                            </div>
                                        </div>
                                        <div className="graphContainer">
                                            <Chart width={width} height={400} graphs={this.state.finalGraphs} line={this.state.line} title={this.state.title} muestreo={this.state.muestreo} unidad={this.props.unidad} maxY={this.state.maxY} limit={this.props.limit} />
                                            {/*<ChartWithTooltip data={this.state.finalData} width={width} height={400} />*/}
                                        </div>
                                    </div>
                                );
                            }}
                        </ParentSize>
                        {/*<SourceBox source={tooltips.source_mem} />
                        <div className="printables">
                            {this.state.printables}
                        </div>*/}
                        <a className="graphToImgBtn downloadBtn" onClick={this.graphToImgClick}>Descargar gráfica <span className="material-symbols-outlined">file_download</span></a>
                        <a className="csvBtn downloadBtn" href={`https://collector.oiegt.org/api/agua-${this.state.parametro}?format=csv`}>Descargar .csv <span className="material-symbols-outlined">database</span></a>
                    </div>
                )

        }
        else {
            return (
                <div></div>
            )
        }
    }
}

const customTheme = buildChartTheme({
  backgroundColor: "#f05454",
  colors: ["#e8e8e8", "#2C2E43"],
  gridColor: "#30475e",
  gridColorDark: "#222831",
  svgLabelSmall: { fill: "#30475e" },
  svgLabelBig: { fill: "#30475e" },
  tickLength: 4
});

const Chart = ({
    muestreo,
    width,
    height,
    graphs,
    title,
    unidad,
    limit,
    maxY
}) => {

    let xAccessor = (d) => d.x;
    let yAccessor = (d) => Number(d.y);

    // let yMax = height - 100;

    const xScale = scaleTime({
        range: [0, width],
        domain: [new Date(muestreo, 0, 1), new Date(muestreo, 11, 31)]
    });

    let margin;

    // bounds
    if (width > 900) {
        margin = { top: 40, left: 100, right: 100, bottom: 80 };
    }
    else {
        margin = { top: 40, left: 50, right: 50, bottom: 80 };
    }

    let xMax = width - margin.left - margin.right;
    let yMax = height - margin.top - margin.bottom;

    let label = title;

    if (unidad) {
        label += ` (${unidad})`;
    }

    // console.log("THESE ARE THE GRAPHS", graphs);

    let printAnnotation;

    if (graphs) {
        if (graphs.length > 0) {
            printAnnotation = true;
        }
        else {
            printAnnotation = false;
        }
    }

  return (
      <XYChart
        margin={margin}
        width={width}
        height={height}
        xScale={{ type: "band", paddingInner: 0.3 }}
        yScale={{ type: "linear" }}
      >

          <rect width={width} height={height} fill="url(#area-background-gradient)" rx={14} />
          <LinearGradient id="area-background-gradient" from={background} to={background2} />

          {graphs}

          {printAnnotation ? (
              <Annotation
                  width={width}
                  height={height}
                  x={width - margin.right}
                  y={height - margin.bottom - (yMax/maxY*limit)}
                  dx={20}
                  dy={0}
                >
                  <Connector stroke={color1} strokeWidth={2} type="line" />
                    <HtmlLabel
                      showAnchorLine={false}
                      anchorLineStroke={color1}
                      containerStyle={{
                        background: 'red',
                        border: `2px solid ${color1}`,
                        borderRadius: 2,
                        color: 'white',
                        fontSize: '1em',
                        lineHeight: '1em',
                        padding: '10px 5px 0 5px',
                        fontWeight: 400,
                      }}
                    >
                      <p>LMP</p>
                    </HtmlLabel>
                    <LineSubject
                      orientation={'horizontal'}
                      stroke={color1}
                      strokeWidth={2}
                      min={margin.left}
                      max={width - margin.right}
                    />
                </Annotation>
          ) : ("")}

          <Axis
              orientation={"bottom"}>
              {props => {
                  let rotate, anchor;
                  if (width > 900) {
                      rotate = 0;
                      anchor = "middle"
                  }
                  else {
                      rotate = 90;
                      anchor = "start"
                  }
                  const tickLabelSize = 10;
                  const tickRotate = rotate;
                  const tickColor = '#8e205f';
                  const axisCenter = (props.axisToPoint.x - props.axisFromPoint.x) / 2;
                  return (

                      <g className="my-custom-bottom-axis">
                          <line class="visx-line visx-axis-line" x1={props.axisFromPoint.x} y1="0" x2={props.axisToPoint.x} y2="0" fill="transparent" shape-rendering="crispEdges" stroke={color4} stroke-width="1"></line>
                          {props.ticks.map((tick, i) => {
                              const tickX = tick.to.x;
                              const tickY = tick.to.y + tickLabelSize + props.tickLength;
                              return (
                                  <Group
                                      key={`vx-tick-${tick.value}-${i}`}
                                      className={'vx-axis-tick'}
                                      >
                                      <Line
                                          from={tick.from}
                                          to={tick.to}
                                          stroke={tickColor}
                                          />
                                      <text
                                          transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                                          fontSize={tickLabelSize}
                                          textAnchor={anchor}
                                          fill={tickColor}
                                          >
                                          {tick.formattedValue}
                                      </text>
                                  </Group>
                              );
                          })}
                          <text
                              textAnchor="middle"
                              transform={`translate(${axisCenter}, 50)`}
                              fontSize="8"
                              >
                              {props.label}
                          </text>
                      </g>
                  );
              }}
          </Axis>

          <Axis
              label={label}
              orientation={"left"}
              />



        <Tooltip
          showVerticalCrosshair
          snapTooltipToDatumX
          renderTooltip={({ tooltipData, colorScale }) => (
            <>
            {/*console.log("tooltipData", tooltipData)*/}
              <div style={{ color: colorScale(tooltipData.nearestDatum.key) }}>
                {tooltipData.nearestDatum.key}
              </div>
              <br />
              <p>{xAccessor(tooltipData.nearestDatum.datum)}</p>
                    {/** temperatures */}
                    {/*console.log("tooltipData", tooltipData)*/}
                    {/*console.log("KEYS?", Object.keys(tooltipData.datumByKey))*/}
                    {(
                      Object.keys(tooltipData.datumByKey)
                    ).map((city) => {
                      //console.log("what's the city?", city);
                      //console.log("datum?", tooltipData.nearestDatum.datum);
                      // const temperature = accessors.y[city](tooltipData.nearestDatum.datum);
                      const temperature = tooltipData.nearestDatum.datum[city];

                      return (
                        <div key={city}>
                          {`${temperature} ${unidad ? unidad : label}`}
                        </div>
                      );
                    })}
            </>
          )}
        />
      </XYChart>
  );
};

function LegendDemo({ title, children }: { title: string; children: React.ReactNode }) {
    return (
        <div className="visxLegend">
            <div className="visxLegendTitle">{title}</div>
            {children}
        </div>
    );
}

import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { toPng } from 'html-to-image';
import {isMobile} from 'react-device-detect';
import { Line, Bar, BarStack } from '@visx/shape';
import { SeriesPoint } from '@visx/shape/lib/types';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import cityTemperature, { CityTemperature } from '@visx/mock-data/lib/mocks/cityTemperature';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { timeParse, timeFormat } from 'd3-time-format';
import { withTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { LegendOrdinal } from '@visx/legend';

import { 
    primaryPalette,
    primaryBackground,
    secondaryBackground,
    primaryStroke
} from './Styles';

import { 
    addCommas
} from './Tools';

import ParentSize from '@visx/responsive/lib/components/ParentSize';

import Select from "react-select";

import { GradientTealBlue, LinearGradient } from '@visx/gradient';

import TooltipModal from './TooltipModal';
import tooltips from './assets/tooltips';

import SourceBox from './SourceBox';

const defaultMargin = { top: 40, left: 80, right: 20, bottom: 100 };

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : '#CCC',
        backgroundColor: '#1F2121'
    }),
    control: styles => ({
        ...styles,
        color: 'white',
        backgroundColor: '#1F2121',
        width: '120px'
    }),
    menu: styles => ({
        ...styles,
        backgroundColor: '#1F2121',
        border: '1px solid white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'white'
    })
}

const olddata = cityTemperature.slice(0, 12);

export default class EmployeesIgssChart extends React.Component {
    constructor(props) {
        super(props);
        this.graphToImg = React.createRef();
        this.graphHeader = React.createRef();
    }

    state = {
        dataset: [],
        isLoaded: false,
        width: isMobile ? window.innerWidth - 40 : window.innerWidth - 100,
        height: 440,
        selectYears: [],
        printables: [],
        selectedYear: {}
    }

    async getData() {

        let res = await axios.get(`https://collector.oiegt.org/api/empleados-igss/${this.props.projectId}`);

        Promise.all([res]).then((responses) => {
            if (res.data.length > 0) {
                const keys = Object.keys(responses[0].data[0]).filter(function(d) {
                    if (d !== 'num_empleados') {
                        return d;
                    }
                });

                let data = responses[0].data.map(function(item) {
                    let newItem = {}
                    keys.forEach(function(key) {
                        newItem[key] = item[key];
                    });
                    return newItem;
                });

                let years = [...new Set(data.map(item => item.anio))];
                years.sort();

                const selectYears = years.map(function(obj) {
                    const options = {};

                    options.value = obj.toString();
                    options.label = obj.toString();

                    return options;
                });

                // let eYear = axios.get(`https://collector.oiegt.org/api/empleados/${this.props.projectId}/${years[years.length-1]}`);


                // this.selectYear(responses[0].data, years[years.length-1]);

                this.selectYear(years[years.length - 1]);

                this.setState({
                    dataset: responses[0].data,
                    selectYears: selectYears,
                    selectedYear: selectYears[selectYears.length - 1],
                });
            }
            this.setState({
                isLoaded: true
            });
        });
    }

    graphToImgClick = () => {
        const graph = this.graphToImg.current;

        const header = this.graphHeader.current;

        header.style.display = "flex";

        let graphClone = graph.cloneNode(true);
        let headerClone = header.cloneNode(true);

        let div = document.createElement("div");

        div.appendChild(headerClone);
        div.appendChild(graphClone);

        let yyyy = new Date().YYYYMMDDHHMMSS();

        toPng(graph)
          .then((dataUrl) => {
            const link = document.createElement('a')
            // link.download = this.state.sitio + "_" + this.state.title + '.png'
            link.download = 'OIE_empleados_IGSS_' + this.props.projectId + "_" + yyyy + '.png'
            link.href = dataUrl
            link.click()
            header.style.display = "none";
          })
          .catch((err) => {
            console.log(err)
        });
    }

    async selectYear(year) {

        let res = await axios.get(`https://collector.oiegt.org/api/empleados-igss/${this.props.projectId}/${year}`);

        let resFull = await axios.get(`https://collector.oiegt.org/api/empleados-igss/${this.props.projectId}`);

        let pairedData = res.data.map(function(item) {
            let newItem = {};
            newItem.category = item.mes;
            newItem.quantity = item.num_empleados;
            return newItem;
        });

        let printables = [];

        for (var i = 0; i < resFull.data.length; i++) {
            printables.push(
                <div key={`prtbl_${i}`}>
                    <h2>{resFull.data[i].anio}</h2>
                    <p>Año: {resFull.data[i].anio}</p>
                    <p>Número de empleados: {resFull.data[i].num_empleados}</p>
                </div>
            );
        }

        this.setState({
            finalData: pairedData,
            printables: printables,
            isLoaded: true
        });
    }

    componentDidMount() {
        this.getData();
    }

    filterYear(array, year) {
        const yearObj = array.filter(function(obj) {
            return obj.anio === year;
        }, {});

        return yearObj;
    }

    changeSelect(e) {
        let year = parseInt(e.value);
        this.selectYear(year);
        // const separatedYear = this.filterYear(dataset, year);
        this.setState({
            selectedYear: e,
            isLoaded: false
            // separatedYear: separatedYear
        })
    }

    render () {
        if (this.state.isLoaded && this.state.finalData) {
            if (this.state.dataset.length > 0) {

                return (
                    <div>
                        <div className="project-module-header">
                            <h1 className="section-title white">Empleados IGSS</h1>
                            <TooltipModal title="Empleados" text={tooltips.tooltip_empleados} />
                            <Select
                                className="project-module-select"
                                styles={customStyles}
                                options={this.state.selectYears}
                                placeholder={"Año"}
                                value={this.state.selectedYear}
                                onChange={e => this.changeSelect(e)}
                            />
                        </div>
                        <ParentSize>
                            {({ width, height }) => {
                                return (
                                    <div ref={this.graphToImg} className="graphWrapper">
                                        <div className="graphHeader" ref={this.graphHeader}>
                                            <img src={require("./assets/images/oie-logo_big.svg")} width="120px" />
                                            <div className="graphTitle">
                                                Empleados IGSS {this.props.projectId}
                                            </div>
                                        </div>
                                        <div className="graphContainer">
                                            <ChartWithTooltip data={this.state.finalData} width={width} height={400} selectYears={this.state.selectYears} selectedYear={this.state.selectedYear} />
                                        </div>
                                    </div>
                                );
                            }}
                        </ParentSize>
                        <SourceBox source={tooltips.source_empleados_igss} />
                        <div className="printables">
                            {this.state.printables}
                        </div>
                        <a className="graphToImgBtn downloadBtn" onClick={this.graphToImgClick}>Descargar gráfica <span className="material-symbols-outlined">file_download</span></a>
                    </div>
                )

            }
            else {
                return (
                    <div className="still-looking">
                        <img src={require('./assets/images/oie-stilllooking.svg')} />
                    </div>
                )
            }
        }
        else {
            return (
                <div>
                    <div className="project-module-header">
                        <h1 className="section-title white">Empleados IGSS</h1>
                            <Select
                                className="project-module-select"
                                styles={customStyles}
                                options={this.state.selectYears}
                                placeholder={"Año"}
                                value={this.state.selectedYear}
                                onChange={e => this.changeSelect(e)}
                            />
                    </div>
                    <svg width={this.state.width} height={this.state.height}>
                        <rect width={this.state.width} height={this.state.height} fill="url(#area-background-gradient)" rx={14} />
                        <LinearGradient id="area-background-gradient" from={primaryBackground} to={secondaryBackground} />
                        <Group top={defaultMargin.top} left={defaultMargin.left}>

                            Cargando...

                        </Group>
                    </svg>
                </div>
            )
        }
    }
}


const ChartWithTooltip = withTooltip(
    ({
        data,
        width,
        height,
        events = true,
        selectYears,
        selectedYear,
        tooltipOpen,
        tooltipLeft,
        tooltipTop,
        tooltipData,
        hideTooltip,
        showTooltip,
    }) => {

        let tooltipTimeout;
        
        let margin;

        // bounds
        if (width > 900) {
            margin = { top: 40, left: 100, right: 100, bottom: 80 };
        }
        else {
            margin = { top: 40, left: 50, right: 50, bottom: 80 };
        }

        let xMax = width - margin.left - margin.right;
        let yMax = height - margin.top - margin.bottom;

        const getCategory = (d) => d.category;
        const getQuantity = (d) => Number(d.quantity);

        // scales, memoize for performance
        const xScale = useMemo(
            () =>
            scaleBand({
                range: [0, xMax],
                domain: data.map(getCategory),
                padding: 0.4,
            }),
            [xMax],
        );

        const yScale = useMemo(
            () =>
            scaleLinear({
                range: [yMax, 0],
                round: true,
                domain: [0, Math.max(...data.map(getQuantity))],
            }),
            [yMax],
        );

        return width < 10 ? null : (

            <div>
                <svg width={width} height={height}>
                    <rect width={width} height={height} fill="url(#area-background-gradient)" rx={14} />
                    <LinearGradient id="area-background-gradient" from={primaryBackground} to={secondaryBackground} />
                    <LinearGradient id="bar-gradient" from={primaryPalette[0]} to={primaryPalette[1]} toOpacity={1} />
                    <Group top={margin.top} left={margin.left}>

                        {data.map(d => {
                            const category = getCategory(d);
                            const barWidth = xScale.bandwidth();
                            const barHeight = yMax - (yScale(getQuantity(d)) ?? 0);
                            const barX = xScale(category);
                            const barY = yMax - barHeight;
                            return (
                                <Bar
                                    key={`bar-${category}`}
                                    x={barX}
                                    y={barY}
                                    width={barWidth}
                                    height={barHeight}
                                    fill="url(#bar-gradient)"
                                    onMouseLeave={() => {
                                        tooltipTimeout = window.setTimeout(() => {
                                            hideTooltip();
                                        }, 300);
                                    }}
                                    onMouseMove={() => {
                                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                                        showTooltip({
                                            tooltipData: d,
                                            tooltipTop: barY + 50,
                                            tooltipLeft: barX + 100,
                                        });
                                    }}
                                    />
                            );
                        })
                    }

                    <AxisBottom
                        top={yMax}
                        scale={xScale}
                        stroke={primaryStroke}
                        tickStroke={primaryStroke}
                        tickValues={data.map(getCategory)}
                        >
                        {props => {
                            let rotate, anchor;
                            if (width > 900) {
                                rotate = 0;
                                anchor = "middle";
                            }
                            else {
                                rotate = 90;
                                anchor = "start";
                            }
                            const tickLabelSize = 10;
                            const tickRotate = rotate;
                            const tickColor = primaryStroke;
                            const axisCenter = (props.axisToPoint.x - props.axisFromPoint.x) / 2;
                            return (

                                <g className="my-custom-bottom-axis">
                                    <line class="visx-line visx-axis-line" x1={props.axisFromPoint.x} y1="0" x2={props.axisToPoint.x} y2="0" fill="transparent" shape-rendering="crispEdges" stroke={primaryStroke} stroke-width="1"></line>
                                    {props.ticks.map((tick, i) => {
                                        const tickX = tick.to.x;
                                        const tickY = tick.to.y + tickLabelSize + props.tickLength;
                                        return (
                                            <Group
                                                key={`vx-tick-${tick.value}-${i}`}
                                                className={'vx-axis-tick'}
                                                >
                                                <Line
                                                    from={tick.from}
                                                    to={tick.to}
                                                    stroke={tickColor}
                                                    />
                                                <text
                                                    transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                                                    fontSize={tickLabelSize}
                                                    textAnchor={anchor}
                                                    fill={tickColor}
                                                    >
                                                    {tick.formattedValue}
                                                </text>
                                            </Group>
                                        );
                                    })}
                                    <text
                                        textAnchor="middle"
                                        transform={`translate(${axisCenter}, 50)`}
                                        fontSize="8"
                                        >
                                        {props.label}
                                    </text>
                                </g>
                            );
                        }}
                    </AxisBottom>

                    <AxisLeft
                        left={0}
                        label={"Empleados"}
                        labelOffset={70}
                        scale={yScale}
                        >
                        {props => {
                            let rotate, label, tickLabelSize;
                            const tickColor = primaryStroke;
                            const axisCenter = (props.axisFromPoint.y - props.axisToPoint.y) / 2 * -1;

                            if (width > 900) {
                                tickLabelSize = 10;
                                rotate = 0;
                                label =
                                <text
                                    textAnchor="middle"
                                    x={axisCenter}
                                    y={-82}
                                    transform={`rotate(${-90})`}
                                    fontSize="12"
                                    >
                                    {props.label}
                                </text>
                            }
                            else {
                                tickLabelSize = 8;
                                rotate = -45;
                                label =
                                <text
                                    textAnchor="end"
                                    x={0}
                                    y={-10}
                                    fontSize="10"
                                    >
                                    {props.label}
                                </text>
                            }

                            const tickRotate = rotate;

                            return (

                                <g className="my-custom-bottom-axis">
                                    <line class="visx-line visx-axis-line" x1="0" y1={props.axisFromPoint.y} x2="0" y2={props.axisToPoint.y} fill="transparent" shape-rendering="crispEdges" stroke={primaryStroke} stroke-width="1"></line>
                                    {props.ticks.map((tick, i) => {
                                        const tickX = -8;
                                        // const tickY = tick.to.y + (props.axisFromPoint.y / props.numTicks);
                                        const tickY = tick.to.y;
                                        return (
                                            <Group
                                                key={`vx-tick-${tick.value}-${i}`}
                                                className={'vx-axis-tick'}
                                                >
                                                <Line
                                                    from={tick.from}
                                                    to={tick.to}
                                                    stroke={tickColor}
                                                    />
                                                <text
                                                    transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                                                    fontSize={tickLabelSize}
                                                    textAnchor="end"
                                                    fill={tickColor}
                                                    >
                                                    {tick.formattedValue}
                                                </text>
                                            </Group>
                                        );
                                    })}
                                    {label}
                                </g>
                            );
                        }}
                    </AxisLeft>

                </Group>
            </svg>
            <div
                style={{
                    position: 'absolute',
                    top: margin.top / 2 - 10,
                    width: '100%',
              display: 'flex',
              justifyContent: 'center',
              fontSize: '14px',
            }}
          >
          </div>
          {tooltipOpen && tooltipData && (
            <Tooltip className="visxTooltip" top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
              <div style={{ color: primaryPalette[0] }}>
                <strong>Empleados</strong>
              </div>
              <div>{addCommas(tooltipData.quantity)}</div>
            </Tooltip>
          )}
        </div>
    );
  },
);

import React from 'react';
import axios from 'axios';
import {isMobile} from 'react-device-detect';
import { BarStackHorizontal } from '@visx/shape';
import { SeriesPoint } from '@visx/shape/lib/types';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import cityTemperature, { CityTemperature } from '@visx/mock-data/lib/mocks/cityTemperature';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { timeParse, timeFormat } from 'd3-time-format';
import { withTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';

import Select from "react-select";

import {
  Legend,
  LegendLinear,
  LegendQuantile,
  LegendOrdinal,
  LegendSize,
  LegendThreshold,
  LegendItem,
  LegendLabel,
} from '@visx/legend';

import { LinearGradient } from '@visx/gradient';

import TooltipModal from './TooltipModal';
import tooltips from './assets/tooltips';

const palette = [
    "#341700"
    , "#512700"
    , "#6B3800"
    , "#834A00"
    , "#985C01"
    , "#AA6E00"
    , "#B97F06"
    , "#CD9300"
    , "#E1AD00"
    , "#F5CB00"
];

const color1 = '#A5230E';
const color2 = '#E2281A';
const color3 = '#EDD926';
const color4 = '#111111';
const background = '#333333';
const background2 = '#964d18';
const accentColor = '#2eff8b';
const accentColorDark = '#75daad';
const defaultMargin = { top: 40, left: 50, right: 40, bottom: 100 };
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

function addCommas(nStr)
{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : '#CCC',
        backgroundColor: '#1F2121'
    }),
    control: styles => ({
        ...styles,
        color: 'white',
        backgroundColor: '#1F2121',
        width: '120px'
    }),
    menu: styles => ({
        ...styles,
        backgroundColor: '#1F2121',
        border: '1px solid white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'white'
    })
}

const olddata = cityTemperature.slice(0, 12);

const legendGlyphSize = 15;

export default class MiningLicensingChart extends React.Component {
    constructor(props) {
      super(props);
    }

    state = {
        isLoaded: false,
        width: isMobile ? window.innerWidth - 40 : window.innerWidth - 100,
        height: 400,
    }

    async getData() {

        let res = await axios.get(`https://collector.oiegt.org/api/regalias-canones/${this.props.projectId}`);
        // console.log("ingresos petroleo", res.data);

        console.log("regalias data", res.data);

        let finalData = [];

        if (res.data.length > 0) {

            const dataset = res.data.filter(function(obj) {
                return obj.tipo_documento === "Regalias";
            }, {});

            let keys = dataset.map(function(item) {
                return item.detalle;
            });

            keys.push("anio");

            keys = [...new Set(keys)];

            console.log("keyss for sure", keys);

            // let keys = Object.keys(dataset[0]).filter(function(d) {
            //     if (d !== 'monto' && d !== 'tipo_documento') {
            //         return d;
            //     }
            // });

            // console.log("keyssss", keys);

            let data = dataset.map(function(item) {
                let newItem = {}
                keys.forEach(function(key) {
                    if (key === 'anio') {
                        newItem[key] = item[key];
                    }
                    else {
                        if (item.detalle === key) {
                            newItem[key] = item.monto;
                        }
                        else {
                            newItem[key] = 0;
                        }
                    }
                });
                // let newItem = {
                //     date: item.anio,
                //     total: item.total
                // }
                // console.log("the new item", newItem);
                return newItem;
            });

            console.log("allegedly data", data);

            // keys = [];
            //
            // data.forEach(function(item) {
            //     Object.keys(item).forEach(function(key) {
            //         keys.push(key);
            //     });
            // });
            //
            // keys = [...new Set(keys)];
            //
            // console.log("new keys", keys);

            let years = [...new Set(data.map(item => item.anio))];
            years.sort();

            years.forEach(function(year) {
                let singleYear = data.filter(function(item) {
                    if (item.anio === year) {
                        return item;
                    }
                });
                // console.log("this is single year", singleYear);
                let newItem = {
                    anio: year
                }
                keys.forEach(function(key) {
                    if (key !== "anio") {
                        let singleYearProfit = singleYear.reduce((acc, obj) => {
                            acc += Number(obj[key]);
                            return acc;
                        }, 0);
                        newItem[key] = singleYearProfit;
                    }
                });

                // console.log("new item", newItem);

                finalData.push(newItem);
            });

            console.log("wrappedddd shit omg", finalData);

            // console.log("yearsss", years);
            // console.log("dataaaa", data);

        }
        this.setState({
            isLoaded: true,
            dataset: res.data,
            finalData: finalData
        });
    }

    componentDidMount() {
        this.getData();
    }

    render () {
        if (this.state.isLoaded && this.state.finalData) {
            if (this.state.dataset.length > 0) {

                return (
                    <div>
                    <div className="project-module-header">
                        <h1 className="section-title white">Empleados IGSS</h1>
                            <Select
                                className="project-module-select"
                                styles={customStyles}
                                options={this.state.selectYears}
                                placeholder={"Año"}
                                value={this.state.selectedYear}
                                onChange={e => this.changeSelect(e)}
                            />
                    </div>
                    <ChartWithTooltip data={this.state.finalData} width={this.state.width} height={this.state.height} selectYears={this.state.selectYears} selectedYear={this.state.selectedYear} />
                    </div>
                )

            }
            else {
                return (
                    <div className="still-looking">
                        <img src={require('./assets/images/oie-stilllooking.svg')} />
                    </div>
                )
            }
        }
        else {
            return (
                <div>
                    <div className="project-module-header">
                        <h1 className="section-title white">Empleados IGSS</h1>
                            <Select
                                className="project-module-select"
                                styles={customStyles}
                                options={this.state.selectYears}
                                placeholder={"Año"}
                                value={this.state.selectedYear}
                                onChange={e => this.changeSelect(e)}
                            />
                    </div>
                    <svg width={this.state.width} height={this.state.height}>
                        <rect width={this.state.width} height={this.state.height} fill="url(#area-background-gradient)" rx={14} />
                        <LinearGradient id="area-background-gradient" from={background} to={background2} />
                        <LinearGradient id="bar-gradient" from={color1} to={color4} toOpacity={1} />
                        <Group top={defaultMargin.top} left={defaultMargin.left}>

                            Cargando...

                        </Group>
                    </svg>
                </div>
            )
        }
    }
}

const ChartWithTooltip = withTooltip(
  ({
    data,
    width = 800,
    height = 400,
    events = false,
    margin = defaultMargin,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  }) => {

      const keys = Object.keys(data[0]).filter(function(d) {
          if (d !== 'anio' && d !== 'mes') {
              return d;
          }
      });

      const totals = data.reduce((obj, val) => {
        const totalSingle = keys.reduce((total, i) => {
          total += Number(val[i]);
          return total;
        }, 0);
        obj.push(totalSingle);
        return obj;
      }, []);

      // accessors
      const getDate = (d) => d.anio;

      // scales
      const yScale = scaleLinear({
        domain: [0, Math.max(...totals)],
        nice: true,
      });
      const xScale = scaleBand({
        domain: data.map(getDate),
        padding: 0.2,
      });

      const colorScale = scaleOrdinal({
        domain: keys,
        range: palette,
      });

      let tooltipTimeout: number;


    // bounds
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    yScale.range([yMax, 0]);
    xScale.rangeRound([50, xMax]);

    return width < 10 ? null : (
        <div>
          <svg width={width} height={height}>
              <rect width={width} height={height} fill="url(#area-background-gradient)" rx={14} />
                  <LinearGradient id="area-background-gradient" from={background} to={background2} />
                  <LinearGradient id="area-gradient" from={accentColor} to={accentColor} toOpacity={0.1} />
            <Group top={margin.top} left={margin.left}>
              <BarStackHorizontal
                data={data}
                keys={keys}
                height={yMax}
                y={getDate}
                xScale={xScale}
                yScale={yScale}
                color={colorScale}
              >
                {barStacks =>
                  barStacks.map(barStack =>
                    barStack.bars.map(bar => (
                      <rect
                        key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                        x={bar.x}
                        y={bar.y}
                        width={bar.width}
                        height={bar.height}
                        fill={bar.color}
                        onClick={() => {
                          if (events) alert(`clicked: ${JSON.stringify(bar)}`);
                        }}
                        onMouseLeave={() => {
                          tooltipTimeout = window.setTimeout(() => {
                            hideTooltip();
                          }, 300);
                        }}
                        onMouseMove={() => {
                          if (tooltipTimeout) clearTimeout(tooltipTimeout);
                          const top = bar.y + margin.top;
                          const left = bar.x + bar.width + margin.left;
                          showTooltip({
                            tooltipData: bar,
                            tooltipTop: top,
                            tooltipLeft: left,
                          });
                        }}
                      />
                    )),
                  )
                }
              </BarStackHorizontal>
              <AxisBottom
                top={yMax}
                scale={xScale}
                stroke={color3}
                tickStroke={color3}
                tickLabelProps={() => ({
                  fill: color3,
                  fontSize: 11,
                  textAnchor: 'middle',
                  dy: '0.33em',
                })}
              />
              <AxisLeft
                  left={50}
                  label={"GTQ"}
                  labelOffset={70}
                scale={yScale}
                stroke={color3}
                tickTransform={"translate(-30,0)"}
                tickStroke={color3}
                tickLabelProps={() => ({
                  fill: color3,
                  fontSize: 11,
                  textAnchor: 'middle',
                })}
              />
            </Group>
          </svg>
          <div
            style={{
              position: 'absolute',
              top: margin.top / 2 - 10,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              fontSize: '14px',
            }}
          >
          </div>
          {tooltipOpen && tooltipData && (
            <Tooltip className="visxTooltip" top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
              <div style={{ color: colorScale(tooltipData.key) }}>
                <strong>{tooltipData.key.replace(/_/g, " ")}</strong>
              </div>
              <div>GTQ {addCommas(tooltipData.bar.data[tooltipData.key].toFixed(2))}</div>
              <div>
                <small>{getDate(tooltipData.bar.data)}</small>
              </div>
            </Tooltip>
          )}

          <LegendDemo title="Tipos de ingreso">
              <LegendOrdinal scale={colorScale} labelFormat={label => `${label.toUpperCase()}`}>
                  {labels => (
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                          {labels.map((label, i) => (
                              <LegendItem
                                  key={`legend-quantile-${i}`}
                                  margin="0 5px"
                                  onClick={() => {
                                      if (events) alert(`clicked: ${JSON.stringify(label)}`);
                                  }}
                                  >
                                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                                      <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                                  </svg>
                                  <LegendLabel align="left" margin="0 0 0 4px">
                                      {label.text.replace(/_/g, " ")}
                                  </LegendLabel>
                              </LegendItem>
                          ))}
                      </div>
                  )}
              </LegendOrdinal>
          </LegendDemo>
        </div>
    );
  },
);

function LegendDemo({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <div className="visxLegend">
      <div className="visxLegendTitle">{title}</div>
      {children}
    </div>
  );
}

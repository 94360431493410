import React, { useState }  from 'react';
import { loadModules } from 'esri-loader';
import {isMobile} from 'react-device-detect';
import axios from 'axios';
import styles from './ProjectsMap.module.css';
import { range } from 'd3';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from "react-select";
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';

import './ProjectsMap.css';
import { Link } from "react-router-dom";

import tooltips from './assets/tooltips';
import TooltipModal from "./TooltipModal";

// import coguanor from './coguanor.png';

const iconActive = require('./assets/icons/project-marker-active.svg');

const iconSuspended = require('./assets/icons/project-marker-suspended.svg');

const iconClosed = require('./assets/icons/project-marker-closed.svg');

const iconPetrolActive = require('./assets/icons/project-marker-petrol-active.svg');

const iconPetrolSuspended = require('./assets/icons/project-marker-petrol-suspended.svg');

const iconPetrolClosed = require('./assets/icons/project-marker-petrol-closed.svg');

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? '#FFF' : '#FFF',
        backgroundColor: '#E67600'
    }),
    control: styles => ({
        ...styles,
        color: '#000',
        backgroundColor: '#FFF',
        width: '100%'
    }),
    menu: styles => ({
        ...styles,
        color: '#FFF',
        backgroundColor: '#E67600',
        border: '1px solid white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#000'
    })
}

const sliderMarks = {

}

export default class ProjectsMap extends React.Component {

    state = {
        dataset: [],
        filterCriteria: [],
        filterCriteriaObj: {}
        // locale: "es"
    }

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.sliderRef = React.createRef();
    }

    async getData() {

        this.res = await axios.get(`https://collector.oiegt.org/api/proyecto-extractivo_`);

        var links = [];

        console.log("ProjectsMap data", this.res.data);

        let derechos = this.res.data.map(function(obj) {
            let derecho = {};
            if (obj.hasOwnProperty('nombre_proyecto')) {
                derecho["name"] = obj.nombre_proyecto;
            }
            else {
                derecho["name"] = obj.nombre_contrato;
            }
            derecho["id"] = obj.id_proyecto;
            return derecho;
        });

        console.log("derechos", derechos);

        let municipios = [];

        this.res.data.forEach(function(obj) {
            for (var i = 0; i < obj.id_municipio.length; i++) {
                if (obj.id_municipio[i].name !== undefined) {
                    municipios.push(obj.id_municipio[i].name);
                }
            }
        });

        let departamentos = [];

        this.res.data.forEach(function(obj) {
            for (var i = 0; i < obj.id_departamentos.length; i++) {
                if (obj.id_departamentos[i].name !== undefined) {
                    departamentos.push(obj.id_departamentos[i].name);
                }
            }
        });

        municipios = [...new Set(municipios)];

        departamentos = [...new Set(departamentos)];

        let status = this.res.data.map(function(obj) {
            return obj.status;
        });

        status = [...new Set(status)];

        const selectDerechos = derechos.map(function(obj) {
            const options = {};

            options.value = obj.name.toString();
            options.label = obj.name.toString() + ` (${obj.id.toString()})`;

            return options;
        });

        const selectMunicipios = municipios.map(function(obj) {
            const options = {};
            if(obj !== undefined){
                options.value = obj.toString();
                options.label = obj.toString();
            }


            return options;
        });

        const selectDepartamentos = departamentos.map(function(obj) {
            const options = {};

            options.value = obj.toString();
            options.label = obj.toString();

            return options;
        });

        const selectStatus = status.map(function(obj) {
            const options = {};

            options.value = obj.toString();
            options.label = obj.toString();

            return options;
        });

        links = this.buildLinks(this.res.data);

        this.setState({
            dataset: this.res.data,
            filteredDataset: this.res.data,
            links: links,
            selectDerechos: selectDerechos,
            selectMunicipios: selectMunicipios,
            selectDepartamentos: selectDepartamentos,
            selectStatus: selectStatus
        });

        // lazy load the required ArcGIS API for JavaScript modules and CSS
        loadModules([
            "esri/Map",
            "esri/views/ui/DefaultUI",
            "esri/views/MapView",
            "esri/layers/FeatureLayer",
            "esri/symbols/PictureMarkerSymbol",
            "esri/Graphic",
            "esri/layers/GraphicsLayer",
            "esri/config"
        ], { css: true })
        .then(([
            ArcGISMap,
            DefaultUI,
            MapView,
            FeatureLayer,
            PictureMarkerSymbol,
            Graphic,
            GraphicsLayer,
            esriConfig
        ]) => {
            esriConfig.request.corsDetection = false;

            this.map = new ArcGISMap({
                basemap: 'topo-vector'
            });

            this.view = new MapView({
                container: this.mapRef.current,
                map: this.map,
                center: [-90.410456,15.690665],
                zoom: 8,
                navigation: {
                    mouseWheelZoomEnabled: false,
                    browserTouchPanEnabled: false
                }
            });

            this.view.ui.move("zoom", "bottom-right");

        });

        this.createMarkers(this.res.data);
    }

    statusParser(status){
        let newStatus = ""
        switch(status) {
            case "Vigente":
                newStatus = "Vigente";
                break;
            case "Vigente en trámite de económico coactivo":
                newStatus = "Vigente";
                break;
            case "Vigente por Derivada":
                newStatus = "Vigente";
                break;
            case "Vigente, trámite de primera y segunda prórroga":
                newStatus = "Vigente";
                break;
            case "Suspendido":
                newStatus = "Suspendida";
                break;
            case "Suspendida":
                newStatus = "Suspendida";
                break;
            case "Cierre":
                newStatus = "Extinta/Caducada";
                break;
            case "Cancelada":
                newStatus = "Extinta/Caducada";
                break;
            case "Extinta":
                newStatus = "Extinta/Caducada";
                break;
            case "En trámite de extinción y renuncia":
                newStatus = "Extinta/Caducada";
                break;
            case "Caducada":
                newStatus = "Extinta/Caducada";
                break;
            case "Trámite de Caducidad":
                newStatus = "Extinta/Caducada";
                break;
            default:
                newStatus = "Extinta/Caducada";
        }
        return newStatus;
    }

    changeSelect(e, source) {

        this.map.remove(this.graphicsLayer);

        let tempData = this.res.data;

        let filteredDatasetObj = [];
        let links = [];
        let filterCriteria = this.state.filterCriteria;
        let filterCriteriaObj = this.state.filterCriteriaObj;

        filterCriteriaObj[source] = [];

        if (e !== null) {
            if (e !== null && e.length > 0) {
                for (var i = 0; i < e.length; i++) {
                    filterCriteria.push({value: e[i].value, type: source});
                    filterCriteriaObj[source].push(e[i].value);
                }
            }
            else {
                if (e.value !== undefined) {
                    filterCriteria.push({value: e.value, type: source});
                    filterCriteriaObj[source].push(e.value);
                }
            }
        }

        const filterKeys = Object.keys(filterCriteriaObj);

        filteredDatasetObj = tempData.filter(item => {
            // validates all filter criteria
            return filterKeys.every(key => {
                // ignores an empty filter
                if (!filterCriteriaObj[key].length) return true;
                if (key === "id_municipio") {
                    for (var i = 0; i < item[key].length; i++) {
                        return filterCriteriaObj[key].find(filter => filter === item[key][i].name);
                    }
                    // return filterCriteriaObj[key].find(filter => filter === item[key][0].name);
                }
                if (key === "id_departamentos") {
                    for (var i = 0; i < item[key].length; i++) {
                        return filterCriteriaObj[key].find(filter => filter === item[key][i].name);
                    }
                    // return filterCriteriaObj[key].find(filter => filter === item[key][0].name);
                }
                else {
                    return filterCriteriaObj[key].find(filter => filter === item[key]);
                }
            });
        });

        this.createMarkers(filteredDatasetObj);
        links = this.buildLinks(filteredDatasetObj);

        this.setState({
            filterCriteria: filterCriteria,
            filterCriteriaObj: filterCriteriaObj,
            links: links
        });
    }

    titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }

    buildLinks (dataset) {
        var links = [];

        for (let i = 0; i < dataset.length; i++) {
            let id_proyecto = "";
            let isSolicitud = dataset[i].solicitud;

            if (isSolicitud) {
                id_proyecto = dataset[i].id_display_solicitud;
            }
            else {
                id_proyecto = dataset[i].id_proyecto;
            }

            let link = <p><Link to={`/proyectos/${id_proyecto}`}>{dataset[i].nombre_proyecto}</Link></p>;
            console.log("haciendo link", link);
            links.push(link);
        }
        return links;
    }

    createMarkers(dataset) {

        loadModules([
            "esri/Map",
            "esri/views/ui/DefaultUI",
            "esri/views/MapView",
            "esri/layers/FeatureLayer",
            "esri/symbols/PictureMarkerSymbol",
            "esri/Graphic",
            "esri/layers/GraphicsLayer",
            "esri/config"
        ], { css: true })
        .then(([
            ArcGISMap,
            DefaultUI,
            MapView,
            FeatureLayer,
            PictureMarkerSymbol,
            Graphic,
            GraphicsLayer,
            esriConfig
        ]) => {

            this.graphicsLayer = new GraphicsLayer();

            this.map.add(this.graphicsLayer);

            this.markers = [];

            var symbol = {
                type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
                height: "30px"
            };


            for (let i = 0; i < dataset.length; i++) {

                let point = {
                    type: "point",
                    longitude: dataset[i].west == null? dataset[i].id_municipio[0].west: dataset[i].west,
                    latitude: dataset[i].north == null? dataset[i].id_municipio[0].north: dataset[i].north,
                }

                let id_proyecto = "";
                let isSolicitud = dataset[i].solicitud;

                if (isSolicitud) {
                    id_proyecto = dataset[i].id_display_solicitud;
                }
                else {
                    id_proyecto = dataset[i].id_proyecto;
                }

                let link = "<a href='" + window.location.href + "/" + id_proyecto + "'>Link</a>";
                // let link = "<p>Link</p>";

                console.log("linkkkkkk", link);

                let attributes = {
                    "nombre_proyecto": dataset[i].nombre_proyecto,
                    "id_proyecto": id_proyecto,
                    "link": link
                }

                let projectStatus = dataset[i].status;

                if (dataset[i].tipo_proyecto === "Minero") {
                    switch (projectStatus) {
                        case "Trámite de Caducidad":
                            symbol.url = iconSuspended;
                            symbol.width = "45px";
                            break;
                        case "Vigente":
                            symbol.url = iconActive;
                            symbol.width = "45px";
                            break;
                        case "Vigente en trámite de económico coactivo":
                            symbol.url = iconActive;
                            symbol.width = "45px";
                            break;
                        case "Vigente por Derivada":
                            symbol.url = iconActive;
                            symbol.width = "45px";
                            break;
                        case "Vigente, trámite de primera y segunda prórroga":
                            symbol.url = iconActive;
                            symbol.width = "45px";
                            break;
                        case "Suspendido":
                            symbol.url = iconSuspended;
                            symbol.width = "45px";
                            break;
                        case "Suspendida":
                            symbol.url = iconSuspended;
                            symbol.width = "45px";
                            break;
                        case "Cierre":
                            symbol.url = iconClosed;
                            symbol.width = "45px";
                            break;
                        case "Cancelada":
                            symbol.url = iconClosed;
                            symbol.width = "45px";
                            break;
                        case "Extinta":
                            symbol.url = iconClosed;
                            symbol.width = "45px";
                            break;
                        case "Extinta/Caducada":
                            symbol.url = iconClosed;
                            symbol.width = "45px";
                            break;
                        case "En trámite de extinción y renuncia":
                            symbol.url = iconClosed;
                            symbol.width = "45px";
                            break;
                        case "Caducada":
                            symbol.url = iconClosed;
                            symbol.width = "45px";
                            break;
                        case "Trámite de Caducidad":
                            symbol.url = iconClosed;
                            symbol.width = "45px";
                            break;
                        default:
                            symbol.url = iconActive;
                            symbol.width = "45px";
                        // code block
                    }
                }
                else {
                    switch(dataset[i].status) {
                        case "Trámite de Caducidad":
                            symbol.url = iconPetrolSuspended;
                            symbol.width = "21px";
                            break;
                        case "Vigente":
                            symbol.url = iconPetrolActive;
                            symbol.width = "21px";
                            break;
                        case "Vigente en trámite de económico coactivo":
                            symbol.url = iconPetrolActive;
                            symbol.width = "21px";
                            break;
                        case "Vigente por Derivada":
                            symbol.url = iconPetrolActive;
                            symbol.width = "21px";
                            break;
                        case "Vigente, trámite de primera y segunda prórroga":
                            symbol.url = iconPetrolActive;
                            symbol.width = "21px";
                            break;
                        case "Suspendido":
                            symbol.url = iconPetrolSuspended;
                            symbol.width = "21px";
                            break;
                        case "Suspendida":
                            symbol.url = iconPetrolSuspended;
                            symbol.width = "21px";
                            break;
                        case "Cierre":
                            symbol.url = iconPetrolClosed;
                            symbol.width = "21px";
                            break;
                        case "Cancelada":
                            symbol.url = iconPetrolClosed;
                            symbol.width = "21px";
                            break;
                        case "Extinta":
                            symbol.url = iconPetrolClosed;
                            symbol.width = "21px";
                            break;
                        case "Extinta/Caducada":
                            symbol.url = iconPetrolClosed;
                            symbol.width = "21px";
                            break;
                        case "En trámite de extinción y renuncia":
                            symbol.url = iconPetrolClosed;
                            symbol.width = "21px";
                            break;
                        case "Caducada":
                            symbol.url = iconPetrolClosed;
                            symbol.width = "21px";
                            break;
                        case "Trámite de Caducidad":
                            symbol.url = iconPetrolClosed;
                            symbol.width = "21px";
                            break;
                        default:
                            symbol.url = iconPetrolActive;
                            symbol.width = "21px";
                        // code block
                    }
                }

                let pointGraphic = new Graphic({
                    attributes: attributes,
                    geometry: point,
                    symbol: symbol,
                    popupTemplate: {
                        // autocasts as new PopupTemplate()
                        title: "Proyecto",
                        content: [
                            {
                                type: "fields",
                                fieldInfos: [
                                    {
                                        fieldName: "nombre_proyecto",
                                        label: "Nombre"
                                    },
                                    {
                                        fieldName: "id_proyecto",
                                        label: "ID Proyecto"
                                    },
                                    {
                                        fieldName: "link",
                                        label: "Ver proyecto"
                                    }

                                ]
                            }
                        ]
                    }
                });

                this.markers.push(pointGraphic);

                this.graphicsLayer.add(this.markers[i]);
            }

            this.filteredMarkers = this.markers;
        });
    }

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
        if (this.view) {
            // destroy the map view
            this.view.container = null;
        }
    }

    render() {
        let myWidth = 0;
        let myHeight = 0;
        let legendWidth = 0;

        if (isMobile) {
            myWidth = (window.innerWidth - 40);
            myHeight = (window.innerWidth - 40);
            legendWidth = window.innerWidth - 40;
        }
        else {
            myWidth = window.innerWidth - 80;
            myHeight = (window.innerWidth - 100) / 2;
            legendWidth = (window.innerWidth - 100) / 2;
        }

        return (
            <div style={{position: 'relative'}}>
                <div className="open-filters-btn-wrapper">
                    <a id="open-filters-btn" className="large-btn toggle-filters-btn" ref={this.filtersWrapperBtn} onClick={this.toggleFilters}>Abrir filtros</a>
                </div>
                <div className="map-filters-wrapper" ref={this.filtersWrapper}>
                    <h1 className="title orange">Explora los distintos proyectos extractivos</h1>
                    <div className="map-filters">
                        <Select
                            className="map-filter"
                            styles={customStyles}
                            options={this.state.selectDerechos}
                            placeholder={"Proyectos Extractivos"}
                            maxMenuHeight={200}
                            isClearable={true}
                            onChange={e => this.changeSelect(e, "nombre_proyecto")}
                        />
                        <Select
                            closeMenuOnSelect={false}
                            className="map-filter"
                            styles={customStyles}
                            options={this.state.selectDepartamentos}
                            placeholder={"Departamento"}
                            maxMenuHeight={200}
                            isMulti
                            onChange={e => this.changeSelect(e, "id_departamentos")}
                        />
                        <Select
                            closeMenuOnSelect={false}
                            className="map-filter"
                            styles={customStyles}
                            options={this.state.selectMunicipios}
                            placeholder={"Municipio"}
                            maxMenuHeight={200}
                            isMulti
                            onChange={e => this.changeSelect(e, "id_municipio")}
                        />
                        <Select
                            closeMenuOnSelect={false}
                            className="map-filter"
                            styles={customStyles}
                            options={this.state.selectStatus}
                            placeholder={"Estatus"}
                            maxMenuHeight={200}
                            isMulti
                            onChange={e => this.changeSelect(e, "status")}
                        />
                        <div className="projects-list">
                            <h2 className="subtitle orange">Proyectos</h2>
                            <div className="projects-list-wrapper">
                                {this.state.links}
                            </div>
                        </div>
                        <a className="large-btn toggle-filters-btn" ref={this.filtersWrapperBtn} onClick={this.toggleFilters}>Listo</a>
                    </div>
                </div>
                <div className="projects-legends-list">
                    <h2 className="subtitle orange">Estatus</h2>
                    <p><img src={require('./assets/icons/project-marker-active.svg')} /> Vigente</p>
                    <p><img src={require('./assets/icons/project-marker-suspended.svg')} /> Suspendida</p>
                    <p><img src={require('./assets/icons/project-marker-closed.svg')} /> Extinta/Caducada</p>
                    <p><img src={require('./assets/icons/project-marker-petrol-active.svg')} /> Vigente</p>
                    <p><img src={require('./assets/icons/project-marker-petrol-suspended.svg')} /> Suspendida</p>
                    <p><img src={require('./assets/icons/project-marker-petrol-closed.svg')} /> Extinta/Caducada</p>
                </div>
                <div className={styles.webmap} ref={this.mapRef} />
            </div>
        );
    }
}

import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import HorizontalTimelineContent from './HorizontalTimelineContent';
import Select from "react-select";
import axios from 'axios';
import styles from './Timeline.module.css';

// Directly importing the minified bootstrap css to avoid all the painful
// steps it will take otherwise to get it to work.
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : '#CCC',
        backgroundColor: '#1F2121'
    }),
    control: styles => ({
        ...styles,
        color: 'white',
        backgroundColor: '#1F2121',
        width: '220px'
    }),
    menu: styles => ({
        ...styles,
        backgroundColor: '#1F2121',
        border: '1px solid white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'white'
    })
}

export default class Timeline extends React.Component {
    state = {
        events: [],
        value: 0,
        previous: 0
    }

    getData() {

        let dataset = this.props.dataset;

        let types = dataset.map(function(obj) {
            return obj.tipo_evento;
        });

        types = [...new Set(types)];

        let selectTypes = types.map(function(obj) {
            const options = {};

            options.value = obj.toString();
            options.label = obj.toString();

            return options;
        });

        selectTypes.splice(0, 0, {value: "all", label: "Todos"});

        let events = dataset.map((event, index) => {
            return ({
                date: event.fecha,
                component: (
                    <div className={styles.timelineEventsWrapper} key={index}>
                        <div>
                            <h1 className={styles.h1}>{ event.nombre_evento }</h1>
                        </div>
                        <div>
                            <h2 className={styles.h2}>{ event.tipo_evento === "Otro"? event.otro_tipo_evento : event.tipo_evento }</h2>
                            <p className={styles.p}>{ event.descripcion_evento}</p>
                        </div>
                    </div>
                )
            });
        });

        let printables = dataset.map((event, index) => {
            return (
                <div key={index}>
                    <div key={index}>
                        <div>
                            <p>{event.fecha}</p>
                        </div>
                        <div>
                            <h2>{ event.nombre_evento }</h2>
                        </div>
                        <div>
                            <p>{ event.tipo_evento }</p>
                            <p className={styles.p}>{ event.descripcion_evento}</p>
                        </div>
                    </div>
                </div>
            );
        });

        this.setState({
            dataset: dataset,
            events: events,
            selectTypes: selectTypes,
            selectedType: selectTypes[0],
            // data: data,
            printables: printables
        });
    }

    componentDidMount() {
        this.getData();
    }

    filterType(array, type) {
        let typeObj = [];
        if (type === "all") {
            typeObj = array;
        }
        else {
            typeObj = array.filter(function(obj) {
                return obj.tipo_evento === type;
            }, {});
        }

        let events = typeObj.map((event, index) => {
            return ({
                date: event.fecha,
                component: (
                    <div className={styles.timelineEventsWrapper} key={index}>
                        <div>
                            <h1 className={styles.h1}>{ event.nombre_evento }</h1>
                        </div>
                        <div>
                            <h2 className={styles.h2}>{ event.tipo_evento === "Otro"? event.otro_tipo_evento : event.tipo_evento }</h2>
                            <p className={styles.p}>{ event.descripcion_evento}</p>
                        </div>
                    </div>
                )
            });
        });

        return events;
    }

    changeSelect(e) {
        let dataset = this.state.dataset;
        let type = e.value;
        const separatedType = this.filterType(dataset, type);
        this.setState({
            selectedType: e,
            events: separatedType,
            value: 0,
            previous: 0
        })
    }

    render() {
        const value = this.state.selectedType;
        let data;

        return (
            <div>
                <div className="project-module-header">
                    <h2>Línea de tiempo del proyecto extractivo</h2>
                    <Select
                        className="project-module-select"
                        styles={customStyles}
                        options={this.state.selectTypes}
                        placeholder={"Tipo de documento"}
                        value={value}
                        onChange={e => this.changeSelect(e)}
                    />
                </div>
                <div className="timeline-wrapper">
                    <HorizontalTimelineContent
                        index={this.state.value}
                        indexClick={(index) => {
                            this.setState({ value: index, previous: this.state.value });
                        }}
                        content={this.state.events} />
                </div>
                <div className="timelinePrintables">
                    {this.state.printables}
                </div>
            </div>
        );
    }
}

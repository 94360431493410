import React from "react";
import { ComposableMap, Geographies, Geography, Graticule, Line, ZoomableGroup } from "react-simple-maps";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import Select from "react-select";
import SourceBox from "./SourceBox";
import TooltipModal from "./TooltipModal"
import tooltips from './assets/tooltips';

function addCommas(nStr)
{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

// URL DEL LAYER DEL MAPA QUE SE ESTA USANDO
const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const rounded = num => {
    if (num > 1000000000) {
        return Math.round(num / 100000000) / 10 + "Bn";
    } else if (num > 1000000) {
        return Math.round(num / 100000) / 10 + "M";
    } else {
        return Math.round(num / 100) / 10 + "K";
    }
};

const colorArray = [
    "#2b5676",
    "#a34d48",
    "#3e729e",
    "#526728",
    "#711f2f",
    "#326440",
    "#692f1c",
    "#2a6c69",
    "#8d5154",
    "#17324d",
    "#856030",
    "#17324d",
    "#514522",
    "#17324d",
    "#2a425f",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d"
];

const colorsss = {
    oro: "#17324a",
    plata: "#17324d"
};

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : '#CCC',
        backgroundColor: '#1F2121'
    }),
    control: styles => ({
        ...styles,
        color: 'white',
        backgroundColor: '#1F2121',
        width: '120px'
    }),
    menu: styles => ({
        ...styles,
        backgroundColor: '#1F2121',
        border: '1px solid white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'white'
    })
}

export default class ExportationsFlowMap extends React.Component {
    state = {
        dataset: [],
        years: [],
        highest: 0,
        vbs: [],
        value: false,
        selectYearsList: [],
        selectedYear: {},
        lines: [],
        separatedYear: []
    }

    async getData() {
        let res = await axios.get(`https://collector.oiegt.org/api/exportaciones/${this.props.projectId}`);

        const years = res.data.map(function(obj) {
            return obj.anio;
        });

        const selectYears = years.map(function(obj) {
            const options = {};

            options.value = obj.toString();
            options.label = obj.toString();

            return options;
        });

        const separatedYear = this.filterYear(res.data, years[0]);

        this.drawMap(separatedYear);

        var printables = [];

        // for (var i = 0; i < res.data.length; i++) {
        //     for (var j = 0; j < res.data[i].export_bruto.length; j++) {
        //         printables.push(
        //             <div>
        //                 <h2>Exportación en bruto</h2>
        //                 <p>Año: {res.data[i].anio}</p>
        //                 <p>Producto minero: {res.data[i].export_bruto[j].producto_minero}</p>
        //                 <p>Cantidad vendida: {res.data[i].export_bruto[j].cantidad_vendida}</p>
        //                 <p>Cantidad vendida dimensional: {res.data[i].export_bruto[j].cantidad_vendida_dimensional}</p>
        //                 <p>Valor quetzales: Q. {addCommas(res.data[i].export_bruto[j].valor_quetzales.toFixed(2))}</p>
        //                 <p>Costo unitario: Q. {addCommas(res.data[i].export_bruto[j].costo_unitario.toFixed(2))}</p>
        //                 <p>Destino: {res.data[i].export_bruto[j].destino[0].name}</p>
        //             </div>
        //         );
        //     }
        //     for (var j = 0; j < res.data[i].export_procesado.length; j++) {
        //         printables.push(
        //             <div key={`prtbl_${i}_${j}`}>
        //                 <h2>Exportación procesada</h2>
        //                 <p>Año: {res.data[i].anio}</p>
        //                 <p>Producto minero: {res.data[i].export_procesado[j].producto_minero}</p>
        //                 <p>Cantidad vendida: {res.data[i].export_procesado[j].cantidad_vendida}</p>
        //                 <p>Cantidad vendida dimensional: {res.data[i].export_procesado[j].cantidad_vendida_dimensional}</p>
        //                 <p>Valor quetzales: Q. {addCommas(res.data[i].export_procesado[j].valor_quetzales.toFixed(2))}</p>
        //                 <p>Costo unitario: Q. {addCommas(res.data[i].export_procesado[j].costo_unitario.toFixed(2))}</p>
        //                 <p>Destino: {res.data[i].export_procesado[j].destino[0].name}</p>
        //             </div>
        //         );
        //     }
        // }

        this.setState({
            dataset: res.data,
            selectYears: selectYears,
            selectedYear: selectYears[0],
            separatedYear: separatedYear,
            printables: printables
        });
    }

    filterYear(array, year) {
        const yearObj = array.filter(function(obj) {
            return obj.anio === year;
        }, {});

        return yearObj;
    }

    drawMap(dataset) {
        var lines = [];

        if (dataset.length > 0) {
            for (let i = 0; i < dataset[0].export_procesado.length; i++) {
                let exportation = dataset[0].export_procesado[i];
                let quantity = rounded(exportation.cantidad_vendida) + " " + exportation.cantidad_vendida_dimensional;
                lines.push(
                    <Line
                        key={`efm_l_${i}`}
                        className="map-line"
                        data-tip={`
                            Mineral: ${exportation.producto_minero} <br/>
                            Cantidad: ${quantity} <br/>
                            Destino: ${exportation.destino[0].name}
                        `}
                        from={[-89.522713,14.628434]}
                        to={[exportation.destino[0].longitude, exportation.destino[0].latitude+(i*0.2)]}
                        stroke={colorArray[i]}
                        strokeWidth={2}
                        strokeLinecap="round"
                        fill="none"
                    />
                );
            }
        }

        if (dataset.length > 0) {
            for (let i = 0; i < dataset[0].export_bruto.length; i++) {
                let exportation = dataset[0].export_bruto[i];
                let quantity = rounded(exportation.cantidad_vendida) + " " + exportation.cantidad_vendida_dimensional;
                lines.push(
                    <Line
                        style={{strokeDasharray: "4"}}
                        key={`efm_l_${i}`}
                        className="map-line"
                        data-tip={`
                            Mineral: ${exportation.producto_minero} <br/>
                            Cantidad: ${quantity} <br/>
                            Destino: ${exportation.destino[0].name}
                        `}
                        from={[-89.522713,14.628434]}
                        to={[exportation.destino[0].longitude, exportation.destino[0].latitude+(i*0.2)]}
                        stroke={colorArray[i]}
                        strokeWidth={2}
                        strokeLinecap="round"
                        fill="none"
                    />
                );
            }
        }

        this.setState({
            lines: lines
        })
    }

    changeSelect(e) {
        let dataset = this.state.dataset;
        let year = parseInt(e.value);
        const separatedYear = this.filterYear(dataset, year);
        this.drawMap(separatedYear);
        this.setState({
            selectedYear: e,
            separatedYear: separatedYear
        })
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const value = this.state.selectedYear;
        const {lines} = this.state;

        return (
            <div>
                <div className="project-module-header">
                    <h1 className="section-title white">Exportaciones</h1>
                    <TooltipModal title="Exportaciones" text={tooltips.tooltip_exportaciones} />
                    <Select
                        className="project-module-select"
                        styles={customStyles}
                        options={this.state.selectYears}
                        placeholder={"Año"}
                        value={value}
                        onChange={e => this.changeSelect(e)}
                    />
                </div>
                {lines.length > 0 ?
                    (
                        <div className="hideInPrint">
                            <ComposableMap
                                projection="geoMercator"
                                projectionConfig={{ scale: 180 }}
                                width={window.innerWidth - 200}
                                height={window.innerHeight - 300}
                            >
                                <ZoomableGroup center={[ 0, 50 ]}>
                                    <Graticule fillOpacity={0} />
                                    <Geographies geography={geoUrl}
                                        fill="#5c5f61"
                                        stroke="rgb(40, 33, 33)"
                                        strokeWidth={0.5}>
                                        {({ geographies }) =>
                                            geographies.map(geo => <Geography key={geo.rsmKey} geography={geo} />)
                                        }
                                    </Geographies>
                                    {this.state.lines}
                                </ZoomableGroup>
                            </ComposableMap>
                            <ReactTooltip multiline={true} />
                        </div>
                    ) :
                    (
                        <div className="still-looking">
                            <img src={require('./assets/images/oie-stilllooking.svg')} />
                        </div>
                    )
                }
                <SourceBox source={tooltips.source_mem} />
                <div className="printables">
                    {this.state.printables}
                </div>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import Select from "react-select";
import tooltips from './assets/tooltips';
import TooltipModal from "./TooltipModal";
import CorporationStock from './CorporationStock';
import CommoditiesChart from './CommoditiesChart';
import {isMobile} from 'react-device-detect';

import './Corporations.css';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? '#FFF' : '#FFF',
        backgroundColor: '#E67600'
    }),
    control: styles => ({
        ...styles,
        color: '#000',
        backgroundColor: '#FFF',
        width: '220px'
    }),
    menu: styles => ({
        ...styles,
        color: '#FFF',
        backgroundColor: '#E67600',
        border: '1px solid white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#000'
    })
}

export default class Corporations extends React.Component {
    // let viewerWidth = 0;
    // if (isMobile) {
    //     viewerWidth = window.innerWidth - 40;
    // }
    // else {
    //     viewerWidth = (window.innerWidth - 100);
    // }

    state = {
        dataset: [],
        isLoaded: false,
        width: isMobile ? window.innerWidth - 40 : window.innerWidth - 100,
        height: 400,
        widthCommodities: isMobile ? window.innerWidth - 40 : window.innerWidth - 100,
        heightCommodities: 400
    }

    constructor(props) {
        super(props);
    }

    async getData() {
        let res = await axios.get(`https://collector.oiegt.org/api/empresa-transnacional`);

        let empresas = res.data.map(function(obj) {
            return obj.name;
        });

        const selectEmpresas = empresas.map(function(obj) {
            const options = {};

            options.value = obj.toString();
            options.label = obj.toString();

            return options;
        });

        let dataset = res.data;

        let name = dataset[0].name;
        let stockCode = dataset[0].codigo_bolsa;

        let stockData = await axios.get(`https://collector.oiegt.org/api/finance/${stockCode}`);

        let filteredDataset = dataset.filter(obj => {
            return obj.name === name;
        });

        let commoditiesCode = "GC=F";

        let commoditiesData = await axios.get(`https://collector.oiegt.org/api/finance/${commoditiesCode}`);

        const selectCommodities = [
            {value: "GC=F", label: "Oro"},
            {value: "SI=F", label: "Plata"},
            {value: "CL=F", label: "Petróleo"}
        ];

        let bolsas = [];

        if (filteredDataset[0].bolsas_de_valores.length > 0) {
            for (let i = 0; i < filteredDataset[0].bolsas_de_valores.length; i++) {
                bolsas.push(<p key={`b_${i}`}>{ filteredDataset[0].bolsas_de_valores[i] }</p>);
            }
        } else {
            bolsas.push(<p key={`b_${1}`}>—</p>);
        }

        let subsidiarias = [];

        if (filteredDataset[0].empnacional_set.length > 0) {
            for (let i = 0; i < filteredDataset[0].empnacional_set.length; i++) {
                subsidiarias.push(<p key={`s_${i}`}>{ filteredDataset[0].empnacional_set[i].razon_social }</p>);
            }
        } else {
            subsidiarias.push(<p key={`s_${1}`}>—</p>);
        }

        this.setState({
            dataset: res.data,
            selectedType: selectEmpresas[0],
            commodityValue: selectCommodities[0],
            selectEmpresas: selectEmpresas,
            selectCommodities: selectCommodities,
            filteredDataset: filteredDataset,
            bolsas: bolsas,
            subsidiarias: subsidiarias,
            stockData: stockData.data,
            commoditiesData: commoditiesData.data,
            isLoaded: true
        });
    }

    async changeSelect(e) {
        this.setState({
            stockData: [],
            width: 0,
            height: 0
        });

        let viewerWidth = 0;
        if (isMobile) {
            viewerWidth = window.innerWidth - 40;
        }
        else {
            viewerWidth = (window.innerWidth - 100);
        }

        let dataset = this.state.dataset;
        let name = e.value;

        let filteredDataset = dataset.filter(obj => {
            return obj.name === name;
        });

        let stockCode = filteredDataset[0].codigo_bolsa;

        let stockData = await axios.get(`https://collector.oiegt.org/api/finance/${stockCode}`);

        let bolsas = [];

        if (filteredDataset[0].bolsas_de_valores.length > 0) {
            for (let i = 0; i < filteredDataset[0].bolsas_de_valores.length; i++) {
                bolsas.push(<p key={`b_${i}`}>{ filteredDataset[0].bolsas_de_valores[i] }</p>);
            }
        } else {
            bolsas.push(<p key={`b_${1}`}>—</p>);
        }

        let subsidiarias = [];

        if (filteredDataset[0].empnacional_set.length > 0) {
            for (let i = 0; i < filteredDataset[0].empnacional_set.length; i++) {
                subsidiarias.push(<p key={`s_${i}`}>{ filteredDataset[0].empnacional_set[i].razon_social }</p>);
            }
        } else {
            subsidiarias.push(<p key={`s_${1}`}>—</p>);
        }

        this.setState({
            filteredDataset: filteredDataset,
            selectedType: e,
            bolsas: bolsas,
            subsidiarias: subsidiarias,
            stockData: stockData.data,
            width: viewerWidth,
            height: 400
        });
    }

    async changeSelectCommodities(e) {
        this.setState({
            commoditiesData: [],
            widthCommodities: 0,
            heightCommodities: 0
        });

        let viewerWidth = 0;
        if (isMobile) {
            viewerWidth = window.innerWidth - 40;
        }
        else {
            viewerWidth = (window.innerWidth - 100);
        }

        let commoditiesCode = e.value;

        let commoditiesData = await axios.get(`https://collector.oiegt.org/api/finance/${commoditiesCode}`);

        this.setState({
            commodityValue: e,
            commoditiesData: commoditiesData.data,
            widthCommodities: viewerWidth,
            heightCommodities: 400
        });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        let isLoaded = this.state.isLoaded;
        let data = this.state.filteredDataset;
        let bolsas = this.state.bolsas;
        let subsidiarias = this.state.subsidiarias;
        let width = this.state.width;
        let height = this.state.height;
        let widthCommodities = this.state.widthCommodities;
        let heightCommodities = this.state.heightCommodities;
        let stockData = this.state.stockData;
        let commoditiesData = this.state.commoditiesData;
        let value = this.state.selectedType;
        let commodityValue = this.state.commodityValue;

        return (
            isLoaded ?
            <div>
                <div className="project-module-header">
                    <h1 className="section-title">Corporaciones de Guatemala</h1>
                    <Select
                        className="project-module-select"
                        styles={customStyles}
                        options={this.state.selectEmpresas}
                        placeholder={"Proyectos Extractivos"}
                        maxMenuHeight={200}
                        isClearable={false}
                        value={value}
                        onChange={e => this.changeSelect(e)}
                    />
                </div>
                <div className="projectInformation">
                    <div className="project-information-content">
                        <div>
                            <h1>{ data[0].name }</h1>
                            <h3>País de origen</h3>
                            <p>{ data[0].id_pais ? data[0].id_pais: "—" }</p>
                        </div>
                        <div>
                            <h3>Símbolo en la bolsa</h3>
                            <p className="uppercase">{ data[0].codigo_bolsa ? data[0].codigo_bolsa : "—" }</p>
                            <h3>Bolsas en las que participa</h3>
                            <div>{ bolsas }</div>
                            <h3>Subsidiarias</h3>
                            <div>{ subsidiarias }</div>
                        </div>
                    </div>
                </div>
                <CorporationStock width={width} height={height} data={stockData} />
                <div style={{paddingTop: "50px"}}>
                    <div className="project-module-header">
                        <h1 className="section-title">Mercado de materias primas{/* <TooltipModal title="Mercado de materias primas" text={tooltips.tooltip_commodities} />*/}</h1>
                        <Select
                            className="project-module-select"
                            styles={customStyles}
                            options={this.state.selectCommodities}
                            placeholder={"Materia prima"}
                            maxMenuHeight={200}
                            isClearable={false}
                            value={commodityValue}
                            onChange={e => this.changeSelectCommodities(e)}
                        />
                    </div>
                    <CommoditiesChart width={widthCommodities} height={heightCommodities} data={commoditiesData} />
                </div>
            </div> :
            <div>Cargando...</div>
        )
    }
}

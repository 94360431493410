import React from "react";

function Chevron(props) {

    return (
        <svg
            className={props.className}
            height={props.height}
            width={props.width}
            viewBox="-2 -2 36 36"
            xmlns="http://www.w3.org/2000/svg"
            >
            <circle className="chevron-circle" cx="16" cy="16" r="16" stroke="#009B84" />
            <polyline className="chevron-pointer" points="23,10.4 16.5,21.6 9,10.4" fill="none" />
        </svg>
    );
}

export default Chevron;

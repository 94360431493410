import React from 'react';
import axios from 'axios';
import { toPng } from 'html-to-image';
import {isMobile} from 'react-device-detect';
import { BarStack, Line } from '@visx/shape';
import { SeriesPoint } from '@visx/shape/lib/types';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import cityTemperature, { CityTemperature } from '@visx/mock-data/lib/mocks/cityTemperature';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { timeParse, timeFormat } from 'd3-time-format';
import { withTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';

import ParentSize from '@visx/responsive/lib/components/ParentSize';

import SourceBox from './SourceBox';

import {
  Legend,
  LegendLinear,
  LegendQuantile,
  LegendOrdinal,
  LegendSize,
  LegendThreshold,
  LegendItem,
  LegendLabel,
} from '@visx/legend';

import { LinearGradient } from '@visx/gradient';

import TooltipModal from './TooltipModal';
import tooltips from './assets/tooltips'

const color1 = '#A5230E';
const color2 = '#E2281A';
const color3 = '#EDD926';
const color4 = '#A5230E';
const background = '#333333';
const background2 = '#964d18';
const accentColor = '#2eff8b';
const accentColorDark = '#75daad';
const defaultMargin = { top: 40, left: 50, right: 40, bottom: 100 };
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

function addCommas(nStr)
{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

const olddata = cityTemperature.slice(0, 12);

const legendGlyphSize = 15;

export default class PetrolRecoverableCostChart extends React.Component {
    constructor(props) {
        super(props);
        this.graphToImg = React.createRef();
        this.graphHeader = React.createRef();
    }

    state = {
        isLoaded: false,
        width: isMobile ? window.innerWidth - 40 : window.innerWidth - 100,
        height: 400,
    }

    async getData() {

        let res = await axios.get(`https://collector.oiegt.org/api/costos-recuperables-petroleo/${this.props.projectId}`);

        let finalData = [];
        let printables = [];

        for (var i = 0; i < res.data.length; i++) {
            printables.push(
                <div key={`prtbl_${i}`}>
                    <h2>{res.data[i].mes}, {res.data[i].anio}</h2>
                    <p>Total: USD {res.data[i].total_dolares}</p>
                </div>
            );
        }

        if (res.data.length > 0) {

            const keys = Object.keys(res.data[0]).filter(function(d) {
                if (d !== 'mes' && d !== 'id_contrato' && d !== 'comentario') {
                    return d;
                }
            });

            let data = res.data.map(function(item) {
                let newItem = {}
                keys.forEach(function(key) {
                    newItem[key] = item[key];
                });
                return newItem;
            });

            let years = [...new Set(data.map(item => item.anio))];
            years.sort();

            years.forEach(function(year) {
                let singleYear = data.filter(function(item) {
                    if (item.anio === year) {
                        return item;
                    }
                });
                let newItem = {
                    anio: year
                }
                keys.forEach(function(key) {
                    if (key !== "anio") {
                        let singleYearProfit = singleYear.reduce((acc, obj) => {
                            acc += Number(obj[key]);
                            return acc;
                        }, 0);
                        newItem[key] = singleYearProfit;
                    }
                });

                finalData.push(newItem);
            });

        }
        this.setState({
            isLoaded: true,
            dataset: res.data,
            finalData: finalData,
            printables: printables
        });
    }

    graphToImgClick = () => {
        const graph = this.graphToImg.current;

        const header = this.graphHeader.current;

        header.style.display = "flex";

        let graphClone = graph.cloneNode(true);
        let headerClone = header.cloneNode(true);

        let div = document.createElement("div");

        div.appendChild(headerClone);
        div.appendChild(graphClone);

        let yyyy = new Date().YYYYMMDDHHMMSS();

        toPng(graph)
          .then((dataUrl) => {
            const link = document.createElement('a')
            // link.download = this.state.sitio + "_" + this.state.title + '.png'
            link.download = 'OIE_costos_recuperables_petroleo_' + this.props.projectId + "_" + yyyy + '.png'
            link.href = dataUrl
            link.click()
            header.style.display = "none";
          })
          .catch((err) => {
            console.log(err)
        });
    }

    componentDidMount() {
        this.getData();
    }

    render () {
        if (this.state.isLoaded) {
            if (this.state.dataset.length > 0) {
                return (
                    <div>
                        <div className="project-module-header">
                            <h1 className="section-title white">Costos Recuperables</h1>
                            <TooltipModal title="Costos recuperables" text={tooltips.petrol_recoverable_costs} />
                        </div>
                        <ParentSize>
                            {({ width, height }) => {
                                return (
                                    <div ref={this.graphToImg} className="graphWrapper">
                                        <div className="graphHeader" ref={this.graphHeader}>
                                            <img src={require("./assets/images/oie-logo_big.svg")} width="120px" />
                                            <div className="graphTitle">
                                                Costos recuperables petróleo {this.props.projectId}
                                            </div>
                                        </div>
                                        <div className="graphContainer">
                                            <ChartWithTooltip data={this.state.finalData} width={width} height={400} />
                                        </div>
                                        <SourceBox source={tooltips.source_petrol_recoverablecosts} />
                                    </div>
                                );
                            }}
                        </ParentSize>
                        <div className="printables">
                            {this.state.printables}
                        </div>
                        <a className="graphToImgBtn" onClick={this.graphToImgClick}><p>Descargar gráfica <span className="material-icons material-icons-outlined">file_download</span></p></a>
                    </div>
                )

            }
            else {
                return (
                    <div className="still-looking">
                        <img src={require('./assets/images/oie-stilllooking.svg')} />
                    </div>
                )
            }
        }
        else {
            return (
                <div>Cargando...</div>
            )
        }
    }
}

const ChartWithTooltip = withTooltip(
    ({
        data,
        width,
        height,
        events = false,
        tooltipOpen,
        tooltipLeft,
        tooltipTop,
        tooltipData,
        hideTooltip,
        showTooltip,
    }) => {

        const keys = Object.keys(data[0]).filter(function(d) {
            if (d !== 'anio' && d !== 'mes') {
                return d;
            }
        });

        const totals = data.reduce((obj, val) => {
            const totalSingle = keys.reduce((total, i) => {
                total += Number(val[i]);
                return total;
            }, 0);
            obj.push(totalSingle);
            return obj;
        }, []);

        // accessors
        const getDate = (d) => d.anio;

        // scales
        const yScale = scaleLinear({
            domain: [0, Math.max(...totals)],
            nice: true,
        });

        const xScale = scaleBand({
            domain: data.map(getDate),
            padding: 0.2,
        });

        const colorScale = scaleOrdinal({
            domain: keys,
            range: [color1, color2, color3],
        });

        let tooltipTimeout: number;

        let margin;

        // bounds
        if (width > 900) {
            margin = { top: 40, left: 100, right: 100, bottom: 80 };
        }
        else {
            margin = { top: 40, left: 50, right: 50, bottom: 80 };
        }

        let xMax = width - margin.left - margin.right;
        let yMax = height - margin.top - margin.bottom;

        yScale.range([yMax, 0]);
        xScale.rangeRound([0, xMax]);

        return width < 10 ? null : (
            <div>
                <svg width={width} height={height}>
                    <rect width={width} height={height} fill="url(#area-background-gradient)" rx={14} />
                    <LinearGradient id="area-background-gradient" from={background} to={background2} />
                    <LinearGradient id="area-gradient" from={accentColor} to={accentColor} toOpacity={0.1} />
                    <Group top={margin.top} left={margin.left}>
                        <BarStack
                            data={data}
                            keys={keys}
                            height={yMax}
                            x={getDate}
                            xScale={xScale}
                            yScale={yScale}
                            color={colorScale}
                            >
                            {barStacks =>
                                barStacks.map(barStack =>
                                    barStack.bars.map(bar => (
                                        <rect
                                            key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                                            x={bar.x}
                                            y={bar.y}
                                            width={bar.width}
                                            height={bar.height}
                                            fill={bar.color}
                                            onMouseLeave={() => {
                                                tooltipTimeout = window.setTimeout(() => {
                                                    hideTooltip();
                                                }, 300);
                                            }}
                                            onMouseMove={() => {
                                                if (tooltipTimeout) clearTimeout(tooltipTimeout);
                                                const top = bar.y + margin.top;
                                                const left = bar.x + bar.width + margin.left;
                                                showTooltip({
                                                    tooltipData: bar,
                                                    tooltipTop: top,
                                                    tooltipLeft: left,
                                                });
                                            }}
                                            />
                                    )),
                                )
                            }
                        </BarStack>

                        <AxisBottom
                            top={yMax}
                            scale={xScale}
                            stroke={color3}
                            tickStroke={color3}
                            >
                            {props => {
                                console.log("widdddthththtth", width);
                                let rotate, anchor;
                                if (width > 900) {
                                    rotate = 0;
                                    anchor = "middle"
                                }
                                else {
                                    rotate = 90;
                                    anchor = "start"
                                }
                                const tickLabelSize = 10;
                                const tickRotate = rotate;
                                const tickColor = '#8e205f';
                                const axisCenter = (props.axisToPoint.x - props.axisFromPoint.x) / 2;
                                return (

                                    <g className="my-custom-bottom-axis">
                                        <line class="visx-line visx-axis-line" x1={props.axisFromPoint.x} y1="0" x2={props.axisToPoint.x} y2="0" fill="transparent" shape-rendering="crispEdges" stroke={color4} stroke-width="1"></line>
                                        {props.ticks.map((tick, i) => {
                                            const tickX = tick.to.x;
                                            const tickY = tick.to.y + tickLabelSize + props.tickLength;
                                            return (
                                                <Group
                                                    key={`vx-tick-${tick.value}-${i}`}
                                                    className={'vx-axis-tick'}
                                                    >
                                                    <Line
                                                        from={tick.from}
                                                        to={tick.to}
                                                        stroke={tickColor}
                                                        />
                                                    <text
                                                        transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                                                        fontSize={tickLabelSize}
                                                        textAnchor={anchor}
                                                        fill={tickColor}
                                                        >
                                                        {tick.formattedValue}
                                                    </text>
                                                </Group>
                                            );
                                        })}
                                        <text
                                            textAnchor="middle"
                                            transform={`translate(${axisCenter}, 50)`}
                                            fontSize="8"
                                            >
                                            {props.label}
                                        </text>
                                    </g>
                                );
                            }}
                        </AxisBottom>

                        <AxisLeft
                            left={0}
                            label={"USD"}
                            labelOffset={70}
                            scale={yScale}
                            >
                            {props => {
                                let rotate, label, tickLabelSize;
                                const tickColor = '#8e205f';
                                const axisCenter = (props.axisFromPoint.y - props.axisToPoint.y) / 2 * -1;

                                console.log("widdddthththtth", width);
                                if (width > 900) {
                                    tickLabelSize = 10;
                                    rotate = 0;
                                    label =
                                    <text
                                        textAnchor="middle"
                                        x={axisCenter}
                                        y={-82}
                                        transform={`rotate(${-90})`}
                                        fontSize="12"
                                        >
                                        {props.label}
                                    </text>
                                }
                                else {
                                    tickLabelSize = 8;
                                    rotate = -45;
                                    label =
                                    <text
                                        textAnchor="end"
                                        x={0}
                                        y={-10}
                                        fontSize="10"
                                        >
                                        {props.label}
                                    </text>
                                }

                                const tickRotate = rotate;

                                return (

                                    <g className="my-custom-bottom-axis">
                                        <line class="visx-line visx-axis-line" x1="0" y1={props.axisFromPoint.y} x2="0" y2={props.axisToPoint.y} fill="transparent" shape-rendering="crispEdges" stroke={color4} stroke-width="1"></line>
                                        {props.ticks.map((tick, i) => {
                                            const tickX = -8;
                                            // const tickY = tick.to.y + (props.axisFromPoint.y / props.numTicks);
                                            const tickY = tick.to.y;
                                            console.log("this is a prop", props);
                                            console.log("this is a tick", tick);
                                            return (
                                                <Group
                                                    key={`vx-tick-${tick.value}-${i}`}
                                                    className={'vx-axis-tick'}
                                                    >
                                                    <Line
                                                        from={tick.from}
                                                        to={tick.to}
                                                        stroke={tickColor}
                                                        />
                                                    <text
                                                        transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                                                        fontSize={tickLabelSize}
                                                        textAnchor="end"
                                                        fill={tickColor}
                                                        >
                                                        {tick.formattedValue}
                                                    </text>
                                                </Group>
                                            );
                                        })}
                                        {label}
                                    </g>
                                );
                            }}
                        </AxisLeft>


                    </Group>
                </svg>
                <div
                    style={{
                        position: 'absolute',
                        top: margin.top / 2 - 10,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '14px',
                    }}
                    >
                </div>
                {tooltipOpen && tooltipData && (
                    <Tooltip className="visxTooltip" top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
                        <div style={{ color: colorScale(tooltipData.key) }}>
                            <strong>Monto recuperado</strong>
                        </div>
                        <div>USD {addCommas(tooltipData.bar.data[tooltipData.key].toFixed(2))}</div>
                        <div>
                            <small>{getDate(tooltipData.bar.data)}</small>
                        </div>
                    </Tooltip>
                )}
            </div>
        );
    },
);

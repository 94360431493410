import React, { useState } from 'react';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { Group } from '@visx/group';
import {
  Treemap,
  hierarchy,
  stratify,
  treemapSquarify,
  treemapBinary,
  treemapDice,
  treemapResquarify,
  treemapSlice,
  treemapSliceDice,
} from '@visx/hierarchy';
import { TileMethod } from '@visx/hierarchy/lib/types';
import shakespeare, { Shakespeare } from '@visx/mock-data/lib/mocks/shakespeare';

import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';

import { withTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';

import {
  Legend,
  LegendLinear,
  LegendQuantile,
  LegendOrdinal,
  LegendSize,
  LegendThreshold,
  LegendItem,
  LegendLabel,
} from '@visx/legend';

import { LinearGradient } from '@visx/gradient';

import TooltipModal from './TooltipModal';
import tooltips from './assets/tooltips';

const palette = [
    "#341700"
    , "#512700"
    , "#6B3800"
    , "#834A00"
    , "#985C01"
    , "#AA6E00"
    , "#B97F06"
    , "#CD9300"
    , "#E1AD00"
];

const dummyData = stratify()
  .id(d => d.id)
  .parentId(d => d.parent)(shakespeare)
  .sum(d => d.size || 0);


const paletteH = ["#341700", "#512700", "#6B3800", "#834A00", "#985C01", "#AA6E00", "#B97F06", "#CD9300", "#E1AD00", "#F5CB00"];

const color1 = '#A5230E';
const color2 = '#c998ff';
const color3 = "#341700";
const background = '#333333';
const background2 = '#964d18';
const accentColor = '#2eff8b';
const accentColorDark = '#75daad';
const defaultMargin = { top: 40, left: 50, right: 40, bottom: 100 };
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

function addCommas(nStr)
{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

const legendGlyphSize = 15;

export default class GeneralExtractionsTreemap extends React.Component {
    constructor(props) {
      super(props);
    }

    state = {
        isLoaded: false,
        width: isMobile ? window.innerWidth - 40 : window.innerWidth - 100,
        height: 400
    }

    async getData() {

        let res = await axios.get(`https://collector.oiegt.org/api/extracciones`);

        let finalData = [];

        if (res.data.length > 0) {

            const dataset = res.data.reduce(function(obj, v) {
                let informacion_explotacion = [];
                for (let i = 0; i < v.informacion_explotacion.length; i++) {
                    if (
                        v.informacion_explotacion[i].producto_minero !== "mena de oro y plata" &&
                        v.informacion_explotacion[i].producto_minero !== "plata" &&
                        v.informacion_explotacion[i].producto_minero !== "oro"
                    ) {
                        informacion_explotacion.push(v.informacion_explotacion[i]);
                    }
                }
                v.informacion_explotacion = informacion_explotacion;
                if (informacion_explotacion.length > 0) {
                    obj.push(v);
                }
                return obj;
            }, []);

            console.log("dataset", dataset);

            let tempDataset = [];

            let keys = [];

            tempDataset.push(
                {
                    id: "Proyectos",
                    parent: null,
                    size: 0
                }
            );

            dataset.forEach(function(item) {
                let newItem = {};
                newItem.id = item.id_der_minero;
                newItem.parent = "Proyectos";
                newItem.size = 0;
                tempDataset.push(newItem);
                keys.push(item.id_der_minero);
            });

            dataset.forEach(function(item) {
                let newItem = {};
                item.informacion_explotacion.forEach(function(subItem) {
                    newItem.id = subItem.producto_minero;
                    newItem.parent = item.id_der_minero;
                    newItem.size = subItem.cantidad_extraida;
                    tempDataset.push(newItem);
                });
                // console.log(item.id_der_minero);
            });

            console.log("tempDataset", tempDataset);

            const finalDataset = stratify()
              .id(d => d.id)
              .parentId(d => d.parent)(tempDataset)
              .sum(d => d.size || 0);

            console.log("tempDataset", tempDataset);

            this.setState({
                isLoaded: true,
                dataset: res.data,
                rawDataset: tempDataset,
                finalDataset: finalDataset,
                keys: keys
            });

        }
    }

    componentDidMount() {
        this.getData();
    }

    render () {
        if (this.state.isLoaded) {
            if (this.state.dataset.length > 0) {
                return (
                    <ChartWithTooltip data={this.state.finalDataset} width={this.state.width} height={this.state.height} rawDataset={this.state.rawDataset} keys={this.state.keys} />
                )

            }
            else {
                return (
                    <div className="still-looking">
                        <img src={require('./assets/images/oie-stilllooking.svg')} />
                    </div>
                )
            }
        }
        else {
            return (
                <div>Cargando...</div>
            )
        }
    }
}

const ChartWithTooltip = withTooltip(
  ({
    data,
    rawDataset,
    keys,
    width = 800,
    height = 400,
    events = true,
    margin = defaultMargin,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  }) => {

      const xMax = width - margin.left - margin.right;
      const yMax = height - margin.top - margin.bottom;
      const root = hierarchy(data).sort((a, b) => (b.value || 0) - (a.value || 0));

      // const colorScale = scaleLinear({
      //   domain: [0, Math.max(...rawDataset.map(d => d.size || 0))],
      //   range: [color2, color1],
      // });

      console.log("them keys", keys);

      const colorScale = scaleOrdinal({
        domain: keys,
        range: palette,
      });

      return width < 10 ? null : (
        <div>
            <div className="project-module-header">
                <h1 className="section-title white">Exportaciones por proyecto</h1>
                <TooltipModal title="Ingresos de petróleo a la nación" text={tooltips.petrol_earnings} />
            </div>

          <div>
            <svg width={width} height={height}>
              <rect width={width} height={height} rx={14} fill={background} />
              <Treemap
                top={margin.top}
                root={root}
                size={[xMax, yMax]}
                tile={treemapSquarify}
                round
              >
                {treemap => (
                  <Group>
                    {treemap
                      .descendants()
                      .reverse()
                      .map((node, i) => {
                        const nodeWidth = node.x1 - node.x0;
                        const nodeHeight = node.y1 - node.y0;
                        return (
                          <Group
                            key={`node-${i}`}
                            top={node.y0 + margin.top}
                            left={node.x0 + margin.left}
                          >
                            {node.depth === 1 && (
                              <rect
                                width={nodeWidth}
                                height={nodeHeight}
                                stroke={background}
                                strokeWidth={4}
                                fill={colorScale(node.value || 0)}
                              />
                            )}
                            {node.depth > 2 && (
                              <rect
                                width={nodeWidth}
                                height={nodeHeight}
                                stroke={background}
                                fill={colorScale(node.value || 0)}
                              />
                            )}
                          </Group>
                        );
                      })}
                  </Group>
                )}
              </Treemap>
            </svg>
          </div>
          <LegendDemo title="Proyectos">
              <LegendOrdinal scale={colorScale} >
                  {labels => (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                          {labels.map((label, i) => (
                              <LegendItem
                                  key={`legend-quantile-${i}`}
                                  margin="0 5px"
                                  onClick={() => {
                                      if (events) alert(`clicked: ${JSON.stringify(label)}`);
                                  }}
                                  >
                                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                                      <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                                  </svg>
                                  <LegendLabel align="left" margin="0 0 0 4px">
                                      {label.text.replace(/_/g, " ")}
                                  </LegendLabel>
                              </LegendItem>
                          ))}
                      </div>
                  )}
              </LegendOrdinal>
          </LegendDemo>
        </div>
      );

  },
);

function LegendDemo({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <div className="visxLegend">
      <div className="visxLegendTitle">{title}</div>
      {children}
    </div>
  );
}

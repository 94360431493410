import React, { useState }  from 'react';
import { loadModules } from 'esri-loader';
import {isMobile} from 'react-device-detect';
import axios from 'axios';
import styles from './AguaWebMapView.module.css';
import { range } from 'd3';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from "react-select";
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';

import tooltips from '../../assets/tooltips';
import TooltipModal from "../../TooltipModal";

import coguanor from './coguanor.png';


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? '#FFF' : '#FFF',
        backgroundColor: '#E67600'
    }),
    control: styles => ({
        ...styles,
        color: '#000',
        backgroundColor: '#FFF',
        width: '100%'
    }),
    menu: styles => ({
        ...styles,
        color: '#FFF',
        backgroundColor: '#E67600',
        border: '1px solid white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#000'
    })
}

const sliderMarks = {

}

export default class AguaWebMapView extends React.Component {

    state = {
        dataset: [],
        comunales: false,
        cuencas: false,
        cuerpos: false,
        proyectos_ext: true,
        proyectos_exp: true,
        petroleras: false,
        lugares: false,
        SETH: false,
        calidadAguaValue: "arsenico",
        ProyectosMinerosLayers: [],
        CuencasLayer: null,
        whatToFilter: "arsenico",
        marks: [
            {
                value: 0,
                label: '0',
            },
            {
                value: 10,
                label: '10',
            },
            {
                value: 100,
                label: '100',
            },
        ],
        newValue: [0, 100],
        defaultValueSlider: [0, 100],
        maxSlider: 100,
        valueSlider: [0, 100],
        titleSlider: "Rangos arsénico",
        legendSlider: "Unidades en ppm, LPM: 10",
        // currentDate: new Date('2014-08-18T21:11:54'),
        initialDate: new Date('2018-01-02'),
        finalDate: new Date(),
        filterCriteria: [],
        filterCriteriaObj: {}
        // locale: "es"
    }

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.sliderRef = React.createRef();
    }

    async getData() {

        this.res = await axios.get(`https://collector.oiegt.org/api/mapa-agua/`);

        let muestreos = this.res.data.filter(function(obj) {
            return obj.no_muestreo !== null;
        }).map(function(obj) {
            return obj.no_muestreo;
        });

        muestreos = [...new Set(muestreos)].sort();

        const selectMuestreos = muestreos.map(function(obj) {
            const options = {};

            options.value = obj.toString();
            options.label = obj.toString();

            return options;
        });

        let fuentes = this.res.data.map(function(obj) {
            return obj.muestreo.tipo_fuente;
        });

        fuentes = [...new Set(fuentes)];

        const selectFuentes = fuentes.map(function(obj) {
            const options = {};

            console.log("objjj", obj);

            if (obj) {
                switch (obj.toString()) {
                    case "Tanque de Distribución/Captación":
                        options.label = <div><img src={require("./icons/icon-gs-water-well-ok.svg")} width="20px" height="20px" /> {obj.toString()}</div>;
                        break;
                    case "Pozo":
                        options.label = <div><img src={require("./icons/icon-gs-water-well-ok.svg")} width="20px" height="20px" /> {obj.toString()}</div>;
                        break;
                    case "Rio o Quebrada":
                        options.label = <div><img src={require("./icons/icon-gs-water-river-ok.svg")} width="20px" height="20px" /> {obj.toString()}</div>;
                        break;
                    case "Nacimiento":
                        options.label = <div><img src={require("./icons/icon-gs-water-source-ok.svg")} width="20px" height="20px" /> {obj.toString()}</div>;
                        break;
                    case "Hogar":
                        options.label = <div><img src={require("./icons/icon-gs-water-source-ok.svg")} width="20px" height="20px" /> {obj.toString()}</div>;
                        break;
                    case "Laguna":
                        options.label = <div><img src={require("./icons/icon-gs-water-source-ok.svg")} width="20px" height="20px" /> {obj.toString()}</div>;
                }

                options.value = obj.toString();
            }

            return options;
        });

        let filteredDataset = this.res.data.filter(item => {
            return item.no_muestreo === parseInt(muestreos[muestreos.length-1]);
        });

        this.setState({
            dataset: this.res.data,
            // filteredDataset: this.res.data,
            filteredDataset: filteredDataset,
            selectFuentes: selectFuentes,
            selectMuestreos: selectMuestreos
        });

        // lazy load the required ArcGIS API for JavaScript modules and CSS
        loadModules([
            "esri/Map",
            "esri/views/ui/DefaultUI",
            "esri/views/MapView",
            "esri/layers/FeatureLayer",
            "esri/symbols/PictureMarkerSymbol",
            "esri/Graphic",
            "esri/layers/GraphicsLayer",
            "esri/config"
        ], { css: true })
        .then(([
            ArcGISMap,
            DefaultUI,
            MapView,
            FeatureLayer,
            PictureMarkerSymbol,
            Graphic,
            GraphicsLayer,
            esriConfig
        ]) => {
            esriConfig.request.corsDetection = false;

            this.map = new ArcGISMap({
                basemap: 'topo-vector'
            });

            let comunalesLabelTemplate = {
                title: "Tierras comunales",
                outFields: ["*"],
                content: [
                    {
                        type: "fields",
                        fieldInfos: [
                            {
                                fieldName: "NOMBRE_TC",
                                visible: true,
                                label: "Nombre"
                            },
                            {
                                fieldName: "DEPTO",
                                visible: true,
                                label: "Departamento"
                            },
                            {
                                fieldName: "MUNICIPIO",
                                visible: true,
                                label: "Municipio"
                            }
                        ]
                    }
                ]
            }

            let tierrasClusterConfig = {
                type: "cluster",
                clusterRadius: "100px",
                // {cluster_count} is an aggregate field containing
                // the number of features comprised by the cluster
                popupTemplate: {
                    content: "{cluster_count} tierras comunales.",
                        fieldInfos: [
                            {
                                fieldName: "cluster_count",
                                format: {
                                    places: 0,
                                    digitSeparator: true
                                }
                            }
                        ]
                },
                clusterMinSize: "40px",
                clusterMaxSize: "80px",
                labelingInfo: [
                    {
                        deconflictionStrategy: "none",
                        labelExpressionInfo: {
                            expression: "Text($feature.cluster_count, '#,###')"
                        },
                        symbol: {
                            type: "text",
                            color: "#004a5d",
                            font: {
                                weight: "bold",
                                family: "Arial",
                                size: "12px"
                            }
                        },
                        labelPlacement: "center-center"
                    }
                ]
            };

            this.TierrasComunalesLayer = new FeatureLayer({
                url: "https://services6.arcgis.com/iJG4JMRzzDbGWkSI/arcgis/rest/services/tierrascomunales/FeatureServer",
                labelsVisible: true,
                featureReduction: tierrasClusterConfig,
                popupTemplate: comunalesLabelTemplate,
                renderer: {
                    type: "simple",
                    field: "mag",
                    symbol: {
                        type: "simple-marker",
                        size: 4,
                        color: "#FDFF75",
                        outline: {
                            color: "#000000",
                            width: 2
                        }
                    }
                },
                visible: this.state.comunales
            });

            this.map.layers.add(this.TierrasComunalesLayer);

            let cuencasLabelTemplate = {
                title: "Cuenca",
                outFields: ["*"],
                content: [
                    {
                        type: "fields",
                        fieldInfos: [
                            {
                                fieldName: "CUENCA",
                                visible: true,
                                label: "Nombre"
                            },
                        ]
                    }
                ]
            }

            this.CuencasLayer = new FeatureLayer({
                url: "https://services6.arcgis.com/iJG4JMRzzDbGWkSI/arcgis/rest/services/cuencas_gral/FeatureServer/0?token=duiNXcxu5DlYBzdbJnNWMjCH1cXx2geIru-Rb7Gi3UWbEfKQCKFwtvAG_k5CCPKxYG3MD9yAMPLfvYw3OAmst4gNZpEXBaZFIGQoMX2n21a5FcgB4EHEm7WVGCVMS0YRKo-FZrYEJdrE9lMCFT2q0D8kkCeQnnOGghR-3X6SZrlCwSKy62OFshoLQvBal4uQXWoPOJgt-WFfT9kUHsfyT2LpWwjxBCbCZzlS5dW6Y1A.",
                labelsVisible: true,
                popupTemplate: cuencasLabelTemplate,
                visible: this.state.cuencas
            });

            this.map.layers.add(this.CuencasLayer);

            let cuerposLabelTemplate = {
                title: "Cuerpo de agua",
                outFields: ["*"],
                content: [
                    {
                        type: "fields",
                        fieldInfos: [
                            {
                                fieldName: "nombre",
                                visible: true,
                                label: "Nombre"
                            }
                        ]
                    }
                ]
            }

            this.CuerposAguaLayer = [];

            for (let i = 0; i < 2; i++) {
                if (i === 0) {
                    var CuerposAguaLayer = new FeatureLayer({
                        url: "https://services6.arcgis.com/iJG4JMRzzDbGWkSI/arcgis/rest/services/cuerposagua/FeatureServer/"+i,
                        labelsVisible: true,
                        popupTemplate: cuerposLabelTemplate,
                        renderer: {
                          type: "simple",
                          symbol: {
                              type: "simple-line",
                              style: "solid",
                              color: [0, 255, 255, 0.5],
                              width: 1
                          }
                        },
                        visible: this.state.cuerpos
                    });
                }
                else {
                    var CuerposAguaLayer = new FeatureLayer({
                        url: "https://services6.arcgis.com/iJG4JMRzzDbGWkSI/arcgis/rest/services/cuerposagua/FeatureServer/"+i,
                        labelsVisible: true,
                        renderer: {
                          type: "simple",
                          symbol: {
                              type: "simple-fill",
                              style: "solid",
                              color: [0, 255, 255, 0.5],
                              width: 1,
                              outline: {
                                  color: "#000000",
                                  width: 2
                              }

                          }
                        },
                        visible: this.state.cuerpos
                    });
                }

                this.CuerposAguaLayer.push(CuerposAguaLayer);

                this.map.layers.add(this.CuerposAguaLayer[i]);
            }

            //Las capas de proyecto extractivo vienen todas juntas. Son 4 capas, hacer un loop para recorrerlas y plottear las 4 o plottearlas individualmente

            function projectContent(feature) {

                let div = document.createElement("div");
                let name = feature.graphic.attributes.NOMBRE;
                let register = feature.graphic.attributes.REGISTRO;
                div.innerHTML =
                `
                <p><strong>Nombre:</strong> ${name}</p>
                <p><strong>Registro:</strong> ${register}</p>
                `
                return div;
            }

            let proyectosLabelTemplate = {
                title: "Proyecto",
                outFields: ["NOMBRE, REGISTRO, layer"],
                content: [
                    {
                        type: "fields",
                        fieldInfos: [
                            {
                                fieldName: "NOMBRE",
                                visible: true,
                                label: "Nombre"
                            },
                            {
                                fieldName: "REGISTRO",
                                visible: true,
                                label: "Registro"
                            }
                        ]
                    }
                ]
            }

            this.ProyectosMinerosExtLayer = new FeatureLayer({
                url: "https://services6.arcgis.com/iJG4JMRzzDbGWkSI/arcgis/rest/services/proyectos_mineros/FeatureServer/0",
                labelsVisible: true,
                renderer: {
                    type: "simple",
                    symbol: {
                        type: "simple-fill",
                        style: "solid",
                        color: [255, 0, 0, 0.5]
                    }
                },
                popupTemplate: proyectosLabelTemplate
            });

            this.map.layers.add(this.ProyectosMinerosExtLayer);

            this.ProyectosMinerosExpLayer = new FeatureLayer({
                url: "https://services6.arcgis.com/iJG4JMRzzDbGWkSI/arcgis/rest/services/proyectos_mineros/FeatureServer/1",
                labelsVisible: true,
                renderer: {
                    type: "simple",
                    symbol: {
                        type: "simple-fill",
                        style: "solid",
                        color: [255, 225, 56, 0.5]
                    }
                },
                popupTemplate: proyectosLabelTemplate
            });

            this.map.layers.add(this.ProyectosMinerosExpLayer);

            let SETHLabelTemplate = {
                title: "Proyecto",
                outFields: ["NOMBRE, REGISTRO, layer"],
                content: [
                    {
                        type: "fields",
                        fieldInfos: [
                            {
                                fieldName: "NOMBRE",
                                visible: true,
                                label: "Nombre"
                            },
                            {
                                fieldName: "REGISTRO",
                                visible: true,
                                label: "Registro"
                            }
                        ]
                    }
                ]
            }

            this.SETHLayer = new FeatureLayer({
                url: "https://services6.arcgis.com/iJG4JMRzzDbGWkSI/arcgis/rest/services/petroleo2020/FeatureServer/0",
                labelsVisible: true,
                renderer: {
                    type: "simple",
                    symbol: {
                        type: "simple-line",
                        style: "solid",
                        color: [173, 0, 0, 1],
                        width: 2
                    }
                },
                popupTemplate: SETHLabelTemplate,
                visible: this.state.SETH
            });

            this.map.layers.add(this.SETHLayer);

            let petrolerasLabelTemplate = {
                title: "Contrato petrolero",
                outFields: ["*"],
                content: [
                    {
                        type: "fields",
                        fieldInfos: [
                            {
                                fieldName: "Nombre",
                                visible: true,
                                label: "Nombre"
                            },
                            {
                                fieldName: "Contrato",
                                visible: true,
                                label: "Contrato"
                            },
                            {
                                fieldName: "Tipo",
                                visible: true,
                                label: "Tipo"
                            }
                        ]
                    }
                ]
            }

            this.LicenciasPetrolerasLayer = new FeatureLayer({
                url: "https://services6.arcgis.com/iJG4JMRzzDbGWkSI/arcgis/rest/services/petroleo2020/FeatureServer/1",
                labelsVisible: true,
                renderer: {
                    type: "simple",
                    symbol: {
                        type: "simple-fill",
                        style: "solid",
                        color: [
                            0,
                            0,
                            0,
                            0.5
                        ]
                    }
                },
                popupTemplate: petrolerasLabelTemplate,
                visible: this.state.petroleras
            });

            this.map.layers.add(this.LicenciasPetrolerasLayer);

            let lugaresClusterConfig = {
                type: "cluster",
                clusterRadius: "20px",
                // {cluster_count} is an aggregate field containing
                // the number of features comprised by the cluster
                popupTemplate: {
                    content: "{cluster_count} lugares poblados.",
                        fieldInfos: [
                            {
                                fieldName: "cluster_count",
                                format: {
                                    places: 0,
                                    digitSeparator: true
                                }
                            }
                        ]
                },
                clusterMinSize: "40px",
                clusterMaxSize: "80px",
                labelingInfo: [
                    {
                        deconflictionStrategy: "none",
                        labelExpressionInfo: {
                            expression: "Text($feature.cluster_count, '#,###')"
                        },
                        symbol: {
                            type: "text",
                            color: "#004a5d",
                            font: {
                                weight: "bold",
                                family: "Arial",
                                size: "12px"
                            }
                        },
                        labelPlacement: "center-center"
                    }
                ]
            };

            let lugaresLabelTemplate = {
                title: "Lugar poblado",
                outFields: ["*"],
                content: [
                    {
                        type: "fields",
                        fieldInfos: [
                            {
                                fieldName: "lugar_pobl",
                                visible: true,
                                label: "Nombre"
                            },
                            {
                                fieldName: "departamen",
                                visible: true,
                                label: "Departamento"
                            },
                            {
                                fieldName: "municipio",
                                visible: true,
                                label: "Municipio"
                            },
                            {
                                fieldName: "categoria",
                                visible: true,
                                label: "Categoría"
                            }
                        ]
                    }
                ]
            }

            this.LugaresPobladosLayer = new FeatureLayer({
                url: "https://services6.arcgis.com/iJG4JMRzzDbGWkSI/arcgis/rest/services/lugares_poblados/FeatureServer/0?token=duiNXcxu5DlYBzdbJnNWMjCH1cXx2geIru-Rb7Gi3UWbEfKQCKFwtvAG_k5CCPKxYG3MD9yAMPLfvYw3OAmst4gNZpEXBaZFIGQoMX2n21a5FcgB4EHEm7WVGCVMS0YRKo-FZrYEJdrE9lMCFT2q0D8kkCeQnnOGghR-3X6SZrlCwSKy62OFshoLQvBal4uQXWoPOJgt-WFfT9kUHsfyT2LpWwjxBCbCZzlS5dW6Y1A.",
                labelsVisible: true,
                featureReduction: lugaresClusterConfig,
                popupTemplate: lugaresLabelTemplate,
                renderer: {
                    type: "simple",
                    field: "mag",
                    symbol: {
                        type: "simple-marker",
                        size: 4,
                        color: "#43BD5E",
                        outline: {
                            color: "#000000",
                            width: 2
                        }
                    }
                },
                visible: this.state.lugares
            });

            this.map.layers.add(this.LugaresPobladosLayer);

            this.view = new MapView({
                container: this.mapRef.current,
                map: this.map,
                center: [-90.410456,15.690665],
                zoom: 8,
                navigation: {
                    mouseWheelZoomEnabled: false,
                    browserTouchPanEnabled: false
                }
            });

            this.view.ui.move("zoom", "bottom-right");

        });

        this.createMarkers("arsenico", filteredDataset);
    }

    changeSelect(e, source) {

        let tempData = this.res.data;


        let filteredDataset = [];
        let markers = [];
        let links = [];
        let filterCriteria = this.state.filterCriteria;
        let filterCriteriaObj = this.state.filterCriteriaObj;

        filterCriteriaObj[source] = [];

        if (e !== null) {
            if (e !== null && e.length > 0) {
                for (var i = 0; i < e.length; i++) {
                    filterCriteria.push({value: e[i].value, type: source});
                    filterCriteriaObj[source].push(e[i].value);
                }
            }
            else {
                if (e.value !== undefined) {
                    filterCriteria.push({value: e.value, type: source});
                    filterCriteriaObj[source].push(e.value);
                }
            }
        }

        const filterKeys = Object.keys(filterCriteriaObj);
            // validates all filter criteria
        filterKeys.forEach(key => {
            if (key === "muestreo") {
                tempData = tempData.filter(item => {
                    if (!filterCriteriaObj[key].length) return true;
                    return filterCriteriaObj[key].find(filter => parseInt(filter) === item.no_muestreo);
                });
            }
            if (key === "tipo_fuente") {
                tempData = tempData.filter(item => {
                    if (!filterCriteriaObj[key].length) return true;
                    return filterCriteriaObj[key].find(filter => filter === item.muestreo[key]);
                });
            }

        });

        filteredDataset = tempData;

        this.map.remove(this.graphicsLayer);

        this.createMarkers(this.state.whatToFilter, filteredDataset);

        for (let i = 0; i < this.markers.length; i++) {
            this.markers[i].visible = false;
        }

        this.filterByValue(this.markers);

        let currentUID = this.markers[0].uid;

        for (let i = 0; i < this.filteredMarkers.length; i++) {
            this.markers[this.filteredMarkers[i].uid - currentUID].visible = true;
        }

        this.setState({
            filteredDataset: filteredDataset,
            filterCriteria: filterCriteria,
            filterCriteriaObj: filterCriteriaObj,
        });
    }

    titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }

    handleInitialDateChange = (date) => {
        this.setState({initialDate: new Date(date)})

        this.filterByDate(this.markers);
    };


    handleFinalDateChange = (date) => {
        this.setState({finalDate: new Date(date)})

        this.filterByDate(this.markers);
    };


    filterByDate = (markers) => {
        var finalDate = this.state.finalDate;
        var initialDate = this.state.initialDate;
        this.filteredMarkers = markers.filter(function(o) {
            var fechaRecoleccion = new Date(o.attributes.fecha_recoleccion);
            return fechaRecoleccion > initialDate && fechaRecoleccion < finalDate;
        });

        for (let i = 0; i < markers.length; i++) {
            markers[i].visible = false;
        }

        let currentUID = this.markers[0].uid;

        for (let i = 0; i < this.filteredMarkers.length; i++) {
            this.markers[this.filteredMarkers[i].uid - currentUID].visible = true;
        }
    }

    filterByValue = (markers) => {
        let newValue = this.state.newValue;

        switch (this.state.whatToFilter) {
            case "arsenico":
                this.filteredMarkers = markers.filter(function(o) {
                    // check value is within the range
                    // remove `=` if you don't want to include the range boundary
                    return o.attributes.arsenico < newValue[1] && o.attributes.arsenico >= newValue[0];
                });
                break;
            case "ph":
                this.filteredMarkers = markers.filter(function(o) {
                    return o.attributes.ph < newValue[1] && o.attributes.ph >= newValue[0];
                });
                break;
            case "cloro":
                this.filteredMarkers = markers.filter(function(o) {
                    let value = [newValue[0] / 100, newValue[1] / 100];
                    return o.attributes.cloro <= value[1] && o.attributes.cloro >= value[0];
                });
                break;
            case "turbiedad":
                this.filteredMarkers = markers.filter(function(o) {
                    return o.attributes.turbiedad <= newValue[1] && o.attributes.turbiedad >= newValue[0];
                });
                break;
            case "conductividad_muestra":
                this.filteredMarkers = markers.filter(function(o) {
                    return o.attributes.conductividad_muestra <= newValue[1] && o.attributes.conductividad_muestra >= newValue[0];
                });
        }
    }

    createMarkers(eventTargetValue, data) {

        loadModules([
            "esri/Map",
            "esri/views/ui/DefaultUI",
            "esri/views/MapView",
            "esri/layers/FeatureLayer",
            "esri/symbols/PictureMarkerSymbol",
            "esri/Graphic",
            "esri/layers/GraphicsLayer",
            "esri/config"
        ], { css: true })
        .then(([
            ArcGISMap,
            DefaultUI,
            MapView,
            FeatureLayer,
            PictureMarkerSymbol,
            Graphic,
            GraphicsLayer,
            esriConfig
        ]) => {

            this.graphicsLayer = new GraphicsLayer();

            this.map.add(this.graphicsLayer);

            this.markers = [];

            var symbol = {
                type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
                width: "30px",
                height: "30px"
            };

            for (let i = 0; i < data.length; i++) {
                let point = {
                    type: "point",
                    longitude: data[i].longitude,
                    latitude: data[i].latitude,
                }

                let fecha_recoleccion = data[i].fecha_recoleccion;
                let arsenico = data[i].muestreo.arsenico;


                if (arsenico) {
                    arsenico = parseInt(arsenico.replace(/[^a-zA-Z0-9 ]/g, ""));
                }
                else {
                    arsenico = "Data no disponible.";
                }

                let ph = data[i].muestreo.ph;
                let cloro = data[i].muestreo.cloro;
                let turbiedad = data[i].muestreo.turbiedad;
                let conductividad = data[i].muestreo.conductividad_muestra;
                let tipo_fuente = data[i].muestreo.tipo_fuente;

                let attributes = {
                    "arsenico": arsenico,
                    "ph": ph,
                    "cloro": cloro,
                    "turbiedad": turbiedad,
                    "conductividad_muestra": conductividad,
                    "name": data[i].nombre,
                    "fecha_recoleccion": fecha_recoleccion,
                    "tipo_fuente": tipo_fuente
                }

                let waterSourceType = data[i].muestreo.tipo_fuente;

                let limit;

                switch (eventTargetValue) {
                    case "arsenico":
                        if (arsenico > 10) {
                            limit = true;
                        }
                        else {
                            limit = false;
                        }
                        break;
                    case "ph":
                        if (ph > 7) {
                            limit = true;
                        }
                        else {
                            limit = false;
                        }
                        break;
                    case "cloro":
                        if (cloro > 50) {
                            limit = true;
                        }
                        else {
                            limit = false;
                        }
                        break;
                    case "turbiedad":
                        if (turbiedad > 5) {
                            limit = true;
                        }
                        else {
                            limit = false;
                        }
                        break;
                    case "conductividad_muestra":
                        if (conductividad > 750) {
                            limit = true;
                        }
                        else {
                            limit = false;
                        }
                }

                switch (waterSourceType) {
                    case "Tanque de Distribución/Captación":
                        if (limit) {
                            symbol.url = require("./icons/icon-gs-water-well-notok.svg");
                        }
                        else {
                            symbol.url = require("./icons/icon-gs-water-well-ok.svg");
                        }
                        break;
                    case "Pozo":
                        if (limit) {
                            symbol.url = require("./icons/icon-gs-water-well-notok.svg");
                        }
                        else {
                            symbol.url = require("./icons/icon-gs-water-well-ok.svg");
                        }
                        break;
                    case "Rio o Quebrada":
                        if (limit) {
                            symbol.url = require("./icons/icon-gs-water-river-notok.svg");
                        }
                        else {
                            symbol.url = require("./icons/icon-gs-water-river-ok.svg");
                        }
                        break;
                    case "Nacimiento":
                        if (limit) {
                            symbol.url = require("./icons/icon-gs-water-source-notok.svg");
                        }
                        else {
                            symbol.url = require("./icons/icon-gs-water-source-ok.svg");
                        }
                        break;
                    case "Hogar":
                        if (limit) {
                            symbol.url = require("./icons/icon-gs-water-source-notok.svg");
                        }
                        else {
                            symbol.url = require("./icons/icon-gs-water-source-ok.svg");
                        }
                        break;
                    case "Laguna":
                        if (limit) {
                            symbol.url = require("./icons/icon-gs-water-source-notok.svg");
                        }
                        else {
                            symbol.url = require("./icons/icon-gs-water-source-ok.svg");
                        }
                }

                let pointGraphic = new Graphic({
                    attributes: attributes,
                    geometry: point,
                    symbol: symbol,
                    popupTemplate: {
                        // autocasts as new PopupTemplate()
                        title: "Fuente de Agua",
                        content: [
                            {
                                type: "fields",
                                fieldInfos: [
                                    {
                                        fieldName: "name",
                                        label: "Nombre"
                                    },
                                    {
                                        fieldName: "arsenico",
                                        label: "Arsénico"
                                    },
                                    {
                                        fieldName: "ph",
                                        label: "pH"
                                    },
                                    {
                                        fieldName: "cloro",
                                        label: "Cloro"
                                    },
                                    {
                                        fieldName: "turbiedad",
                                        label: "Turbiedad"
                                    },
                                    {
                                        fieldName: "conductividad_muestra",
                                        label: "Conductividad"
                                    },
                                    {
                                        fieldName: "fecha_recoleccion",
                                        label: "Fecha de recolección"
                                    },
                                    {
                                        fieldName: "tipo_fuente",
                                        label: "Tipo de fuente"
                                    }
                                ]
                            }
                        ]
                    }
                });

                this.markers.push(pointGraphic);

                this.graphicsLayer.add(this.markers[i]);
            }

            this.filteredMarkers = this.markers;
        });
    }

    valuetext(value) {
      return `${value}°C`;
    }

    handleChange = (event) => {

        switch (event.target.name) {
            case "proyectos_ext":
                this.ProyectosMinerosExtLayer.visible = event.target.checked;
                break;
            case "proyectos_exp":
                this.ProyectosMinerosExpLayer.visible = event.target.checked;
                break;
            case "cuerpos":
                for (let i = 0; i < this.CuerposAguaLayer.length; i++) {
                    this.CuerposAguaLayer[i].visible = event.target.checked;
                }
                break;
            case "cuencas":
                this.CuencasLayer.visible = event.target.checked;
                break;
            case "comunales":
                this.TierrasComunalesLayer.visible = event.target.checked;
                break;
            case "lugares":
                this.LugaresPobladosLayer.visible = event.target.checked;
                break;
            case "petroleras":
                this.LicenciasPetrolerasLayer.visible = event.target.checked;
                break;
            case "seth":
                this.SETHLayer.visible = event.target.checked;
        }

        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    handleRadioChange = (event) => {

        event.persist();

        let whatToFilter = "";
        let marks = [];
        let maxSlider = 0;
        let valueSlider = [];
        let titleSlider = "";
        let legendSlider = "";

        this.map.remove(this.graphicsLayer);

        let eventTargetValue = event.target.value;

        this.createMarkers(eventTargetValue, this.state.filteredDataset);

        switch (event.target.value) {
            case "arsenico":
                whatToFilter = "arsenico";
                titleSlider = "Rangos arsénico";
                legendSlider = "Unidades en ppm, LMP: 10";
                marks = [
                    {
                        value: 0,
                        label: '0',
                    },
                    {
                        value: 10,
                        label: '10',
                    },
                    {
                        value: 100,
                        label: '100',
                    },
                ];
                maxSlider = 100;
                valueSlider = [0, 100];
                break;
            case "ph":
                whatToFilter = "ph";
                titleSlider = "Rangos pH";
                legendSlider = "Unidades en ppm, LMA: 7-7.5, LMP: 6.5-8.5";
                marks = [
                    {
                        value: 0,
                        label: '0',
                    },
                    {
                        value: 7,
                        label: '7',
                    },
                    {
                        value: 14,
                        label: '14',
                    },
                ];
                maxSlider = 14;
                valueSlider = [0, 14];
                break;
            case "cloro":
                whatToFilter = "cloro";
                titleSlider = "Rangos cloro";
                legendSlider = "Unidades en mg/L, LMA: 0.5, LMP: 1.0";
                marks = [
                    {
                        value: 0,
                        scaledValue: 0,
                        label: '0',
                    },
                    {
                        value: 100,
                        scaledValue: 1,
                        label: '1',
                    },
                ];
                maxSlider = 100;
                valueSlider = [0, 100];
                break;
            case "turbiedad":
                whatToFilter = "turbiedad";
                titleSlider = "Rangos turbiedad";
                legendSlider = "Unidades nefeométricas de turbiedad, LMA: 5, LMP: 15";
                marks = [
                    {
                        value: 0,
                        label: '0',
                    },
                    {
                        value: 15,
                        label: '15',
                    },
                    {
                        value: 20,
                        label: '20',
                    },
                ];
                maxSlider = 20;
                valueSlider = [0, 20];
                break;
            case "conductividad_muestra":
                whatToFilter = "conductividad_muestra";
                titleSlider = "Rangos conductividad";
                legendSlider = "Unidades en μS/cm, LMA: 100-750";
                marks = [
                    {
                        value: 0,
                        label: '0',
                    },
                    {
                        value: 750,
                        label: '750',
                    },
                    {
                        value: 5000,
                        label: '5000',
                    },
                ];
                maxSlider = 5000;
                valueSlider = [0, 5000];
        }

        this.setState({
            whatToFilter: whatToFilter,
            titleSlider: titleSlider,
            legendSlider: legendSlider,
            valueSlider: valueSlider,
            marks: marks,
            maxSlider: maxSlider,
            calidadAguaValue: event.target.value
        });
    };

    scale = (value) => {
        switch (this.state.whatToFilter) {
            case "arsenico":
                return value;
                break;
            case "ph":
                return value;
                break;
            case "cloro":
                return value / 100;
                break;
            case "turbiedad":
                return value;
                break;
            case "conductividad_muestra":
                return value;
        }
    };

    handleChangeSlider = (event, newValue) => {
        this.setState({
            newValue: newValue,
            valueSlider: newValue
        });
    }

    handleDragStopSlider = () => {
        for (let i = 0; i < this.markers.length; i++) {
            this.markers[i].visible = false;
        }

        this.filterByValue(this.markers);

        let currentUID = this.markers[0].uid;

        for (let i = 0; i < this.filteredMarkers.length; i++) {
            this.markers[this.filteredMarkers[i].uid - currentUID].visible = true;
        }
    }

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
        if (this.view) {
            // destroy the map view
            this.view.container = null;
        }
    }

    render() {
        let myWidth = 0;
        let myHeight = 0;
        let legendWidth = 0;

        if (isMobile) {
            myWidth = (window.innerWidth - 40);
            myHeight = (window.innerWidth - 40);
            legendWidth = window.innerWidth - 40;
        }
        else {
            myWidth = window.innerWidth - 80;
            myHeight = (window.innerWidth - 100) / 2;
            legendWidth = (window.innerWidth - 100) / 2;
        }

        return (
            <div style={{position: 'relative'}}>
                <div className={styles.mapFiltersWrapper} ref={this.filtersWrapper}>
                    <h1 className="title orange">Explora</h1>
                    <div className={styles.mapFilters}>
                        <Typography variant="legend" gutterBottom>
                          Número de muestreo
                        </Typography>
                        <Select
                            className="map-filter"
                            styles={customStyles}
                            options={this.state.selectMuestreos}
                            placeholder={"Selecciona una opción"}
                            defaultValue={{ label: "9", value: "9" }}
                            maxMenuHeight={200}
                            isClearable={true}
                            onChange={e => this.changeSelect(e, "muestreo")}
                        />
                        <Typography variant="legend" gutterBottom>
                          Fuentes de agua
                        </Typography>
                        <Select
                            className="map-filter"
                            styles={customStyles}
                            options={this.state.selectFuentes}
                            placeholder={"Selecciona una o más opciones"}
                            maxMenuHeight={200}
                            isClearable={true}
                            isMulti
                            onChange={e => this.changeSelect(e, "tipo_fuente")}
                        />
                        <FormControl component="fieldset">
                          <Typography className={styles.sidebarHeadline} gutterBottom>
                              Calidad de Agua
                              <TooltipModal title="Glosario unidades" text={tooltips.calidad_de_agua} className={styles.tooltipButton}  />
                          </Typography>
                          <RadioGroup aria-label="calidadagua" name="calidadagua" value={this.state.calidadAguaValue} onChange={this.handleRadioChange}>
                            {/*<FormControlLabel value="todos" control={<Radio />} label="Todos" />*/}
                            <FormControlLabel value="arsenico" control={<Radio />} label="Arsénico (ppb)" />
                            <FormControlLabel value="ph" control={<Radio />} label="pH" />
                            <FormControlLabel value="cloro" control={<Radio />} label="Cloro (ppm)" />
                            <FormControlLabel value="turbiedad" control={<Radio />} label="Turbiedad (NTU)" />
                            <FormControlLabel value="conductividad_muestra" control={<Radio />} label="Conductividad eléctrica (&micro;S/cm)" />
                          </RadioGroup>
                        </FormControl>
                        <div>
                          <Typography id="range-slider" className={styles.sidebarHeadline} gutterBottom component="legend">
                            {this.state.titleSlider}<TooltipModal title="Norma COGUANOR" text="Estándares de calidad del agua en Guatemala" image={coguanor} imagecaption={<small>Norma Técnica Guatemalteca: Coguanor Ntg 29001. Comisión Guatemalteca De Normas Ministerio De Economía</small>} className={styles.tooltipButton}  />
                          </Typography>
                          <Slider
                              ref={this.sliderRef}
                              scale={this.scale}
                            aria-labelledby="range-slider"
                            getAriaValueText={this.valuetext}
                            value={this.state.valueSlider}
                            defaultValue={this.state.defaultValueSlider}
                            max={this.state.maxSlider}
                            marks={this.state.marks}
                            valueLabelDisplay="auto"
                            onChange={this.handleChangeSlider}
                            onChangeCommitted={this.handleDragStopSlider}
                          />
                        </div>
                        <Typography variant="caption" gutterBottom>
                          {this.state.legendSlider}
                        </Typography>
                        <FormControl component="fieldset">
                          <Typography className={styles.sidebarHeadline} gutterBottom>
                              Capas<TooltipModal title="Fuentes capas" text={<span>Tierras comunales (Grupo promotor de Tierras comunales, 2009)<br/> Cuencas (Ministerio de Agricultura, Ganadería y Alimentación, 2009)<br/> Ríos (IARNA/URL, 2013)<br/> Licencias Mineras (Ministerio de Energía y Minas, 2020)<br/> Licencias Petroleras (Ministerio de Energía y Minas, 2020)<br/> Lugares Poblados (Instituto Nacional de Estadística, 2002)</span>} className={styles.tooltipButton}  />
                          </Typography>
                          <FormGroup>
                            <FormControlLabel
                              control={<Switch checked={this.state.comunales} onChange={this.handleChange} name="comunales" />}
                              label="Tierras comunales"
                            />
                            <FormControlLabel
                              control={<Switch checked={this.state.cuencas} onChange={this.handleChange} name="cuencas" />}
                              label="Cuencas"
                            />
                            <FormControlLabel
                              control={<Switch checked={this.state.cuerpos} onChange={this.handleChange} name="cuerpos" />}
                              label="Cuerpos de agua"
                            />
                            <FormControlLabel
                              control={<Switch checked={this.state.proyectos_ext} onChange={this.handleChange} name="proyectos_ext" />}
                              label="Licencias mineras"
                            />
                            <FormControlLabel
                              control={<Switch checked={this.state.proyectos_exp} onChange={this.handleChange} name="proyectos_exp" />}
                              label="Solicitudes de licencias mineras"
                            />
                            <FormControlLabel
                              control={<Switch checked={this.state.petroleras} onChange={this.handleChange} name="petroleras" />}
                              label="Contratos petroleros"
                            />
                            <FormControlLabel
                              control={<Switch checked={this.state.seth} onChange={this.handleChange} name="seth" />}
                              label="SETH"
                            />
                            <FormControlLabel
                              control={<Switch checked={this.state.lugares} onChange={this.handleChange} name="lugares" />}
                              label="Lugares poblados"
                            />
                          </FormGroup>
                          <FormHelperText>Selecciona las capas que deseas ver</FormHelperText>
                        </FormControl>
                    </div>
                </div>
                <div className={styles.webmap} ref={this.mapRef} />
            </div>
        );
    }
}

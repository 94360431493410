import React from 'react';
import './CommunityMonitoring.css';
import { loadModules } from 'esri-loader';
import axios from 'axios';
import Select from "react-select";
import CommunityMonitoringGraph from "./CommunityMonitoringGraph";

import tooltips from './assets/tooltips';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? '#FFF' : '#FFF',
        backgroundColor: '#4D4DFF'
    }),
    control: styles => ({
        ...styles,
        color: '#000',
        backgroundColor: '#FFF',
        width: '100%'
    }),
    menu: styles => ({
        ...styles,
        color: '#FFF',
        backgroundColor: '#4D4DFF',
        border: '1px solid white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#000'
    })
}

export default class CommunityMonitoring extends React.Component {
    state = {
        dataset: [],
        phData: [],
        firstRun: true,
        cuenca: ""
    }
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
    }

    async getData() {
        let [cuencas, sitios] = await Promise.all([
            axios.get(`https://collector.oiegt.org/api/lista-cuenca-sitio`),
            axios.get(`https://collector.oiegt.org/api/lista-sitio-muestreo`)
        ]);

        const selectCuencas = cuencas.data.map(function(obj) {
            const options = {};

            options.value = obj.name;
            options.label = obj.name;

            return options;
        });

        this.createMap();

        this.setState({
            cuencas: cuencas,
            sitios: sitios,
            selectCuencas: selectCuencas
        });
    }

    getSitios(cuenca, cuencas, sitios) {
        let singleCuenca = cuencas.data.filter(function(e) {
            return e.name === cuenca;
        });

        let sitiosFinal = singleCuenca[0].cuenca_sitios.filter(function(e) {
            for (let i = 0; i < sitios.data.length; i++) {
                if (sitios.data[i].id_sitio === e.id_sitio) {
                    e.latitude = sitios.data[i].latitude;
                    e.longitude = sitios.data[i].longitude;
                    e.tipo_fuente = sitios.data[i].tipo_fuente;
                    return e;
                }
            }
        });

        let sitiosFinales = sitios.data.map(function(eleSitio, index) {
            for (let j = 0; j < cuencas.data.length; j++) {
                let final = cuencas.data[j].cuenca_sitios.find(function(eleCuenca) {
                    return eleSitio.id_sitio === eleCuenca.nombre;
                });
            }
        });

        // if (this.state.firstRun) {
        //     this.createMap(sitiosFinal);
        // }
        // else {
        //     this.createMarkers(sitiosFinal);
        // }

        this.createMarkers(sitiosFinal);
    };

    createMap() {
        // lazy load the required ArcGIS API for JavaScript modules and CSS
        loadModules([
            "esri/Map",
            "esri/views/ui/DefaultUI",
            "esri/views/MapView",
            "esri/layers/FeatureLayer",
            "esri/symbols/PictureMarkerSymbol",
            "esri/Graphic",
            "esri/layers/GraphicsLayer",
            "esri/config",
            "esri/geometry/Extent"
        ], { css: true })
        .then(([
            ArcGISMap,
            DefaultUI,
            MapView,
            FeatureLayer,
            PictureMarkerSymbol,
            Graphic,
            GraphicsLayer,
            esriConfig,
            Extent
        ]) => {

            esriConfig.request.corsDetection = false;

            this.map = new ArcGISMap({
                basemap: 'topo-vector'
            });

            this.view = new MapView({
                container: this.mapRef.current,
                map: this.map,
                center: [-90.410456,15.690665],
                zoom: 8,
                navigation: {
                    mouseWheelZoomEnabled: false,
                    browserTouchPanEnabled: false
                }
            });

            this.view.ui.move("zoom", "bottom-right");

            // this.view.when(() => {
            //     this.createMarkers(sitios);
            // });
        });
    }

    async changeSelect(e) {
        this.map.remove(this.graphicsLayer);

        this.getSitios(e.value, this.state.cuencas, this.state.sitios);

        // this.getWaterData(e.value);

        this.setState({
            firstRun: false,
            cuenca: e.value
        });
    }

    async getWaterData(cuenca) {
        // let [ph] = await Promise.all([
        //     axios.get(`https://collector.oiegt.org/api/agua-ph?cuenca=${cuenca}`)
        // ]);
    }

    maxExtent(graphics) {
        const e = graphics
        .map(g => g.geometry)
        .reduce(
            (acc, geom) => ({
                xmin: Math.min(acc.xmin, geom.longitude),
                ymin: Math.min(acc.ymin, geom.latitude),
                xmax: Math.max(acc.xmax, geom.longitude),
                ymax: Math.max(acc.ymax, geom.latitude)
            }),
            {
                xmin: Number.MAX_SAFE_INTEGER,
                ymin: Number.MAX_SAFE_INTEGER,
                xmax: Number.MIN_SAFE_INTEGER,
                ymax: Number.MIN_SAFE_INTEGER
            }
        );
        return e;
    }

    createMarkers(data) {
        loadModules([
            "esri/Map",
            "esri/views/ui/DefaultUI",
            "esri/views/MapView",
            "esri/layers/FeatureLayer",
            "esri/symbols/PictureMarkerSymbol",
            "esri/Graphic",
            "esri/layers/GraphicsLayer",
            "esri/config",
            "esri/geometry/Extent",
            "dojo/domReady!"
        ], { css: true })
        .then(([
            ArcGISMap,
            DefaultUI,
            MapView,
            FeatureLayer,
            PictureMarkerSymbol,
            Graphic,
            GraphicsLayer,
            esriConfig,
            Extent
        ]) => {

            this.graphicsLayer = new GraphicsLayer();

            this.map.add(this.graphicsLayer);

            this.markers = [];

            var symbol = {
                type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
                width: "30px",
                height: "30px"
            };

            for (let i = 0; i < data.length; i++) {
                let point = {
                    type: "point",
                    longitude: data[i].longitude,
                    latitude: data[i].latitude,
                }

                let id_sitio = data[i].id_sitio;
                let nombre = data[i].nombre;
                let tipo_fuente = data[i].tipo_fuente;

                let attributes = {
                    "id_sitio": id_sitio,
                    "nombre": nombre,
                    "tipo_fuente": tipo_fuente
                }

                switch (tipo_fuente) {
                    case "R":
                        symbol.url = require("./assets/icons/icon-cm-rio.svg");
                        break;
                    case "N":
                        symbol.url = require("./assets/icons/icon-cm-nacimiento.svg");
                        break;
                    case "T":
                        symbol.url = require("./assets/icons/icon-cm-tanque.svg");
                        break;
                    case "P":
                        symbol.url = require("./assets/icons/icon-cm-pozo.svg");
                        break;
                    case "H":
                        symbol.url = require("./assets/icons/icon-cm-hogar.svg");
                        break;
                    case "M":
                        symbol.url = require("./assets/icons/icon-cm-mina.svg");
                        break;
                    case "LN":
                        symbol.url = require("./assets/icons/icon-cm-laguna.svg");
                        break;
                    case "LG":
                        symbol.url = require("./assets/icons/icon-cm-lago.svg");
                        break;
                }

                let pointGraphic = new Graphic({
                    attributes: attributes,
                    geometry: point,
                    symbol: symbol,
                    popupTemplate: {
                        // autocasts as new PopupTemplate()
                        title: "Punto de muestreo",
                        content: [
                            {
                                type: "fields",
                                fieldInfos: [
                                    {
                                        fieldName: "id_sitio",
                                        label: "ID sitio"
                                    },
                                    {
                                        fieldName: "nombre",
                                        label: "Nombre"
                                    },
                                    {
                                        fieldName: "tipo_fuente",
                                        label: "Tipo de fuente"
                                    }
                                ]
                            }
                        ]
                    }
                });

                this.markers.push(pointGraphic);

                this.graphicsLayer.add(this.markers[i]);
            }

            this.filteredMarkers = this.markers;

            const ext = new Extent(this.maxExtent(this.markers));
            console.log(`View Extent: ${JSON.stringify(this.view.extent)} Graphics Extent:${JSON.stringify(ext)}`);
            this.view.extent = ext.expand(1.8);
        });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div id="cm">
                <div className="banner-wrapper">
                    <div className="banner-wrapper-content">
                        <div className="banner-info">
                            <h1 className="black title">Datos periódicos de calidad de agua  producidos por científicos comunitarios para cerrar la brecha de información existente.</h1>

                            <div className="cmDisclaimerWrapper">

                                <p>
                                    El Monitoreo comunitario nace a partir de la colaboración entre CODIDENA y el OIE, con la intención de tener la tecnología necesaria accesible para las y los jóvenes científicos comunitarios  que buscan generar procesos de investigación para respaldar la defensa de sus comunidades y territorios frente a proyectos extractivos.
                                </p>

                                <p>
                                    En ese mismo sentido el enfoque comunitario busca crear un diálogo de saberes, es decir, que las experiencias de quienes habitan el territorio, el conocimiento cultural acumulado por generaciones y el conocimiento técnico científico se entrelazan para poder responder a la complejidad del problema socioambiental que representa el extractivismo y la vulneración de una serie de derechos encadenados.
                                </p>

                            </div>

                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="wrapper-symmetrical twoColumns">
                    <div>
                        <p>Explora datos de calidad de agua producidos de manera períodica por los cientiﬁcos comunitarios</p>
                    </div>
                    <div>
                        <Select
                            className="map-filter"
                            styles={customStyles}
                            options={this.state.selectCuencas}
                            placeholder={"Selecciona una cuenca"}
                            maxMenuHeight={200}
                            onChange={e => this.changeSelect(e)}
                        />
                    </div>
                </div>
                <div className="cmWebmapWrapper">
                    <h1 className="section-title">Sitios de muestreo comunitario</h1>
                    <div className="cmWebmapWrapperContent">
                        <div className="cmLegends">
                            <p><strong>Tipo de fuente leyenda</strong></p>
                            <p>
                                <img src={require('./assets/icons/icon-cm-rio.svg')} /> <strong>'R'</strong>, 'Rio o Quebrada'<br/>
                                <img src={require('./assets/icons/icon-cm-nacimiento.svg')} /> <strong>'N'</strong>, 'Nacimiento'<br/>
                                <img src={require('./assets/icons/icon-cm-tanque.svg')} /> <strong>'T'</strong>, 'Tanque de Distribución/Captación'<br/>
                                <img src={require('./assets/icons/icon-cm-pozo.svg')} /> <strong>'P'</strong>, 'Pozo'<br/>
                                <img src={require('./assets/icons/icon-cm-hogar.svg')} /> <strong>'H'</strong>, 'Hogar'<br/>
                                <img src={require('./assets/icons/icon-cm-mina.svg')} /> <strong>'M'</strong>, 'Desagüe de Mina'<br/>
                                <img src={require('./assets/icons/icon-cm-laguna.svg')} /> <strong>'LN'</strong>, 'Laguna'<br/>
                                <img src={require('./assets/icons/icon-cm-lago.svg')} /> <strong>'LG'</strong>, 'Lago'
                            </p>
                        </div>
                        <div>
                            <div className="cmWebmap" ref={this.mapRef} />
                        </div>
                    </div>
                </div>
                <Graphs cuenca={this.state.cuenca}></Graphs>
            </div>
        )
    }
}

function Graphs(props) {
    return (
        <div>
            <div className="section darkblue-bg">
                <div className="wrapper">
                    <CommunityMonitoringGraph cuenca={props.cuenca} parametro={"ph"} unidad={""} tooltip={tooltips.monitoreo_ph} limit={7.5} />
                </div>
            </div>
            <div className="section darkblue-bg">
                <div className="wrapper">
                    <CommunityMonitoringGraph cuenca={props.cuenca} parametro={"conductividad"} unidad={"µS/cm"} tooltip={tooltips.monitoreo_conductividad} limit={1500} />
                </div>
            </div>
            <div className="section darkblue-bg">
                <div className="wrapper">
                    <CommunityMonitoringGraph cuenca={props.cuenca} parametro={"arsenico"} unidad={"mg/l"} tooltip={tooltips.monitoreo_arsenico} limit={0.01} />
                </div>
            </div>
            <div className="section darkblue-bg">
                <div className="wrapper">
                    <CommunityMonitoringGraph cuenca={props.cuenca} parametro={"turbiedad"} unidad={"UNT"} limit={15} />
                </div>
            </div>
            <div className="section darkblue-bg">
                <div className="wrapper">
                    <CommunityMonitoringGraph cuenca={props.cuenca} parametro={"oxigeno_disuelto_total"} unidad={"mg/l"} limit={15} />
                </div>
            </div>
            <div className="section darkblue-bg">
                <div className="wrapper">
                    <CommunityMonitoringGraph cuenca={props.cuenca} parametro={"porcentaje_oxigeno_disuelto"} unidad={"mg/l"} limit={15} />
                </div>
            </div>
            <div className="section darkblue-bg">
                <div className="wrapper">
                    <CommunityMonitoringGraph cuenca={props.cuenca} parametro={"solidos"} unidad={"mg/l"} tooltip={tooltips.monitoreo_solidos} limit={1000} />
                </div>
            </div>
            <div className="section darkblue-bg">
                <div className="wrapper">
                    <CommunityMonitoringGraph cuenca={props.cuenca} parametro={"temperatura"} unidad={"Cº"} limit={34} />
                </div>
            </div>
        </div>
    )
}

import React from 'react';
import Accordion from './Accordion';
import './AboutUs.css';

export default class AboutUs extends React.Component {
    render() {
        return (
            <div className="aboutUs">
                <div className="section">
                    <div className="banner-wrapper aboutus-banner">
                        <div className="banner-wrapper-content aboutus-banner-content">
                            <span className="subtitle">Acerca del OIE</span>
                            <h1 className="title">El Observatorio de Industrias Extractivas (OIE) es una plataforma de investigación independiente construida para adquirir, organizar y visualizar datos sobre proyectos extractivos (mineros y petroleros) en Guatemala.</h1>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="accordions-wrapper">
                        <Accordion
                            title="OIE"
                            content=
                            "<p>El Observatorio de Industrias Extractivas (OIE) es una plataforma de investigación independiente construida para adquirir, organizar y visualizar datos sobre proyectos extractivos (mineros y petroleros) en Guatemala.</p>

                            <p>Investigamos, interpretamos y damos seguimiento a:</p>

                            <p><i>Los principales proyectos extractivos en Guatemala </i></p>
                            <p><i>El papel que juegan los gobiernos nacionales y locales en la ejecución y regulación de estos proyectos.</i></p>
                            <p><i>Los operadores de los proyectos a nivel nacional.</i></p>
                            <p><i>Los inversionistas, corporaciones y empresas que operan estos proyectos a nivel internacional.</i></p>
                            <p><i>Las exportaciones y sus destinos.</i></p>
                            <p><i>Pagos de regalías, cánones y pagos fiscales.</i></p>
                            <p><i>Datos recolectados a nivel comunitario sobre el recurso hídrico.</i></p>


                            <p>El análisis y la socialización de estos datos juegan un papel clave en la comprensión de los impactos socioambientales que las Industrias Extractivas (IE) tienen en los territorios en los que operan. Nuestro objetivo es hacer que esta información sea de fácil acceso y comprensión, utilizando diferentes sistemas de conocimiento en nuestra comprensión de los impactos y efectos del extractivismo. Nuestro proceso de investigación prioriza el compromiso ético y social, la transparencia, la sensibilidad y solidaridad con los problemas sociales, el reconocimiento de los derechos de los pueblos indígenas, la apertura al diálogo, la democracia y participación ciudadana, y el respeto a los derechos humanos.</p>

                            <p>El OIE recolecta datos gubernamentales por medio de la Ley de Acceso a la Información Pública, mientras que los datos comunitarios independientes son reunidos a través de diferentes métodos de investigación, como el análisis de la calidad del agua, la documentación de los impactos, la observación, el diálogo y los estudios de caso en profundidad.</p>

                            <p>La evidente falta de interés de las instituciones públicas por difundir estos datos y hacer partícipe a la población, provoca la vulneración de un derecho tan importante como el acceso a la información, y por tanto dificulta el debate informado sobre el extractivismo en Guatemala. Es a partir de este contexto y de la necesidad de contar con datos accesibles para las poblaciones afectadas que buscamos contribuir a una narrativa que parta desde los datos y concluya en una perspectiva independiente pero comprometida con la justicia social/climática y los derechos humanos. Somos el primer Observatorio específico de Industrias Extractivas en Guatemala y estamos abriendo camino al identificar y ordenar la información sobre el tema para apoyar las demandas de las comunidades frente al Estado y las empresas extractivas. Nos enfocamos en las fallas del sistema, las leyes y las políticas para poder mejorarlas.</p>

                            <p>Actualmente, el OIE conduce estudios sobre proyectos extractivos en toda Guatemala y está iniciando investigaciones a lo largo de la región centroamericana.</p>"
                        />
                        <Accordion
                            title="Bases de datos"
                            content="
                            <p>La base de datos del OIE busca construir un repositorio centralizado de la información disponible en los documentos oficiales del Estado. Esta se alimenta por medio de formularios en línea administrados por actores comunitarios interesados o por miembros del equipo OIE.</p>

                            <p>Se construyó con una estructura relacional clásica centrada en los proyectos extractivos. Estos proyectos son el punto nodal de encuentro con las distintas tablas utilizadas para poblar la plataforma. Estas incluyen información filtrada sobre las corporaciones transnacionales detrás de los proyectos, las empresas subsidiarias nacionales, los representantes legales, las instituciones rectoras, las empresas consultoras y también están conectadas a lo territorial por municipio, departamento y cuenca.</p>

                            <p>Dada la naturaleza de observación externa, el equipo OIE sostiene que los datos utilizados para la construcción de los módulos que se presentan en la plataforma deben ser abiertos y auditables por otros observadores externos. Por tanto, con el afán de mantener la transparencia del observatorio a continuación se listan las tablas de análisis utilizadas en formato descargable “CSV” para que puedan ser utilizadas para realizar estudios adicionales o para verificar la veracidad de las gráficas y aseveraciones presentadas en este sitio.</p>

                            <p><strong>Proyecto Extractivo</strong></p>
                            <p>Información sobre todos los proyectos extractivos, esta tabla central conecta todas las otras tablas del observatorio<p>
                            <p><a href='https://collector.oiegt.org/api/proyecto-extractivo?format=csv' target='_blank'>Descarga</a></p>

                            <p><strong>Cuencas</strong></p>
                            <p>Polígonos y áreas de cuencas de Guatemala<p>
                            <p><a href='https://collector.oiegt.org/api/cuencasformat=csv' target='_blank'>Descarga</a></p>

                            <p><strong>Regalías y Cánones</strong></p>
                            <p>Datos de Regalías y Cánones catalogadas por tipo y por ente al que se le otorgaron.<p>
                            <p><a href='https://collector.oiegt.org/api/proyecto-extractivo?format=csv' target='_blank'>Descarga</a></p>

                            <p><strong>Exportaciones</strong></p>
                            <p>Agregado de las exportaciones de los últimos 2 años con su destino y proyecto extractivo.<p>
                            <p><a href='https://collector.oiegt.org/api/exportaciones?format=csv' target='_blank'>Descarga</a></p>

                            <p><strong>Extracciones</strong></p>
                            <p>Minerales extraídos por proyecto extractivo en toneladas métricas y costo de extracción en Quetzales<p>
                            <p><a href='https://collector.oiegt.org/api/extracciones?format=csv' target='_blank'>Descarga</a></p>

                            <p>Nota: Al descargar los archivos es necesario colocarles nombre y la extensión “.csv” por ejemplo: Si se quisiera descargar el documento de proyecto extractivo podría colocársele el nombre “proyecto extractivo.csv” para descargarlo correctamente. Si no se coloca la extensión es posible que programas como Excel no lo reconozcan correctamente y no se pueda abrir.</p>

                            <p><strong>API para descargas especializadas</strong></p>

                            <p>El observatorio de industrias extractivas tiene un API Rest que se utiliza para alimentar la plataforma. Como parte de los esfuerzos de transparencia, el API es de acceso público y puede ser utilizado para descargar información de proyectos extractivos específicos para generar visualizaciones especializadas y atomizar las búsquedas.</p>

                            <p>El API cuenta con 2 modos de uso, un modo integrado para utilizarse en aplicaciones WEB donde la información se descarga en formato JSON y un modo de descarga CSV directa. </p>

                            <p><strong>Estructura</strong></p>

                            <p>El API utiliza una estructura de búsqueda con base en el ID oficial del proyecto extractivo en el ministerio de energía y minas. El enlace utiliza un protocolo GET para obtener la información de la base de datos del OIE.</p>


                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    https://
                                </td>
                                <td>
                                    collector.oiegt.org/
                                </td>
                                <td>
                                    api/
                                </td>
                                <td>
                                    específicos/
                                </td>
                                <td>
                                    &lt; Str : pk &gt; /
                                </td>
                                <td>
                                    ?format=csv
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    SSL
                                </td>
                                <td>
                                    URL del observatorio
                                </td>
                                <td>
                                    EndpointAPI Rest
                                </td>
                                <td>
                                    Punto de enlace
                                </td>
                                <td>
                                    ID proyecto minero
                                </td>
                                <td>
                                    Modificadores
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <p><i>Ejemplo:</i></p>

                    <p>Para obtener la información general del proyecto extractivo en formato JSON “El Escobal” se utilizaría el siguiente enlace:</p>

                    <p><a href='https://collector.oiegt.org/api/proyecto-extractivo/LEXT-015-11'>https://collector.oiegt.org/api/proyecto-extractivo/LEXT-015-11</a></p>

                    <p>Para obtenerla en formato CSV se utilizaría el siguiente enlace:</p>

                    <p><a href='https://collector.oiegt.org/api/proyecto-extractivo/LEXT-015-11?format=csv'>https://collector.oiegt.org/api/proyecto-extractivo/LEXT-015-11?format=csv</a></p>

                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Nombre del Punto de Enlace</strong>
                                </th>
                                <th>
                                    <strong>Descripción General</strong>
                                </th>
                                <th>
                                    <strong>Enlace</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Proyecto extractivo</td>
                                <td>Devuelve un JSON con la información general del proyecto extractivo solicitado.</td>
                                <td>https://collector.oiegt.org/api/proyecto-extractivo/ &lt; str:pk &gt; </td>
                            </tr>
                            <tr>
                                <td>Listado de proyectos extractivos</td>
                                <td>Devuelve un JSON-Array con la información general de todos los proyectos extractivos.</td>
                                <td>https://collector.oiegt.org/api/proyecto-extractivo/</td>
                            </tr>
                            <tr>
                                <td>Línea del tiempo</td>
                                <td>DLista eventos relacionados al proyecto extractivo con fecha.</td>
                                <td>https://collector.oiegt.org/api/linea-tiempo/ &lt; str:pk &gt; </td>
                            </tr>
                            <tr>
                                <td>Línea del tiempo de la industria extractiva</td>
                                <td>Devuelve un JSON array de los eventos de la línea del tiempo general para la industria extractiva.</td>
                                <td>https://collector.oiegt.org/api/linea-tiempo-industria/</td>
                            </tr>
                            <tr>
                                <td>Regalías-Cánones</td>
                                <td>Permite obtener los campos de información con fecha y monto de regalías y cánones categorizadas con detalle por proyecto extractivo.</td>
                                <td>https://collector.oiegt.org/api/regalias-canones/ &lt; str:pk &gt; </td>
                            </tr>
                            <tr>
                                <td>Empleados de la industria extractiva en general (IGSS)</td>
                                <td>Devuelve un listado de empelados por mes y por empresa nacional asociada.</td>
                                <td>https://collector.oiegt.org/api/empleados-industria/</td>
                            </tr>
                            <tr>
                                <td>Empleados</td>
                                <td>Devuelve la cantidad de empleados registrada en el MEM, MARN y en el IGSS por empresa nacional/proyecto extractivo.</td>
                                <td>https://collector.oiegt.org/api/empleados/ &lt; str:pk &gt; </td>
                            </tr>
                            <tr>
                                <td>Empleados por año</td>
                                <td>Devuelve la cantidad de empleados registrada en el IGSS por empresa nacional/proyecto extractivo y por año.</td>
                                <td>https://collector.oiegt.org/api/empleados/ &lt; str:pk &gt; / &lt; str:año &gt; </td>
                            </tr>
                            <tr>
                                <td>Exportaciones generales</td>
                                <td>Devuelve un JSON array de los productos mineros y destinos con información georreferenciada.</td>
                                <td>https://collector.oiegt.org/api/exportaciones/</td>
                            </tr>
                            <tr>
                                <td>Exportaciones</td>
                                <td>Devuelve un JSON con la información general del proyecto extractivo solicitado.</td>
                                <td>https://collector.oiegt.org/api/exportaciones/ &lt; str:pk &gt; </td>
                            </tr>
                            <tr>
                                <td>Extracciones generales</td>
                                <td>Devuelve un JSON array de los productos mineros y destinos con información georreferenciada.</td>
                                <td>https://collector.oiegt.org/api/extracciones/</td>
                            </tr>
                            <tr>
                                <td>Extracciones</td>
                                <td>Devuelve un JSON array de los productos mineros y el costo en quetzales para extraerlos por año por proyecto extractivo.</td>
                                <td>https://collector.oiegt.org/api/extracciones/ &lt; str:pk &gt; </td>
                            </tr>
                            <tr>
                                <td>Otorgamiento</td>
                                <td>Realiza una comparación por días de los tiempos oficiales para otorgamiento de licencias mineras y los tiempos que se tomó un proyecto extractivo en obtenerla.</td>
                                <td>https://collector.oiegt.org/api/otorgamiento/ &lt; str:pk &gt; </td>
                            </tr>
                            <tr>
                                <td>Sitios de muestreo comunitario de agua</td>
                                <td>Despliega un listado de puntos de muestreo de calidad de agua correspondientes a los sitios de muestreo.</td>
                                <td>https://collector.oiegt.org/api/mapa-agua/</td>
                            </tr>
                            <tr>
                                <td>Sitios de muestreo comuinitario, resultados E.Coli</td>
                                <td>Despliega un listado de puntos de muestreo de calidad de agua  (E.Coli) correspondientes a los sitios de muestreo.</td>
                                <td>https://collector.oiegt.org/api/agua-ecoli/</td>
                            </tr>
                            <tr>
                                <td>SEC</td>
                                <td>
                                    Despliega información de 3 formularios de la security Exchange comisión:
                                    <ul>
                                        <li>API SEC 13F</li>
                                        <li>API SEC 6K</li>
                                        <li>API SEC 20K - 40K</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>https://collector.oiegt.org/api/sec-13F/</li>
                                        <li>https://collector.oiegt.org/api/sec-6K/</li>
                                        <li>https://collector.oiegt.org/api/sec-20F-40F/</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Puertas giratorias</td>
                                <td>Permite obtener los campos de información para cada uno de los pares de nodos del formulario de puertas giratorias.</td>
                                <td>https://collector.oiegt.org/api/puertas-giratorias/</td>
                            </tr>
                        </tbody>
                    </table>



                        "
                        />
                        <Accordion
                            title="Solicitud de acceso a la información pública "
                            content="
                            <p>El OIE utiliza como herramienta la Ley de Acceso a la Información Pública <a href='http://portalgl.minfin.gob.gt/Descargas/Documents/LEY-DE-ACCESO-A-LA-INFORMACION-PUBLICA-DECRETO-57-2008.pdf' target='_blank'>Decreto No.57-2008</a>. La mayoría de los datos se obtuvieron por medio de solicitudes realizadas hacia las principales instituciones del Estado vinculadas a la industria extractiva. De acuerdo con el artículo 3 inciso 3 de la Ley de Acceso a la Información Pública, se establece el principio de gratuidad de la información. Sin embargo, algunas instituciones norman los cobros por costos de reproducción lo que hace difícil de acceder a información de proyectos extractivos que están compuestos por expedientes voluminosos de información. </p>"
                        />
                        <Accordion
                            title="Gobernanza de las Industrias Extractivas"
                            content="
                            <p>El sector extractivo está compuesto por industrias que obtienen ganancias de la explotación y exportación de los recursos naturales sin valor agregado, (convirtiendo la naturaleza en mercancías para los mercados financieros y a los territorios en activos para las corporaciones). Los principales exponentes en Guatemala son el sector minas y canteras, hidrocarburos y la agricultura mono-cultivista (caña, palma, banano).</p>

                            <p>La gobernanza de las IE está estructurada por diferentes marcos legales organizados por sector extractivo: </p>
                            <p><a href='https://www.mem.gob.gt/wp-content/uploads/2015/06/1_Ley_de_Hidrocarburos_y_su_Reglamento.pdf'>Ley de Hidrocarburos (Decreto No.109-83)</a></p>
                            <p><a href='https://www.cnee.gob.gt/pdf/marco-legal/LEY%20GENERAL%20DE%20ELECTRICIDAD%20Y%20REGLAMENTOS.pdf'>Ley General  de Electricidad (Decreto No. 93-96)</a></p>
                            <p><a href='https://www.mem.gob.gt/wp-content/uploads/2015/06/1._Ley_de_Mineria_y_su_Reglamento.pdf'>Ley de Minería (Decreto No. 48-97)</a></p>

                            <p>El marco legal para la IE regula temas ambientales de manera separada, en la que se requiere la presentación de instrumentos de evaluación de impacto ambiental normados en la <a href='https://www.mem.gob.gt/wp-content/uploads/2015/06/4._Ley_de_Proteccion_y_Mejoramiento_al_Medio_Ambiente_Decreto_68-86.pdf'>Ley de Protección y Mejoramiento de Medio Ambiente (Decreto 68-86)</a> y el <a href='https://www.marn.gob.gt/Multimedios/4739.pdf'>Reglamento de Evaluación, Control y Seguimiento Ambiental (Acuerdo Gubernativo No. 137-2016)</a> el cual es complementado con una guía de elaboración de los instrumentos ambientales (DIGARN, 2004) y la <a href='http://www.ada2.org/sala-prensa/publicaciones/doc_view/28-coguanor-29001-99'>Norma COGUANOR (NGT 290001)</a> que regula temas específicos sobre límites máximos permitidos de contaminantes en el recurso hídrico para  el consumo humano. </p>

                            <p>Para todas estas normativas existen autoridades e instituciones que tienen a su cargo velar y ejecutar lo que mandatan las leyes. Para el caso de las IE, las principales instituciones a nivel ministerial son el Ministerio de Energía y Minas (MEM) y el Ministerio de Ambiente y Recursos Naturales (MARN). A nivel local las municipalidades en donde se ubican los proyectos tienen roles asignados en la gobernanza de las IE, su papel como autoridades competentes  establece una clara línea de responsabilidad  que basados en los marcos legales les asignan un rol en la explotación de recursos naturales. </p>
                            "

                        />
                        <Accordion
                            title="Glosario"
                            content="
                            <p><strong>Año de Exploración</strong></p>

                            <p>Período de doce meses consecutivos, contados a partir del día siguiente de la fecha de notificación al interesado de la resolución de otorgamiento del derecho minero de exploración. </p>

                            <p><strong>Año de Explotación</strong></p>

                            <p>Período de doce meses consecutivos, contados a partir del día siguiente de la fecha de notificación al interesado de la resolución de otorgamiento del derecho minero de explotación. </p>

                            <p><strong>Áreas de Interés Minero</strong></p>

                            <p>Áreas en las cuales están identificados o evaluados recursos mineros de importancia económica y que el Estado los declara como tales, a efecto de que se proceda a la exploración en forma inmediata a través de convocatoria. </p>

                            <p><strong>Canon</strong></p>

                            <p>Prestación pecuniaria periódica que grava una licencia u otorgamiento de derechos mineros o un disfrute en el dominio público, regulado en minería según el área otorgada, sean estas explotadas o no. </p>

                            <p><strong>Coordenadas UTM</strong></p>

                            <p>Coordenadas planas universales transversas de Mercator. </p>

                            <p><strong>Conflicto Socioambiental</strong></p>

                            <p>La conflictividad social ha sido causada por la forma de operar de las IE, la toma de decisiones centralizadas de las instituciones rectoras/reguladoras, y las respuestas del gobierno, las corporaciones y sus subsidiarias frente a la defensa del territorio y de los derechos individuales y colectivos de las comunidades locales. Las dinámicas extractivas en los territorios han afectado adversamente a la población que los habita, ya que son las poblaciones quienes reciben los impactos de la actividad extractiva en sus medios de vida y en sus dinámicas culturales, generando oposición al extractivismo y resistencia a la operación de estos proyectos que ponen en vulneración los derechos de las poblaciones locales.</p>

                            <p><strong>Derecho Minero</strong></p>

                            <p>Relación jurídica que se da entre el Estado y un solicitante, que nace de un acto administrativo del Ministerio o la Dirección, y que comprende licencias para la ejecución de operaciones mineras.</p>

                            <p><strong>Explotación Minera</strong></p>

                            <p>Extracción de rocas, minerales o ambos, para disponer de ellos con fines industriales, comerciales o utilitarios.</p>

                            <p><strong>Estudio de Mitigación</strong></p>

                            <p>Informe técnico que describe las operaciones de reconocimiento y exploración y las consecuencias de tales operaciones para el medio ambiente, con miras a su protección y conservación.</p>

                            <p><strong>Exploración Minera</strong></p>

                            <p>Conjunto de trabajos administrativos, de gabinete y de campo, tanto superficiales como subterráneos, que sean necesarios para localizar, estudiar y evaluar un yacimiento.</p>

                            <p><strong>Extractivismo</strong></p>

                            <p>Apropiación intensiva de recursos naturales a partir de la extracción, explotación y exportación para satisfacer la demanda de otros destinos globales, relegando a los territorios nacionales y locales a ser proveedores de materia prima sin valor agregado, recibiendo los impactos de las externalidades negativas y transformando de manera permanente las condiciones culturales, socioambientales, económicas y políticas.</p>

                            <p><strong>Gobernanza</strong></p>

                            <p>Para que exista una gobernanza desde los territorios para proteger los recursos naturales de los actores que los explotan, se necesita que los tomadores de decisiones, la ciudadanía y las poblaciones locales tengan acceso a datos, de toda la cadena de decisiones que faculta la operación de las distintas industrias extractivas.</p>

                            <p><strong>Gobierno Central</strong></p>

                            <ul>
                                <li>SAT</li>

                                <li>IGSS</li>

                                <li>MEM</li>

                                <li>MARN</li>
                            </ul>

                            <p><strong>Gobierno Local</strong></p>

                            <ul>
                                <li>Municipalidades</li>

                                <li>COCODES</li>
                            </ul>

                            <p><strong>Industrias Extractivas</strong></p>

                            <p>El sector extractivo está compuesto por industrias que obtienen ganancias de la explotación y exportación de los recursos naturales, convirtiendo la naturaleza en producto para los mercados financieros internacionales y a los territorios en activos para las corporaciones. Los principales exponentes son el sector minas y canteras, hidrocarburos, y la agricultura mono-cultivista (caña, palma y soya).  Estos sectores a su vez se vinculan directamente con el sector energético, como las hidroeléctricas. La forma de operar de las Industrias Extractivas ha sido causa de conflictos socioambientales en Guatemala. </p>

                            <p><strong>Instituciones reguladoras/rectoras</strong></p>

                            <p>Instituciones del gobierno central y local que tienen roles asignados en la gobernanza de las IE. Su papel como autoridades competentes con una clara línea de responsabilidad se basa en los marcos legales que nacionalmente permiten la explotación de recursos naturales.</p>

                            <p><strong>Modelo Subsidiario</strong></p>

                            <p>Modelo jurídico-administrativo de las industrias extractivas, que les facilita operar en distintas jurisdicciones y poseer activos a través de una compleja estructura corporativa, facilita también el manejo de los derechos sobre recursos naturales a nivel global y el financiamiento a través de mercados de valores y mercancías.</p>

                            <p><strong>Licencia</strong></p>

                            <p>Autorización otorgada por la Dirección o el Ministerio a un solicitante, para realizar operaciones de reconocimiento, exploración y de explotación.</p>

                            <p><strong>Minerales</strong></p>

                            <p>Son las substancias formadas por procesos naturales, con integración de elementos esencialmente provenientes de la corteza terrestre, que existen en el territorio de la República.</p>

                            <p><strong>Minería</strong></p>

                            <p>Es toda actividad de reconocimiento, exploración y explotación de productos mineros.</p>

                            <p><strong>Operaciones Mineras</strong></p>

                            <p>Todas y cada una de las actividades que tengan por objeto el desarrollo de la minería.</p>

                            <p><strong>Período de Reconocimiento</strong></p>

                            <p>Período de seis meses consecutivos, contados a partir del día siguiente de la fecha de notificación al interesado, de la resolución de otorgamiento del derecho minero de reconocimiento.</p>

                            <p><strong>Productos Mineros</strong></p>

                            <p>Rocas o minerales extraídos de un yacimiento o los productos resultado de la separación de los mismos.</p>

                            <p><strong>Reconocimiento Minero</strong></p>

                            <p>Conjunto de trabajos administrativos, de gabinete y de campo, tanto superficiales como subterráneos, que sean necesarios para localizar e identificar áreas para exploración minera.</p>

                            <p><strong>Regalía</strong></p>

                            <p>Es la compensación económica que se paga al Estado por la explotación de productos mineros o de materiales de construcción, no considerada como un tributo.</p>

                            <p><strong>Roca Decorativa</strong></p>

                            <p>Son todas aquellas substancias minerales que se utilizan con fines ornamentales, tales como: mármol, serpentinitas, gneis, filitas, travertino, ónix, jaspe y cualquier otra afín.</p>

                            <p><strong>Servidumbre legal</strong></p>

                            <p>Se tendrán como servidumbres legales, todas aquellas que sea necesario constituir, teniendo como fin operaciones mineras y en especial, las actividades de reconocimiento, exploración y explotación.</p>

                            <p><strong>Titular de Derecho Minero</strong></p>

                            <p>Toda persona que obtiene una resolución favorable del Ministerio o de la Dirección, para realizar operaciones mineras conforme a esta ley.</p>

                            <p><strong>Yacimiento</strong></p>

                            <p>Toda acumulación de rocas o concentración natural de uno o más minerales.</p>"
                        />
                        <Accordion
                            title="Descarga de responsabilidad"
                            content="
                            No podemos garantizar la integridad o exactitud de estos datos y se recomienda a los usuarios que verifiquen las fuentes originales antes de tomar decisiones serias basadas en los datos presentados aquí."
                        />
                    </div>
                </div>
            </div>
        )
    }
}

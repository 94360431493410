import React from 'react';
import axios from 'axios';

// Directly importing the minified bootstrap css to avoid all the painful
// steps it will take otherwise to get it to work.
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

export default class ProjectLinks extends React.Component {
    state = {
        dataset: [],
        cuencas: []
    };

    async getData() {
        let res = await axios.get(`https://collector.oiegt.org/api/proyecto-extractivo_/${this.props.projectId}`);

        this.setState({
            dataset: res.data
        });
    }

    componentDidMount() {
        this.getData();
    }

    render() {

        const data = this.state.dataset;
        const link = data.link_documentos;

        return (
            <div className="projectLinksWrapper">
                <div>
                    <h1 className="title">Genera un reporte imprimible con los datos del proyecto extractivo y las visualizaciones</h1>
                    <a className="round-btn" onClick={() => window.print()}>
                        <span>Genera reporte</span>
                    </a>
                </div>
                <div>
                    <h2 className="subtitle">Descarga documentos oficiales desde nuestro Drive en Google</h2><br/>
                    <a className="round-btn-small" target="_blank" href={ data.link_documentos }>
                        <span>Link a documentos oficiales</span>
                    </a>
                </div>
            </div>
        );
    }
}

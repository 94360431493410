import React from 'react';
import axios from 'axios';
import {isMobile} from 'react-device-detect';
import { BarStack, Line } from '@visx/shape';
import { SeriesPoint } from '@visx/shape/lib/types';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import cityTemperature, { CityTemperature } from '@visx/mock-data/lib/mocks/cityTemperature';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { timeParse, timeFormat } from 'd3-time-format';
import { withTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';

import ParentSize from '@visx/responsive/lib/components/ParentSize';

import {
  Legend,
  LegendLinear,
  LegendQuantile,
  LegendOrdinal,
  LegendSize,
  LegendThreshold,
  LegendItem,
  LegendLabel,
} from '@visx/legend';

import { LinearGradient } from '@visx/gradient';

import TooltipModal from './TooltipModal';
import tooltips from './assets/tooltips';

const palette = [
    "#341700"
    , "#512700"
    , "#6B3800"
    , "#834A00"
    , "#985C01"
    , "#AA6E00"
    , "#B97F06"
    , "#CD9300"
    , "#E1AD00"
    , "#F5CB00"
];

const paletteH = ["#341700", "#512700", "#6B3800", "#834A00", "#985C01", "#AA6E00", "#B97F06", "#CD9300", "#E1AD00", "#F5CB00"];

const color1 = '#A5230E';
const color2 = '#c998ff';
const color3 = "#341700";
const color4 = '#A5230E';
const background = '#333333';
const background2 = '#964d18';
const accentColor = '#2eff8b';
const accentColorDark = '#75daad';
const defaultMargin = { top: 40, left: 50, right: 40, bottom: 100 };
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

function addCommas(nStr)
{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

const olddata = cityTemperature.slice(0, 12);

const legendGlyphSize = 15;

export default class PetrolGeneralProductionChart extends React.Component {
    constructor(props) {
      super(props);
    }

    state = {
        isLoaded: false,
        width: isMobile ? window.innerWidth - 40 : window.innerWidth - 100,
        height: 400,
    }

    async getData() {

        let res = await axios.get(`https://collector.oiegt.org/api/produccion-petroleo`);

        let finalData = [];

        if (res.data.length > 0) {

            const keys = Object.keys(res.data[0]).filter(function(d) {
                if (d !== 'mes' && d !== 'comentario') {
                    return d;
                }
            });

            let data = res.data.map(function(item) {
                let newItem = {}
                keys.forEach(function(key) {
                    newItem[key] = item[key];
                });
                return newItem;
            });

            let years = [...new Set(data.map(item => item.anio))];
            years.sort();

            let contracts = [...new Set(data.map(item => item.id_contrato))];

            years.forEach(function(year) {
                let singleYear = data.filter(function(item) {
                    if (item.anio === year) {
                        return item;
                    }
                });
                let newItem = {
                    anio: year
                }
                contracts.forEach(function(key) {
                    let contractArray = singleYear.filter(function(obj){
                        if (obj.id_contrato === key) {
                            return obj;
                        }
                    });

                    let singleYearContract = contractArray.reduce((acc, obj) => {
                        acc += Number(obj.total_barriles);
                        return acc;
                    }, 0);

                    newItem[key] = singleYearContract;
                });

                finalData.push(newItem);
            });



        }
        this.setState({
            isLoaded: true,
            dataset: res.data,
            finalData: finalData
        });
    }

    componentDidMount() {
        this.getData();
    }

    render () {
        if (this.state.isLoaded) {
            if (this.state.dataset.length > 0) {
                return (
                    <div>
                        <div className="project-module-header">
                            <h1 className="section-title white">Producción de barriles de petróleo</h1>
                            <TooltipModal title="Producción de petróleo" text={tooltips.petrol_production} />
                        </div>
                        <ParentSize>
                            {({ width, height }) => {
                                return (
                                    <ChartWithTooltip data={this.state.finalData} width={width} height={400} />
                                );
                            }}
                        </ParentSize>
                    </div>
                )

            }
            else {
                return (
                    <div className="still-looking">
                        <img src={require('./assets/images/oie-stilllooking.svg')} />
                    </div>
                )
            }
        }
        else {
            return (
                <div>Cargando...</div>
            )
        }
    }
}

const ChartWithTooltip = withTooltip(
    ({
        data,
        width,
        height,
        events = true,
        tooltipOpen,
        tooltipLeft,
        tooltipTop,
        tooltipData,
        hideTooltip,
        showTooltip,
    }) => {

        const keys = Object.keys(data[0]).filter(function(d) {
            if (d !== 'anio' && d !== 'mes') {
                return d;
            }
        });

        const totals = data.reduce((obj, val) => {
            const totalSingle = keys.reduce((total, i) => {
                total += Number(val[i]);
                return total;
            }, 0);
            obj.push(totalSingle);
            return obj;
        }, []);

        // accessors
        const getDate = (d) => d.anio;

        // scales
        const yScale = scaleLinear({
            domain: [0, Math.max(...totals)],
            nice: true,
        });

        const xScale = scaleBand({
            domain: data.map(getDate),
            padding: 0.2,
        });

        const colorScale = scaleOrdinal({
            domain: keys,
            range: paletteH,
        });

        let tooltipTimeout;

        let margin;

        // bounds
        if (width > 900) {
            margin = { top: 40, left: 100, right: 100, bottom: 80 };
        }
        else {
            margin = { top: 40, left: 50, right: 50, bottom: 80 };
        }

        let xMax = width - margin.left - margin.right;
        let yMax = height - margin.top - margin.bottom;

        yScale.range([yMax, 0]);
        xScale.rangeRound([0, xMax]);

        return width < 10 ? null : (
            <div>
                <svg width={width} height={height}>
                    <rect width={width} height={height} fill="url(#area-background-gradient)" rx={14} />
                    <LinearGradient id="area-background-gradient" from={background} to={background2} />
                    <LinearGradient id="area-gradient" from={accentColor} to={accentColor} toOpacity={0.1} />

                    <Group top={margin.top} left={margin.left}>
                        <BarStack
                            data={data}
                            keys={keys}
                            height={yMax}
                            x={getDate}
                            xScale={xScale}
                            yScale={yScale}
                            color={colorScale}
                            >
                            {barStacks =>
                                barStacks.map(barStack =>
                                    barStack.bars.map(bar => (
                                        <rect
                                            key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                                            x={bar.x}
                                            y={bar.y}
                                            width={bar.width}
                                            height={bar.height}
                                            fill={bar.color}
                                            onMouseLeave={() => {
                                                tooltipTimeout = window.setTimeout(() => {
                                                    hideTooltip();
                                                }, 300);
                                            }}
                                            onMouseMove={() => {
                                                if (tooltipTimeout) clearTimeout(tooltipTimeout);
                                                const top = bar.y + margin.top;
                                                const left = bar.x + bar.width + margin.left;
                                                showTooltip({
                                                    tooltipData: bar,
                                                    tooltipTop: top,
                                                    tooltipLeft: left,
                                                });
                                            }}
                                            />
                                    )),
                                )
                            }
                        </BarStack>

                        <AxisBottom
                            top={yMax}
                            scale={xScale}
                            stroke={color3}
                            tickStroke={color3}
                            >
                            {props => {
                                let rotate, anchor;
                                if (width > 900) {
                                    rotate = 0;
                                    anchor = "middle";
                                }
                                else {
                                    rotate = 90;
                                    anchor = "start";
                                }
                                const tickLabelSize = 10;
                                const tickRotate = rotate;
                                const tickColor = '#8e205f';
                                const axisCenter = (props.axisToPoint.x - props.axisFromPoint.x) / 2;
                                return (

                                    <g className="my-custom-bottom-axis">
                                        <line class="visx-line visx-axis-line" x1={props.axisFromPoint.x} y1="0" x2={props.axisToPoint.x} y2="0" fill="transparent" shape-rendering="crispEdges" stroke={color4} stroke-width="1"></line>
                                        {props.ticks.map((tick, i) => {
                                            const tickX = tick.to.x;
                                            const tickY = tick.to.y + tickLabelSize + props.tickLength;
                                            return (
                                                <Group
                                                    key={`vx-tick-${tick.value}-${i}`}
                                                    className={'vx-axis-tick'}
                                                    >
                                                    <Line
                                                        from={tick.from}
                                                        to={tick.to}
                                                        stroke={tickColor}
                                                        />
                                                    <text
                                                        transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                                                        fontSize={tickLabelSize}
                                                        textAnchor={anchor}
                                                        fill={tickColor}
                                                        >
                                                        {tick.formattedValue}
                                                    </text>
                                                </Group>
                                            );
                                        })}
                                        <text
                                            textAnchor="middle"
                                            transform={`translate(${axisCenter}, 50)`}
                                            fontSize="8"
                                            >
                                            {props.label}
                                        </text>
                                    </g>
                                );
                            }}
                        </AxisBottom>

                        <AxisLeft
                            left={0}
                            label={"Barriles"}
                            labelOffset={70}
                            scale={yScale}
                            >
                            {props => {
                                let rotate, label, tickLabelSize;
                                const tickColor = '#8e205f';
                                const axisCenter = (props.axisFromPoint.y - props.axisToPoint.y) / 2 * -1;

                                if (width > 900) {
                                    tickLabelSize = 10;
                                    rotate = 0;
                                    label =
                                    <text
                                        textAnchor="middle"
                                        x={axisCenter}
                                        y={-82}
                                        transform={`rotate(${-90})`}
                                        fontSize="12"
                                        >
                                        {props.label}
                                    </text>
                                }
                                else {
                                    tickLabelSize = 8;
                                    rotate = -45;
                                    label =
                                    <text
                                        textAnchor="end"
                                        x={0}
                                        y={-10}
                                        fontSize="10"
                                        >
                                        {props.label}
                                    </text>
                                }

                                const tickRotate = rotate;

                                return (

                                    <g className="my-custom-bottom-axis">
                                        <line class="visx-line visx-axis-line" x1="0" y1={props.axisFromPoint.y} x2="0" y2={props.axisToPoint.y} fill="transparent" shape-rendering="crispEdges" stroke={color4} stroke-width="1"></line>
                                        {props.ticks.map((tick, i) => {
                                            const tickX = -8;
                                            // const tickY = tick.to.y + (props.axisFromPoint.y / props.numTicks);
                                            const tickY = tick.to.y;
                                            return (
                                                <Group
                                                    key={`vx-tick-${tick.value}-${i}`}
                                                    className={'vx-axis-tick'}
                                                    >
                                                    <Line
                                                        from={tick.from}
                                                        to={tick.to}
                                                        stroke={tickColor}
                                                        />
                                                    <text
                                                        transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                                                        fontSize={tickLabelSize}
                                                        textAnchor="end"
                                                        fill={tickColor}
                                                        >
                                                        {tick.formattedValue}
                                                    </text>
                                                </Group>
                                            );
                                        })}
                                        {label}
                                    </g>
                                );
                            }}
                        </AxisLeft>

                    </Group>
                </svg>
                <div
                    style={{
                        position: 'absolute',
                        top: margin.top / 2 - 10,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '14px',
                    }}
                    >
                </div>
                {tooltipOpen && tooltipData && (
                    <Tooltip className="visxTooltip" top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
                        <div style={{ color: colorScale(tooltipData.key) }}>
                            <strong>{tooltipData.key.replace(/_/g, " ")}</strong>
                        </div>
                        <div>{addCommas(tooltipData.bar.data[tooltipData.key].toFixed(0))}</div>
                        <div>
                            <small>{getDate(tooltipData.bar.data)}</small>
                        </div>
                    </Tooltip>
                )}

                <LegendDemo title="Proyectos">
                    <LegendOrdinal scale={colorScale} labelFormat={label => `${label.toUpperCase()}`}>
                        {labels => (
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {labels.map((label, i) => (
                                    <LegendItem
                                        key={`legend-quantile-${i}`}
                                        margin="0 5px"
                                        >
                                        <svg width={legendGlyphSize} height={legendGlyphSize}>
                                            <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                                        </svg>
                                        <LegendLabel align="left" margin="0 0 0 4px">
                                            {label.text.replace(/_/g, " ")}
                                        </LegendLabel>
                                    </LegendItem>
                                ))}
                            </div>
                        )}
                    </LegendOrdinal>
                </LegendDemo>
            </div>
        );
    },
);

function LegendDemo({ title, children }: { title: string; children: React.ReactNode }) {
    return (
        <div className="visxLegend">
            <div className="visxLegendTitle">{title}</div>
            {children}
        </div>
    );
}

import React from 'react';
import axios from 'axios';
import {isMobile} from 'react-device-detect';

import './Catalogue.css';

export default class Catalogue extends React.Component {

    constructor(props) {
      super(props);
    }

    state = {
        isLoaded: false,
        width: isMobile ? window.innerWidth - 40 : window.innerWidth - 100,
        height: 400,
        entries: []
    }

    async getData() {
        let res = await axios.get(`https://collector.oiegt.org/api/catalog/tag-list`);

        let tags = [];

        if (res.data) {
            console.log("tags!", res.data);

            for (let i = 0; i < res.data.length; i++) {
                tags.push(
                    <li class="tag-entry">
                        <a href={`/catalogo/${res.data[i].tag}`}>
                            {res.data[i].tag}
                        </a>
                    </li>
                );
            }

            this.setState({
                tags: tags
            });
        }

        if (this.props.match.params.tag) {
            let tag = this.props.match.params.tag;
            let tagRes = await axios.get(`https://collector.oiegt.org/api/catalog?t=${tag}`);

            console.log("TAGGGGGG", tag);
            console.log("TAG RESSSSS", tagRes);

            let tagEntries = [];

            for (let i = 0; i < tagRes.data.length; i++) {
                tagEntries.push(
                    <div class="catalogue-entry">
                        <h1><a href={tagRes.data[i].enlace} target="_blank">{tagRes.data[i].nombre}</a></h1>
                        <h2>Actualizado {tagRes.data[i].fecha_actualizacion ? tagRes.data[i].fecha_actualizacion : "N/A"} / Tipo de documento: {tagRes.data[i].data_type} / Formato: {tagRes.data[i].formato}</h2>
                        <p>{tagRes.data[i].descripcion}</p>
                    </div>
                );
            }

            console.log("TAG ENTRIESSS", tagEntries);

            this.setState({
                tag: tag,
                tagEntries: tagEntries
            });

        } else {
            console.log("no params");
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        let toRender = <p>Selecciona una etiqueta</p>;
        if (this.state.tagEntries) {
            if (this.state.tagEntries.length > 0) {
                toRender = this.state.tagEntries;
            }
            else {
                toRender = "No hay resultados"
            }
        }
        return (
            <div id="catalogue">
                <div class="wrapper-full">
                    <div class="sidebar">
                        <h1>Etiquetas</h1>
                        <ul>
                            {this.state.tags}
                        </ul>
                    </div>
                </div>
                <div class="catalogue-content">
                    <h1>Catálogo de datos{this.state.tagEntries ? `: ${this.state.tag}` : "" }</h1>
                    {/*
                    <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec hendrerit condimentum iaculis. Pellentesque orci odio, venenatis quis lectus nec, ultricies rutrum eros. </h2>
                    <input type="text"></input>
                    <ul class="tags">
                        <li>Tag 1</li>
                        <li>Tag 2</li>
                        <li>Tag 3</li>
                        <li>Tag 4</li>
                        <li>Tag 5</li>
                    </ul>
                    */}
                    <div class="catalogue-entries">
                        {toRender}
                        {/*
                        <div class="catalogue-entry">
                            <h1>LEXT-015-11 : Proyecto Nombre </h1>
                            <h2>Actualizado al 2020-01-29 / Doc type: Expediente / Formato PDF / Solicitud UIP-199 -2018  </h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec hendrerit condimentum iaculis. Pellentesque orci odio, venenatis quis lectus nec, ultricies rutrum eros. Ut placerat massa nisl, at sagittis dolor dictum sit amet. Nulla aliquet justo vitae purus congue, vitae scelerisque lorem ullamcorper. Vestibulum vel erat ex. Aenean non dignissim mauris, id rutrum turpis. Donec hendrerit condimentum iaculis. Pellentesque orci odio, venenatis quis lectus nec, ultricies rutrum eros.</p>
                            <ul class="tags">
                                <li>Tag 1</li>
                                <li>Tag 2</li>
                                <li>Tag 3</li>
                                <li>Tag 4</li>
                                <li>Tag 5</li>
                            </ul>
                        </div>
                        */}
                    </div>
                </div>
            </div>
        )
    }
}

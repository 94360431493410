import React from 'react';
import './SocioEnvironmentalGovernance.css';
import AguaWebMapView from './components/ArcGis/AguaWebMapView'

export default class SocioEnvironmentalGovernance extends React.Component {
    render() {
        return (
            <div id="seg">
                <div className="banner-wrapper">
                    <div className="banner-wrapper-content">
                        <div className="banner-info">
                            <h1 className="black title">Datos producidos por científicos comunitarios  que buscan cerrar la brecha de información  existente sobre  el recurso hídrico en sus territorios.</h1>
                            <h2 className="subtitle black bold">Monitoreo comunitario</h2>
                            <p>En Guatemala la gobernanza socioambiental de las IE ha sido relegada a las empresas consultoras ambientales quienes identifican y definen cuál es el impacto de los proyectos y cómo se mitigaran los mismos (Dougerthy, 2019). Por su parte las autoridades reguladoras carecen de las condiciones institucionales para asegurar la gobernanza ambiental en los territorios en donde se establecen los proyectos extractivos.</p>

                            <p>La gobernanza socio ambiental que se plantea desde el OIE es una de carácter transdisciplinario que busca poner en diálogo el conocimiento local  con conocimientos científicos.</p>

                            <p>Un proyecto extractivo puede tener en múltiples impactos socioambientales, sin embargo, la gobernanza socio ambiental comunitaria se centra en el monitoreo del recurso hídrico. Debido a que consideramos que el recurso hídrico es un nodo central en el modelo extractivista y es un elemento vital para los medios de vida de las poblaciones que habitan el territorio.</p>
                        </div>
                        <div className="cc-image"></div>
                    </div>
                </div>
                <div className="wrapper-full">
                   <AguaWebMapView />
                </div>
                <div className="wrapper-symmetrical paddingTop">
                    <h1 className="title black bold">Indicadores<br/> de calidad de agua</h1>
                    <div className="grid water-indicators">
                        <div>
                            <img src={require('./assets/icons/icon-wi-ph.svg')} width={'48px'} />
                            <div>
                                <h2 className="small-title black">pH:</h2>
                                <p>El pH es “una medición de la cantidad relativa de iones de hidrógeno e hidróxido en el agua. Agua que contenga más iones de hidrógeno tiene una acidez mayor, mientras que agua que contenga más iones de hidróxido indica un rango básico”. El pH se mide en unidades de potencial hidrógeno (pH) (UICN, 2018).</p>
                            </div>
                        </div>
                        <div>
                            <img src={require('./assets/icons/icon-wi-solids.svg')} width={'49px'} />
                            <div>
                                <h2 className="small-title black">Sólidos disueltos totales:</h2>
                                <p>Es la materia disuelta en el agua, es decir, que permanece después de pasar la muestra por un ﬁltro de 2 micrones o menos. Así se diferencian de los sólidos suspendidos, que son los que quedan retenidos por el ﬁltro. Los sólidos disueltos totales (SDT) se miden en partes de sólidos por un millón de partes agua (ppm) (UICN, 2018).</p>
                            </div>
                        </div>
                        <div>
                            <img src={require('./assets/icons/icon-wi-arsenic.svg')} width={'52px'} />
                            <div>
                                <h2 className="small-title black">Arsénico:</h2>
                                <p>El arsénico es un elemento natural ampliamente distribuido en la corteza terrestre y puede encontrarse en altos niveles en aguas subterráneas. El arsénico es muy tóxico en su forma inorgánica y su mayor amenaza para la salud humana consiste en la utilización de agua contaminada para beber, preparar alimentos, bañarse y regar cultivos usados como alimentos (OMS).</p>
                            </div>
                        </div>
                        <div>
                            <img src={require('./assets/icons/icon-wi-conductivity.svg')} width={'48px'} />
                            <div>
                                <h2 className="small-title black">Conductividad eléctrica:</h2>
                                <p>Es “la capacidad de una solución de transmitir una corriente eléctrica”. Cuando una solución tiene compuestos inorgánicos (sales y metales) suele tener alta conductividad; en cambio, cuando tiene materia orgánica, suele tener baja conductividad. La conductividad eléctrica (EC) se mide en microSimens por centímetro (UICN, 2018) . </p>
                            </div>
                        </div>
                        <div>
                            <img src={require('./assets/icons/icon-wi-temperature.svg')} width={'52px'} />
                            <div>
                                <h2 className="small-title black">Temperatura:</h2>
                                <p>Es una medida del grado de calor o frío del agua. Los valores obtenidos generalmente sirven como datos auxiliares para realizar otras determinaciones como es el caso del oxígeno disuelto, alcalinidad, salinidad, conductividad  y actividad biológica. La temperatura se mide en grados centígrados (C).</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const primaryPalette = [
    "#400517"
    , "#6B050F"
    , "#9E0308"
    , "#D10000"
    , "#DD2400"
    , "#FF6600"
    , "#F58C05"
    , "#FAA308"
    , "#FFBA08"
    , "#F5CB00"
];

export const extendedPalette = [
    "#400517"
    , "#6B050F"
    , "#9E0308"
    , "#D10000"
    , "#DD2400"
    , "#FF6600"
    , "#F58C05"
    , "#FAA308"
    , "#FFBA08"
    , "#F5CB00"

    , "#FF6600" 
    , "#FF9B00" 
    , "#FFBA08" 
    , "#FCE900" 
    , "#C6EA00" 
    , "#1BB119" 
    , "#0192D1" 
    , "#0046FE" 
    , "#4500A9"
    
    // "#2b5676",
    // "#a34d48",
    // "#3e729e",
    // "#526728",
    // "#711f2f",
    // "#326440",
    // "#692f1c",
    // "#2a6c69",
    // "#8d5154",
    // "#17324d",
    // "#856030",
    // "#17324d",
    // "#514522",
    // "#17324d",
    // "#2a425f",
    // "#17324d",
    // "#17324d",
    // "#17324d",
    // "#17324d",
    // "#17324d",
    // "#17324d",
    // "#17324d",
    // "#17324d",
    // "#17324d",
    // "#17324d",
    // "#17324d",
    // "#17324d",
    // "#17324d",
    // "#17324d",
    // "#17324d"
];

export const secondaryPalette = [];

export const primaryBackground = '#333333';
export const secondaryBackground = '#964d18';
export const primaryStroke = '#FFFFFF';

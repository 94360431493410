import React from 'react';
import './CorporativeGovernance.css';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import ChordTest from './ChordTest';
import Corporations from './Corporations';
import CorporationsSEC from './CorporationsSEC';

import tooltips from './assets/tooltips';
import TooltipModal from "./TooltipModal";

export default class CorporativeGovernance extends React.Component {
    render() {
        return (
            <div id="corporativeGovernance">

                    <div className="section fp-auto-height">
                        <div className="banner-wrapper">
                            <div className="banner-wrapper-content">
                                <div className="banner-info black">
                                    <span className="subtitle">Gobernanza Corporativa</span>

                                    <h1 className="title">Conoce a las corporaciones detrás de la operación extractiva en Guatemala</h1>
                                    <p>La gobernanza corporativa se refiere a los procesos transnacionales  detrás de la operación de las corporaciones y sus articulaciones con lo local para operar proyectos extractivos  en los territorios. Este módulo busca visualizar datos de los mercados globales en donde las corporaciones buscan fondos de inversión para transformar los recursos naturales a mercancías/commodities que satisfacen otros destinos globales.</p>

                                    <p>La gobernanza corporativa que se plantea desde el OIE está enfocada en compartir datos de las empresas transnacionales, su estructura corporativa, sus accionistas, los mercados financieros en los que participan, sus declaraciones en las comisiones de seguridad de las bolsas de valores y el precio de los metales o mercancías  comercializadas. Así mismo, también hacer una análisis de los operadores a nivel nacional e identificar si existen casos de captura corporativa o puertas giratorias.</p>
                                </div>
                                <div className="gc-image"></div>
                            </div>
                        </div>
                    </div>
                    <div className="section fp-auto-height green">
                        <div className="wrapper">
                            <Corporations />
                        </div>
                    </div>
                    <div className="section green">
                        <div className="wrapper noPaddingTop">
                            <CorporationsSEC />
                        </div>
                    </div>
                    <div class="section fp-auto-height ocre">
                        <div className="wrapper">
                            <div className="banner-wrapper">
                                <div className="banner-wrapper-content-full">
                                    <div className="banner-info black">
                                        <h1 className="title">Puertas Giratorias <TooltipModal title="Puertas Giratorias" text={tooltips.puertas_giratorias} /></h1>
                                    </div>
                                    <div>
                                        <p>Las puertas giratorias pueden ir en ambas vías del gobierno al sector privado y del sector privado al gobierno. En ambos casos los agentes que juegan este rol usan su experiencia, conexiones, poder para obtener beneficios para la empresa o para facilitar procesos políticos o regulatorios dentro del gobierno para la industria. Uno de los principales riesgos de corrupción en esta dinámica de puertas giratorias es que compromete la integridad e imparcialidad de las acciones dentro del sector público.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section fp-auto-height">
                        <div class="wrapper noPaddingTop">
                            <img src={require('./assets/images/oie-gc-pg-01.svg')} width={'100%'} />
                        </div>
                    </div>
                    <div className="section fp-auto-height">
                        <div class="wrapper noPaddingTop">
                            <img src={require('./assets/images/oie-gc-pg-02.svg')} width={'100%'} />
                        </div>
                    </div>
                    <div className="section">
                        <div class="wrapper">
                            <div className="project-module-header">
                                <h1 className="section-title">Actores Extractivos</h1>
                            </div>
                            <iframe src="https://embed.kumu.io/2acef2b45fc2930053196aaa6354ec43" className="mapa-actores" height="600" frameborder="0"></iframe>
                        </div>
                    </div>
                    <div className="section">
                        <div class="wrapper">
                            <div className="project-module-header">
                                <h1 className="section-title">Grupo Lundin</h1>
                            </div>
                            <iframe src="https://embed.kumu.io/1d8f11ffa590c87943935449d53aba06" className="mapa-actores" height="600" frameborder="0"></iframe>
                        </div>
                    </div>
                    <div className="section">
                        <div class="wrapper">
                            <div className="project-module-header">
                                <h1 className="section-title">Mapeo de níquel</h1>
                            </div>
                            <iframe src="https://embed.kumu.io/bb8ca001a8c63ee676484287120087b8" className="mapa-actores" height="600" frameborder="0"></iframe>
                        </div>
                    </div>
                    <div className="section">
                        <div class="wrapper">
                            <div className="project-module-header">
                                <h1 className="section-title">Hidropoder</h1>
                            </div>
                            <iframe src="https://embed.kumu.io/c6a708613247a2936220ff0b69156681" className="mapa-actores" height="600" frameborder="0"></iframe>
                        </div>
                    </div>
            </div>
        )
    }
}

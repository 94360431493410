import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { createBrowserHistory } from 'history';
import Header from "./Header";
import Home from "./Home";
import Project from "./Project";
import ProjectsMap from "./ProjectsMap";
import Governance from "./Governance";
import SocioEnvironmentalGovernance from "./SocioEnvironmentalGovernance";
import CorporativeGovernance from "./CorporativeGovernance";
import CommunityMonitoring from "./CommunityMonitoring";
import Catalogue from "./Catalogue";
import Library from "./Library";
import MapsLibrary from "./MapsLibrary";
import AboutUs from "./AboutUs";
import './App.css';
//import ReactGA from 'react-ga';
//import GA from './utils/GoogleAnalytics';
import logoBig from './assets/images/oie-logo_big.svg';

Object.defineProperty(Date.prototype, 'YYYYMMDDHHMMSS', {
    value: function() {
        function pad2(n) {  // always returns a string
            return (n < 10 ? '0' : '') + n;
        }

        return this.getFullYear() +
               pad2(this.getMonth() + 1) +
               pad2(this.getDate()) +
               pad2(this.getHours()) +
               pad2(this.getMinutes()) +
               pad2(this.getSeconds());
    }
});

export default class App extends React.Component {
    render() {
        return (
            <Router>
                 {/* { GA.init() && <GA.RouteTracker /> } */}
                <div>
                    <Header />
                    <Container />
                    <div className="footer">
                        <div className="footerRow hideInPrint">
                            <div className="site-branding">
                                <Link className="logoBig" to="/"><span>OIE</span></Link>
                            </div>
                            <div className="footerLinks">
                                <nav>
                                    <ul>
                                        <li>
                                            <Link to="/" onClick={this.toggleNav}>Inicio</Link>
                                        </li>
                                        <li>
                                            <Link to="/proyectos" onClick={this.toggleNav}>Proyectos extractivos</Link>
                                        </li>
                                        <li>
                                            <Link to="/gobernanza" onClick={this.toggleNav}>Gobernanza de la IE</Link>
                                        </li>
                                        <li>
                                            <Link to="/gobernanza-socioambiental" onClick={this.toggleNav}>Gobernanza Socioambiental</Link>
                                        </li>
                                        <li>
                                            <Link to="/gobernanza-corporativa" onClick={this.toggleNav}>Gobernanza Corporativa</Link>
                                        </li>
                                        <li>
                                            <Link to="/acerca-de" onClick={this.toggleNav}>Acerca del OIE</Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div>
                                <p className="footerText redText">Para preguntas, comentarios o sugerencias escríbenos</p>
                                <p><a href="mailto:contacto@oiegt.org">contacto@oiegt.org</a></p>
                                <p className="footerText redText">Síguenos</p>
                                <p><a href="https://twitter.com/oie_gt" class="icon-twitter" target="_blank"><span>Twitter</span></a></p>
                            </div>
                        </div>
                        <div className="footerRow">
                            <div id="disclaimer">
                                <p className="footerText redText">Descarga de responsabilidad</p>
                                <p className="whiteText smallText">El OIE hace uso de plataformas y medios digitales con fines de divulgación. Se sugiere consultar las fuentes originales de los datos aquí presentados antes de tomar decisiones basadas en ellos. El OIE actualiza de forma regular la información de la página, sin embargo, no se puede garantizar la exactitud ni la integridad de los datos en todo momento.</p>
                            </div>
                            <div>
                                <div id="oxfam"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Router>
        );
    }
}

function Container() {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/proyectos" component={ProjectsMap} />
            <Route exact path="/proyectos/:projectId" component={Project} />
            <Route exact path="/gobernanza" component={Governance} />
            <Route exact path="/gobernanza-socioambiental" component={SocioEnvironmentalGovernance} />
            <Route exact path="/gobernanza-corporativa" component={CorporativeGovernance} />
            <Route exact path="/monitoreo-comunitario" component={CommunityMonitoring} />
            {/* <Route exact path="/catalogo" component={Catalogue} /> */}
            <Route exact path="/catalogo/:tag" component={Catalogue} />
            <Route exact path="/biblioteca" component={Library} />
            <Route exact path="/biblioteca/mapas" component={MapsLibrary} />
            <Route exact path="/acerca-de" component={AboutUs} />
        </Switch>
    );
}

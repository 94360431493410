import React from 'react';
import GovernanceRoyaltiesStackbar from './GovernanceRoyaltiesStackbar';

import GovernanceMiningRoyaltiesChart from './GovernanceMiningRoyaltiesChart';
import GovernanceMiningVoluntaryRoyaltiesChart from './GovernanceMiningVoluntaryRoyaltiesChart';

import GovernanceCanonsStackbar from './GovernanceCanonsStackbar';

import GovernanceMiningCanonsChart from './GovernanceMiningCanonsChart';

import GovernanceExtractionsTreeMap from './GovernanceExtractionsTreeMap';
import GovernanceExtractionsPMTreeMap from './GovernanceExtractionsPMTreeMap';
import GovernanceExportationsFlowMap from './GovernanceExportationsFlowMap';

import GovernanceExportationsMap from './GovernanceExportationsMap';

import GeneralExtractionsTreemap from './GeneralExtractionsTreemap';
import GeneralExtractionsPMTreemap from './GeneralExtractionsPMTreemap';

import EmployeesGeneralIgssChart from './EmployeesGeneralIgssChart';

import GovernanceTimeline from './GovernanceTimeline';
import './Governance.css';

import PetrolGeneralUnifiedChart from './PetrolGeneralUnifiedChart';
import PetrolGeneralProductionChart from './PetrolGeneralProductionChart';
import PetrolGeneralRecoverableCostsChart from './PetrolGeneralRecoverableCostsChart';
import PetrolGeneralEarningsChart from './PetrolGeneralEarningsChart';
import PetrolGeneralConsumptionChart from './PetrolGeneralConsumptionChart';
import PetrolGeneralExportationsChart from './PetrolGeneralExportationsChart';

import TooltipModal from './TooltipModal';
import tooltips from './assets/tooltips';

export default class Governance extends React.Component {
    render() {
        return (
            <div>
                <div className="section darkgray-bg fp-auto-height">
                    <div className="banner-wrapper" style={{background: "#1F2121", paddingTop: "250px"}}>
                        <div className="banner-wrapper-content">
                            <span className="subtitle">Gobernanza de las Industrias Extractivas</span>
                            <h1 className="orange title">Análisis de datos agregados sobre el sector extractivo.</h1>
                            <p className="paragraph">El sector extractivo está compuesto por industrias que obtienen ganancias de la explotación y exportación de los recursos naturales, convirtiendo la naturaleza en mercancías para los mercados financieros internacionales y a los territorios en activos para las corporaciones.</p>
                        </div>
                    </div>
                </div>
                <div className="section darkgray-bg fp-auto-height">
                    <div className="wrapperCentered governanceDisclaimer">
                        <span className="electricOrange subtitle">Industria Extractiva en Guatemala</span>

                        <div className="governanceDisclaimerWrapper">
                            <p>
                                La gobernanza de las IE está regulada por diferentes marcos legales por sector extractivo, como la Ley de Minería (Decreto No. 48-97); la Ley de Hidrocarburos (Decreto No.109-83) o la Ley General  de Electricidad (Decreto No. 93-96). Además de estas el marco legal también regula de manera  específica temas ambientales en la Ley de Protección y Mejoramiento de Medio Ambiente (Decreto 68-86),  asimismo hay normativas como el Reglamento de evaluación, Control y Seguimiento Ambiental (Acuerdo Gubernativo No. 137-2016) o COGUANOR que regulan el tema específico del agua.
                            </p>

                            <p>
                                Para todas estas normativas existen autoridades e instituciones que tienen a su cargo velar y ejecutar lo que mandatan las leyes. Para el caso de las IE, las principales autoridades son ministerios que están reguladas dentro de la Ley Orgánica del Ejecutivo (Decreto No. 97).  Las principales instituciones rectoras para la IE son el Ministerio de Energía y Minas (MEM) y el Ministerio de Ambiente y Recursos Naturales (MARN). Ambas instituciones tienen las funciones y atribuciones que les atribuyen las leyes que regulan por sector las IE.
                            </p>

                            <p>
                                En este módulo presentamos datos agregados sobre el proceso de otorgamiento de las licencias mineras, el  régimen financiero, extracción y exportaciones de los productos mineros, datos sobre las publicaciones de las empresas transnacionales y documentos descargables sobre la IE.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <div className="governanceTimeline">
                            {/*<GovernanceTimeline />*/}
                        </div>
                    </div>
                </div>
                {/*
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <GovernanceRoyaltiesStackbar />
                    </div>
                </div>
                */}
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <GovernanceMiningRoyaltiesChart />
                    </div>
                </div>
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <GovernanceMiningVoluntaryRoyaltiesChart />
                    </div>
                </div>
                {/*
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <GovernanceCanonsStackbar />
                    </div>
                </div>
                */}
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <GovernanceMiningCanonsChart />
                    </div>
                </div>
                {/*
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <GeneralExtractionsTreemap />
                    </div>
                </div>
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <GeneralExtractionsPMTreemap />
                    </div>
                </div>
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <GovernanceExtractionsTreeMap />
                    </div>
                </div>
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <GovernanceExtractionsPMTreeMap />
                    </div>
                </div>
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <GovernanceExportationsFlowMap />
                    </div>
                </div>
                */}
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <GovernanceExportationsMap />
                    </div>
                </div>
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <EmployeesGeneralIgssChart />
                    </div>
                </div>
                <div className="section darkgray-bg fp-auto-height">
                    <div className="banner-wrapper" style={{background: "#1F2121", paddingTop: "50px"}}>
                        <div className="banner-wrapper-content">
                            <h1 className="orange title">Hidrocarburos<TooltipModal title="Hidrocarburos" text={tooltips.tooltip_petrolero} /></h1>
                        </div>
                    </div>
                </div>
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <PetrolGeneralUnifiedChart />
                    </div>
                </div>
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <PetrolGeneralProductionChart />
                    </div>
                </div>
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <PetrolGeneralRecoverableCostsChart />
                    </div>
                </div>
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <PetrolGeneralEarningsChart />
                    </div>
                </div>
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <PetrolGeneralConsumptionChart />
                    </div>
                </div>
                <div className="section darkgray-bg">
                    <div className="wrapper">
                        <PetrolGeneralExportationsChart />
                    </div>
                </div>
            </div>
        )
    }
}

import React from 'react';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  Hint,
  ChartLabel,
  DiscreteColorLegend,
} from 'react-vis';
import {isMobile} from 'react-device-detect';

import axios from 'axios';
import SourceBox from './SourceBox';
import TooltipModal from './TooltipModal';
import tooltips from './assets/tooltips';
import './colorLegend.css';

function addCommas(nStr)
{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

const colorArray = [
    "#a34d48",
    "#2b5676",
    "#3e713e",
    "#526728",
    "#711f2f",
    "#326440",
    "#692f1c",
    "#2a6c69",
    "#8d5154",
    "#32324d",
    "#856030",
    "#17994d",
    "#514522",
    "#17324d",
    "#2a425f",
    "#14432d",
    "#17224d",
    "#f7324d",
    "#37324d",
    "#77324d",
    "#1a324d",
    "#12324d",
    "#13324d",
    "#1f324d",
    "#1c324d",
    "#fbb24d",
    "#1111dd",
    "#17524d",
    "#37d24d",
    "#12232d",
    "#2722fd",
    "#17534d",
    "#37d2dd",
    "#17a14d",
    "#27b241",
    "#37c242",
    "#42d323",
    "#57e2f4",
    "#67f345",
    "#7712d6",
    "#272147",
];

function sortProperties(obj) {
  // convert object into array
    var sortable=[];
    for(var key in obj)
        if(obj.hasOwnProperty(key))
            sortable.push([key, obj[key]]); // each item is an array in format [key, value]

    // sort items by value
    sortable.sort(function(a, b)
    {
      return b[1] - a[1]; // compare numbers
    });

    return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
}

export default class GovernanceCanonsStackbar extends React.Component {
    state = {
        dataset: [],
        years: [],
        legends: [],
        highest: 0,
        vbs: [],
        value: {x:null, y:null, label: ""},
        hoveredCell: false
    }

    async getData() {
        let res = await axios.get(`https://collector.oiegt.org/api/regalias-canones`);
        this.setState({dataset: res.data});
        const dataset = res.data.filter(function(obj) {
            return obj.tipo_documento === "Canon de Superficie";
        }, {});

        const years = dataset.reduce(function(obj, v) {
            obj[v.anio] = (obj[v.anio] || 0) + 1;
            // return the updated object
            return obj;
        }, {});

        const amountsperyear = dataset.reduce(function(obj, v) {
            obj[v.anio] = (obj[v.anio] || []).concat(v);
            // return the updated object
            return obj;
        }, {});

        const amounts = Object.entries(amountsperyear);
        var sortedYears = sortProperties(years);
        var highest = sortedYears[0][1];
        var vbs = [];
        var x = Object.keys(amounts).length;
        var y = highest;
        let legend = new Set();
        for (let i = 0; i < y; i++) {
            let vbars = [];
            for (let j = 0; j < x; j++) {
                if (amounts[j][1][i] != null) {
                    vbars.push(
                        {
                            x: amounts[j][1][i]["anio"],
                            y: amounts[j][1][i]["monto"],
                            label:amounts[j][1][i]["detalle"],
                            tipo_documento:amounts[j][1][i]["tipo_documento"],
                            id: amounts[j][1][i].id_der_minero,
                            proyecto_extractivo:amounts[j][1][i]["id_der_minero"]
                        }
                    );
                    legend.add(amounts[j][1][i]["id_der_minero"])
                }
                else {
                    vbars.push(
                        {
                            x: parseInt(amounts[j][0]),
                            y: 0
                        }
                    );
                }
            }

            vbs.push(
                <VerticalBarSeries
                    key={`s_vbs_${i}`}
                    data={vbars.slice()}
                    colorType="literal"
                    onValueMouseOver={v =>
                        this.setState({hoveredCell: v.x && v.y ? v : false})
                    }
                    onValueMouseOut={v =>
                        this.setState({hoveredCell: false})
                    }
                />
            );
        }
        const legendArr = Array.from(legend)
        const formattedLegends = legendArr.map((x, index) => ({title: x, color:colorArray[index], strokeWidth:5}))
        for (let i = 0; i < vbs.length; i++){
            for (let k = 0; k < vbs[i].props.data.length;k++){
                for (let j = 0; j < formattedLegends.length; j++){
                    if (vbs[i].props.data[k].id === formattedLegends[j].title){
                        vbs[i].props.data[k].color = formattedLegends[j].color;
                    }
                }
            }
        }

        this.setState(
            {
                years: years,
                highest: highest,
                vbs: vbs,
                legends: formattedLegends,
            }
        );
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const {hoveredCell} = this.state;
        let myWidth = 0;
        let myHeight = 0;
        if (isMobile) {
            myWidth = window.innerWidth - 80;
            myHeight = window.innerHeight / 2;
        }
        else {
            myWidth = window.innerWidth - 200;
            myHeight = window.innerHeight - 250;
        }
        return (
            <div>
                <div className="project-module-header">
                <h1 className="section-title white">Cánones</h1>
                <TooltipModal title="Cánones" text={tooltips.tooltip_canones} />
                </div>

                <XYPlot
                    margin={{left: 100, top: 50, bottom: 50}}
                    width={myWidth}
                    height={myHeight}
                    stackBy="y"
                    xType="ordinal"
                    className="vis"
                >
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    {/* Cambios a los AXES para tener Labels VER DOC DE ChartLabel*/}
                    <XAxis />
                    <YAxis />
                    <ChartLabel
                    text="Año"
                    className="alt-x-label"
                    includeMargin={true}
                    xPercent={0.5}
                    yPercent={0.77}
                    style={{
                        textAnchor: "start",
                        fontWeight: "bold",
                        fontSize: 16,
                        }}
                    />
                    <ChartLabel
                    text="Quetzales"
                    className="alt-y-label"
                    includeMargin={true}
                    xPercent={0.02}
                    yPercent={0.3}
                    style={{
                    textAnchor: "start",
                    fontWeight: "bold",
                    transform: 'rotate(-90)',
                    fontSize: 16,
                    }}
                    />
                    {this.state.vbs}
                    {hoveredCell ? (
                        <Hint
                            value={hoveredCell}
                            align={{horizontal: 'auto', vertical: 'auto'}}
                        >
                            <div className="hint">
                                <h5>{hoveredCell.label}</h5>
                                <p>{hoveredCell.proyecto_extractivo}</p>
                                <p>
                                    {hoveredCell.id_der_minero}
                                </p>
                                <p>
                                    Q. <span>{addCommas((hoveredCell.y - (hoveredCell.y0 != null ? hoveredCell.y0 : 0 )).toFixed(2))}</span>
                                </p>
                            </div>
                        </Hint>
                    ) : null}
                </XYPlot>
                <DiscreteColorLegend
                    className="colorLegend"
                    width={myWidth}
                    items={this.state.legends}
                    orientation="vertical"
                />
                <SourceBox source={tooltips.source_mem} />
            </div>
        );
    }
}

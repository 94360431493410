import React, { useMemo, useCallback } from 'react';
import { AreaClosed, Line, Bar } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { GridRows, GridColumns } from '@visx/grid';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleTime, scaleLinear } from '@visx/scale';
import { withTooltip, Tooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { localPoint } from '@visx/event';
import { LinearGradient } from '@visx/gradient';
import { max, extent, bisector } from 'd3-array';
import { timeFormat } from 'd3-time-format';

export const background = '#3b6978';
export const background2 = '#964d18';
export const accentColor = '#2eff8b';
export const accentColorDark = '#75daad';
const tooltipStyles = {
  ...defaultStyles,
  background,
  border: '1px solid white',
  color: 'white',
};

// util
const formatDate = timeFormat("%b %d, '%y");

const chartWithTooltip = withTooltip (
    ({
        data = this.props.data,
        width = this.props.width,
        height = this.props.height,
        margin = { top: 20, right: 40, bottom: 30, left: 60 },
        showTooltip,
        hideTooltip,
        tooltipData,
        tooltipTop = 0,
        tooltipLeft = 0,
    }) => {

        const stock = data;

        if (data.length === 0) return (<div></div>);

        const getDate = (d: data) => new Date(d.Date);
        // const getDate = new Date(Date);
        const getStockValue = (d: data) => d.Close;
        const bisectDate = bisector(d => new Date(d.Date)).left;

        if (width < 10) return null;

        // bounds
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        // scales
        const dateScale = useMemo(
            () =>
            scaleTime({
                range: [margin.left, innerWidth + margin.left],
                domain: extent(stock, getDate),
            }),
            [innerWidth, margin.left],
        );

        const stockValueScale = useMemo(
            () =>
            scaleLinear({
                range: [innerHeight + margin.top, margin.top],
                domain: [0, (max(stock, getStockValue) || 0) + innerHeight / 3],
                nice: true,
            }),
            [margin.top, innerHeight],
        );

        // tooltip handler
        const handleTooltip = useCallback(
            (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
                const { x } = localPoint(event) || { x: 0 };
                const x0 = dateScale.invert(x);
                const x00 = dateScale(x);
                const index = bisectDate(stock, x0, 1);
                const d0 = stock[index - 1];
                const d1 = stock[index];
                let d = d0;
                if (d1 && getDate(d1)) {
                    d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
                }
                showTooltip({
                    tooltipData: d,
                    tooltipLeft: x,
                    tooltipTop: stockValueScale(getStockValue(d)),
                });
            },
            [showTooltip, stockValueScale, dateScale],
        );

        return (
            <div id="stockViewer">
                <svg width={width} height={height}>
                    <rect
                        x={0}
                        y={0}
                        width={width}
                        height={height}
                        fill="url(#area-background-gradient)"
                        rx={14}
                    />
                    <LinearGradient id="area-background-gradient" from={background} to={background2} />
                    <LinearGradient id="area-gradient" from={accentColor} to={accentColor} toOpacity={0.1} />
                    <GridRows
                        left={margin.left}
                        scale={stockValueScale}
                        width={innerWidth}
                        strokeDasharray="1,3"
                        stroke={accentColor}
                        strokeOpacity={0}
                        pointerEvents="none"
                    />
                    <GridColumns
                        top={margin.top}
                        scale={dateScale}
                        height={innerHeight}
                        strokeDasharray="1,3"
                        stroke={accentColor}
                        strokeOpacity={0.2}
                        pointerEvents="none"
                    />
                    <AreaClosed
                        data={stock}
                        x={d => dateScale(getDate(d)) ?? 0}
                        y={d => stockValueScale(getStockValue(d)) ?? 0}
                        yScale={stockValueScale}
                        strokeWidth={1}
                        stroke="url(#area-gradient)"
                        fill="url(#area-gradient)"
                        curve={curveMonotoneX}
                    />
                    <AxisLeft
                        label={"USD"}
                        left={margin.left}
                        scale={stockValueScale}
                        stroke={"#FFF"}
                        tickStroke={"#FFF"}
                        tickLabelProps={() => ({
                          fill: "#FFF",
                          fontSize: 11,
                          textAnchor: 'end',
                          dy: '0.33em',
                        })}
                      />
                      <AxisBottom
                        top={innerHeight + margin.top}
                        scale={dateScale}
                        stroke={"#FFF"}
                        tickStroke={"#FFF"}
                        tickLabelProps={() => ({
                          fill: "#FFF",
                          fontSize: 11,
                          textAnchor: 'middle',
                        })}
                      />
                    <Bar
                        x={margin.left}
                        y={margin.top}
                        width={innerWidth}
                        height={innerHeight}
                        fill="transparent"
                        rx={14}
                        onTouchStart={handleTooltip}
                        onTouchMove={handleTooltip}
                        onMouseMove={handleTooltip}
                        onMouseLeave={() => hideTooltip()}
                    />
                    {tooltipData && (
                        <g>
                            <Line
                                from={{ x: tooltipLeft, y: margin.top }}
                                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                                stroke={accentColorDark}
                                strokeWidth={2}
                                pointerEvents="none"
                                strokeDasharray="5,2"
                            />
                            <circle
                                cx={tooltipLeft}
                                cy={tooltipTop + 1}
                                r={4}
                                fill="black"
                                fillOpacity={0.1}
                                stroke="black"
                                strokeOpacity={0.1}
                                strokeWidth={2}
                                pointerEvents="none"
                            />
                            <circle
                                cx={tooltipLeft}
                                cy={tooltipTop}
                                r={4}
                                fill={accentColorDark}
                                stroke="white"
                                strokeWidth={2}
                                pointerEvents="none"
                            />
                        </g>
                    )}
                </svg>
                {tooltipData && (
                    <div>
                        <TooltipWithBounds
                            key={Math.random()}
                            top={tooltipTop - 12}
                            left={tooltipLeft + 12}
                            style={tooltipStyles}
                        >
                            <p>{formatDate(getDate(tooltipData))}</p>
                            <p><strong>{`$${getStockValue(tooltipData)}`}</strong></p>
                        </TooltipWithBounds>
                    </div>
                )}
                <div><p><small>Fuente: Yahoo Finance</small></p></div>
            </div>
        );
    }
);

export default chartWithTooltip;

import React from 'react';
import axios from 'axios';
import './ProjectMinerals.css';
import {ReactComponent as MineralColor} from './assets/icons/icon-mineralcolor.svg';

// Directly importing the minified bootstrap css to avoid all the painful
// steps it will take otherwise to get it to work.
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

export default class ProjectMinerals extends React.Component {
    state = {
        dataset: [],
        cuencas: []
    };

    async getData() {
        let res = await axios.get(`https://collector.oiegt.org/api/proyecto-extractivo_/${this.props.projectId}`);
        let minerals = [];
        let mineralsData = Object.entries(res.data.mineral);

        if (res.data.mineral != null) {
            for (let i = 0; i < mineralsData.length; i++) {
                if (mineralsData[i][1]) {
                    minerals.push(<p key={`se_vbs_${i}`}><span className={mineralsData[i][0]}><MineralColor /></span> { mineralsData[i][0] }</p>);
                }
            }
        }

        this.setState({
            dataset: res.data,
            minerals: minerals
        });
    }

    componentDidMount() {
        this.getData();
    }

    render() {

        let isSolicitud = this.props.isSolicitud;
        const minerals = this.state.minerals;

        return (
            <div className="projectMineralsWrapper">
                <div className="projectMinerals">
                    <div className="projectMineralsHeader">
                        <h1>Minerales metálicos</h1>
                        {isSolicitud ? <h2>Producto minero solicitado</h2> : <h2>Producto minero autorizado</h2>}
                    </div>
                    <div className="project-minerals-content">
                        { minerals }
                    </div>
                </div>
            </div>
        );
    }
}

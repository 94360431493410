import React from 'react';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  HorizontalBarSeries,
  Hint,
  ChartLabel,
  DiscreteColorLegend,
} from 'react-vis';

import Select from "react-select";
import axios from 'axios';
import SourceBox from './SourceBox'
import tooltips from './assets/tooltips'
import TooltipModal from "./TooltipModal"
import {isMobile} from 'react-device-detect';
import './colorLegend.css';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : '#CCC',
        backgroundColor: '#1F2121'
    }),
    control: styles => ({
        ...styles,
        color: 'white',
        backgroundColor: '#1F2121',
        width: '220px'
    }),
    menu: styles => ({
        ...styles,
        backgroundColor: '#1F2121',
        border: '1px solid white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'white'
    })
}

const colorArray = [
    "#a34d48",
    "#2b5676",
    "#3e713e",
    "#526728",
    "#711f2f",
    "#326440",
    "#692f1c",
    "#2a6c69",
    "#8d5154",
    "#17324d",
    "#856030",
    "#17324d",
    "#514522",
    "#17324d",
    "#2a425f",
    "#17324d",
    "#17224d",
    "#f7324d",
    "#37324d",
    "#77324d",
    "#1a324d",
    "#12324d",
    "#13324d",
    "#1f324d",
    "#1c324d",
    "#fbb24d",
    "#27224d",
    "#17524d",
    "#37d24d",
    "#17324d"
];

function sortProperties(obj) {
  // convert object into array
    var sortable=[];
    for(var key in obj)
        if(obj.hasOwnProperty(key))
            sortable.push([key, obj[key]]); // each item is an array in format [key, value]

    // sort items by value
    sortable.sort(function(a, b)
    {
      return b[1] - a[1]; // compare numbers
    });

    return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
}

export default class LicensingHorizontalStackbar extends React.Component {
    state = {
        dataset: [],
        years: [],
        highest: 0,
        vbs: [],
        licensingOficialList: [],
        licensingRealList: [],
        printables: [],
        value: {x:null, y:null, label: ""},
        hoveredCell: false,
        legends: [],
        oficialVbars: [],
        realVbars: []
    }

    async getData() {
        let res = await axios.get(`https://collector.oiegt.org/api/otorgamiento/${this.props.projectId}`);
        this.setState({dataset: res.data});

        let types = [];

        for (let key in res.data[0]) {
            types.push(key);
        }

        let selectTypes = [];

        selectTypes = types.map(function(obj) {
            const options = {};

            options.value = obj.toString();
            options.label = obj.toString();

            return options;
        });

        selectTypes.splice(0, 0, {value: "all", label: "Todos"});

        let vbs = this.filterType(res.data[0], "all");

        let printables = [];

        for (let key in res.data[0]) {
            let real = "";
            printables.push(
                <div key={`prtbl_${key}`}>
                    <h2>{key}</h2>
                    <p>Oficial: {res.data[0][key].oficial ? res.data[0][key].oficial : "No disponible"}</p>
                    <p>Real: {res.data[0][key].real ? res.data[0][key].real : "No disponible"}</p>
                </div>
            );
        }

        this.setState({
            dataset: res,
            printables: printables,
            vbs: vbs,
            selectTypes: selectTypes,
            selectedType: selectTypes[0]
        });

    }

    componentDidMount() {
        this.getData();
    }

    filterType(array, type) {
        let vbs = [];
        let legend = new Set();
        if (type === "all") {
            let counter = 0;

            for (let key in array) {
                if (array.hasOwnProperty(key)) {
                    vbs.push(
                        <HorizontalBarSeries
                            key={`s_vbs_oficial_${counter}`}
                            colorType="literal"
                            data={[
                                {
                                    y: 'Oficial',
                                    x: array[key].oficial,
                                    label: key,
                                    color: ""
                                }
                            ]}
                            onValueMouseOver={v =>
                                this.setState({hoveredCell: v})
                            }
                            onValueMouseOut={v =>
                                this.setState({hoveredCell: false})
                            }
                        />
                    );
                    vbs.push(
                        <HorizontalBarSeries
                            key={`s_vbs_real_${counter}`}
                            colorType="literal"
                            data={[
                                {
                                    y: 'Real',
                                    x: array[key].real,
                                    label: key,
                                    color: ""
                                }
                            ]}
                            onValueMouseOver={v =>
                                this.setState({hoveredCell: v})
                            }
                            onValueMouseOut={v =>
                                this.setState({hoveredCell: false})
                            }
                        />
                    );
                    legend.add(key);
                }
                counter++;
            }
        }
        else {
            let counter = 0;

            for (let key in array) {
                if (key === type) {
                    vbs.push(
                        <HorizontalBarSeries
                            key={`s_vbs_oficial_${counter}`}
                            colorType="literal"
                            data={[
                                {
                                    y: 'Oficial',
                                    x: array[key].oficial,
                                    label: key,
                                    color: colorArray[counter]
                                }
                            ]}
                            onValueMouseOver={v =>
                                this.setState({hoveredCell: v})
                            }
                            onValueMouseOut={v =>
                                this.setState({hoveredCell: false})
                            }
                        />
                    );
                    vbs.push(
                        <HorizontalBarSeries
                            key={`s_vbs_real_${counter}`}
                            colorType="literal"
                            data={[
                                {
                                    y: 'Real',
                                    x: array[key].real,
                                    label: key,
                                    color: colorArray[counter]
                                }
                            ]}
                            onValueMouseOver={v =>
                                this.setState({hoveredCell: v})
                            }
                            onValueMouseOut={v =>
                                this.setState({hoveredCell: false})
                            }
                        />
                    );
                }
                counter++;
            }
        }

        const legendArr = Array.from(legend);
        const formattedLegends = legendArr.map((x, index) => ({title: x, color:colorArray[index], strokeWidth:5}));

        for (let i = 0; i < vbs.length; i++) {
            for (let k = 0; k < vbs[i].props.data.length; k++) {
                for (let j = 0; j < formattedLegends.length; j++) {
                    if (vbs[i].props.data[k].label === formattedLegends[j].title) {
                        vbs[i].props.data[k].color = formattedLegends[j].color;
                    }
                }
            }
        }

        this.setState({
            legends: formattedLegends
        });

        return vbs;
    }

    changeSelect(e) {
        let dataset = this.state.dataset;
        let type = e.value;
        const separatedType = this.filterType(dataset.data[0], type);
        this.setState({
            selectedType: e,
            vbs: separatedType
        })
    }

    render() {
        let legendWidth = 0;
        if (isMobile) {
            legendWidth = window.innerWidth;
        }
        else {
            legendWidth = (window.innerWidth - 100);

        }

        const {selectedType} = this.state;
        const {hoveredCell} = this.state;
        const {vbs} = this.state;

        return (
            <div>
                <div className="project-module-header">
                    <h1 className="section-title white">Proceso de otorgamiento de licencia</h1>
                    <TooltipModal title="Proceso de otorgamiento de licencia" text={tooltips.tooltip_otorgamiento} />
                    <Select
                        className="project-module-select"
                        styles={customStyles}
                        options={this.state.selectTypes}
                        placeholder={"Proceso"}
                        value={selectedType}
                        onChange={e => this.changeSelect(e)}
                    />
                </div>
                {vbs.length > 0 ?
                    (
                        <XYPlot
                            margin={{left: 100, top: 50, bottom: 50}}
                            width={window.innerWidth - 200}
                            height={window.innerHeight - 450}
                            yType="ordinal"
                            stackBy="x"
                            className="vis"
                        >
                            <VerticalGridLines />
                            <HorizontalGridLines />
                            {/* Cambios a los AXES para tener Labels VER DOC DE ChartLabel*/}
                            <XAxis />
                            <YAxis />
                            <ChartLabel
                            text="Tipo de proceso"
                            className="alt-y-label"
                            includeMargin={true}
                            xPercent={0.015}
                            yPercent={0.3}
                            style={{
                            textAnchor: "start",
                            fontWeight: "bold",
                            transform: 'rotate(-90)',
                            fontSize: 16,
                            }}
                            />

                            {vbs}

                            {hoveredCell ? (
                                <Hint
                                    value={hoveredCell}
                                    align={{horizontal: 'auto', vertical: 'auto'}}
                                >
                                    <div className="hint">
                                        <h5>{hoveredCell.label}</h5>
                                        <p>
                                            <span>{hoveredCell.x ? hoveredCell.x0 ? hoveredCell.x - hoveredCell.x0 : hoveredCell.x : hoveredCell.x0}</span> Días
                                        </p>
                                    </div>
                                </Hint>
                            ) : null}
                        </XYPlot>
                    ) :
                    (
                        <div className="still-looking">
                            <img src={require('./assets/images/oie-stilllooking.svg')} />
                        </div>
                    )
                }
                {/* Agregando color Legend*/}
                <DiscreteColorLegend
                    className="colorLegend"
                    width={legendWidth}
                    items={this.state.legends}
                    orientation="vertical"
                />
                <SourceBox source={tooltips.source_licensing} />
                <div className="printables">
                    {this.state.printables}
                </div>
            </div>
        );
    }
}

import React, { useState }  from 'react';
import { loadModules } from 'esri-loader';
import {isMobile} from 'react-device-detect';
import axios from 'axios';
import styles from './ExportationsMap.module.css';

import Select from "react-select";
import SourceBox from "./SourceBox";
import TooltipModal from "./TooltipModal"
import tooltips from './assets/tooltips';

import { 
    extendedPalette
} from './Styles';

import { 
    addCommas
} from './Tools';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : '#CCC',
        backgroundColor: '#1F2121'
    }),
    control: styles => ({
        ...styles,
        color: 'white',
        backgroundColor: '#1F2121',
        width: '120px'
    }),
    menu: styles => ({
        ...styles,
        backgroundColor: '#1F2121',
        border: '1px solid white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'white'
    })
}

const rounded = num => {
    if (num > 1000000000) {
        return Math.round(num / 100000000) / 10 + "Bn";
    } else if (num > 1000000) {
        return Math.round(num / 100000) / 10 + "M";
    } else {
        return Math.round(num / 100) / 10 + "K";
    }
};

export default class ExportationsMap extends React.Component {

    state = {
        dataset: [],
        printables: []
        // locale: "es"
    }

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.sliderRef = React.createRef();
    }

    async getData() {

        let res = await axios.get(`https://collector.oiegt.org/api/exportaciones/${this.props.projectId}`);

        Promise.all([res]).then((responses) => {

            const dataset = responses[0].data.filter(function(obj) {
                if (obj.export_procesado.length > 0 || obj.export_bruto.length > 0) {
                    return obj;
                }
                // return obj.anio === year;
            }, {});

            const years = dataset.map(function(obj) {
                return obj.anio;
            });

            const selectYears = years.map(function(obj) {
                const options = {};

                options.value = obj.toString();
                options.label = obj.toString();

                return options;
            });

            const separatedYear = this.filterYear(dataset, years[0]);

            // this.drawMap(separatedYear);

            var printables = [];

            for (var i = 0; i < responses[0].data.length; i++) {
                for (var j = 0; j < responses[0].data[i].export_bruto.length; j++) {
                    printables.push(
                        <div>
                            <h2>Exportación en bruto</h2>
                            <p>Año: {responses[0].data[i].anio}</p>
                            <p>Producto minero: {responses[0].data[i].export_bruto[j].producto_minero}</p>
                            <p>Cantidad vendida: {responses[0].data[i].export_bruto[j].cantidad_vendida}</p>
                            <p>Cantidad vendida dimensional: {responses[0].data[i].export_bruto[j].cantidad_vendida_dimensional}</p>
                            <p>Valor quetzales: Q. {responses[0].data[i].export_bruto[j].valor_quetzales ? addCommas(responses[0].data[i].export_bruto[j].valor_quetzales.toFixed(2)) : "No disponible"}</p>
                            <p>Costo unitario: Q. {responses[0].data[i].export_bruto[j].costo_unitario ? addCommas(responses[0].data[i].export_bruto[j].costo_unitario.toFixed(2)) : "No disponible"}</p>
                            <p>Destino: {responses[0].data[i].export_bruto[j].destino[0] ? responses[0].data[i].export_bruto[j].destino[0].name : "No disponible"}</p>
                        </div>
                    );
                }
                for (var j = 0; j < responses[0].data[i].export_procesado.length; j++) {
                    printables.push(
                        <div key={`prtbl_${i}_${j}`}>
                            <h2>Exportación procesada</h2>
                            <p>Año: {responses[0].data[i].anio}</p>
                            <p>Producto minero: {responses[0].data[i].export_procesado[j].producto_minero}</p>
                            <p>Cantidad vendida: {responses[0].data[i].export_procesado[j].cantidad_vendida}</p>
                            <p>Cantidad vendida dimensional: {responses[0].data[i].export_procesado[j].cantidad_vendida_dimensional}</p>
                            <p>Valor quetzales: Q. {addCommas(responses[0].data[i].export_procesado[j].valor_quetzales.toFixed(2))}</p>
                            <p>Costo unitario: Q. {addCommas(responses[0].data[i].export_procesado[j].costo_unitario.toFixed(2))}</p>
                            <p>Destino: {responses[0].data[i].export_procesado[j].destino[0] ? responses[0].data[i].export_procesado[j].destino[0].name : "No disponible"}</p>
                        </div>
                    );
                }
            }

            this.setState({
                dataset: dataset,
                selectYears: selectYears,
                selectedYear: selectYears[0],
                separatedYear: separatedYear,
                printables: printables
            });

            // lazy load the required ArcGIS API for JavaScript modules and CSS
            loadModules([
                "esri/Map",
                "esri/views/ui/DefaultUI",
                "esri/views/MapView",
                "esri/layers/FeatureLayer",
                "esri/symbols/PictureMarkerSymbol",
                "esri/Graphic",
                "esri/geometry/Polyline",
                "esri/geometry/geometryEngine",
                "esri/geometry/support/geodesicUtils",
                "esri/layers/GraphicsLayer",
                "esri/config",
                "dojo/domReady!"
            ], { css: true })
            .then(([
                ArcGISMap,
                DefaultUI,
                MapView,
                FeatureLayer,
                PictureMarkerSymbol,
                Graphic,
                GraphicsLayer,
                Polyline,
                geometryEngine,
                geodesicUtils,
                esriConfig
            ]) => {
                esriConfig.request.corsDetection = false;

                this.map = new ArcGISMap({
                    basemap: 'gray'
                });

                this.view = new MapView({
                    container: this.mapRef.current,
                    map: this.map,
                    center: [-89.522713,16.628434], //Longitude, latitude
                    zoom: 2,
                    navigation: {
                        mouseWheelZoomEnabled: false,
                        browserTouchPanEnabled: false
                    }
                });

                this.spatialReference = this.view.spatialReference;

                this.view.ui.move("zoom", "bottom-right");

                // this.graphicsLayer = new GraphicsLayer();
                // this.map.add(this.graphicsLayer);

                this.createLines(separatedYear);

            });

        });

    }

    changeSelect(e) {

        let dataset = this.state.dataset;
        let year = parseInt(e.value);
        const separatedYear = this.filterYear(dataset, year);
        this.map.remove(this.graphicsLayer);
        this.createLines(separatedYear);
        this.setState({
            selectedYear: e,
            separatedYear: separatedYear
        })
    }

    filterYear(array, year) {
        const yearObj = array.filter(function(obj) {
            return obj.anio === year;
        }, {});

        return yearObj;
    }

    createLines(dataset) {

        loadModules([
            "esri/Map",
            "esri/views/ui/DefaultUI",
            "esri/views/MapView",
            "esri/layers/FeatureLayer",
            "esri/symbols/PictureMarkerSymbol",
            "esri/Graphic",
            "esri/layers/GraphicsLayer",
            "esri/geometry/Polyline",
            "esri/geometry/geometryEngine",
            "esri/geometry/support/geodesicUtils",
            "esri/config"
        ], { css: true })
        .then(([
            ArcGISMap,
            DefaultUI,
            MapView,
            FeatureLayer,
            PictureMarkerSymbol,
            Graphic,
            GraphicsLayer,
            Polyline,
            geometryEngine,
            geodesicUtils,
            esriConfig
        ]) => {

            this.graphicsLayer = new GraphicsLayer();

            const simpleLineSymbol = {
               type: "simple-line",
               width: 2
            };

            if (dataset[0].export_procesado.length > 0) {
                for (let i = 0; i < dataset[0].export_procesado.length; i++) {

                    let exportation = dataset[0].export_procesado[i];

                    let producto_minero = exportation.producto_minero;
                    let cantidad_vendida = rounded(exportation.cantidad_vendida) + " " + exportation.cantidad_vendida_dimensional;
                    let valor_quetzales = exportation.valor_quetzales;

                    let destino = "No Disponible";

                    if (exportation.destino[0]) {
                        destino = exportation.destino[0].name;
                    }

                    let attributes = {
                        "producto_minero": producto_minero,
                        "cantidad_vendida": cantidad_vendida,
                        "valor_quetzales": addCommas(valor_quetzales),
                        "destino": destino
                    }

                    simpleLineSymbol.color = extendedPalette[i];

                    if (exportation.destino[0]) {
                        let polyline = new Polyline({
                            paths: [
                                [-89.522713,14.628434], //Longitude, latitude
                                [exportation.destino[0].longitude, exportation.destino[0].latitude + (i * 0.2)] //Longitude, latitude
                            ],
                            spatialReference: this.spatialReference
                        });

                        let denseGeometry = geometryEngine.geodesicDensify(polyline, 10000)

                        let polylineGraphic = new Graphic({
                            attributes: attributes,
                            geometry: denseGeometry,
                            symbol: simpleLineSymbol,
                            popupTemplate: {
                                // autocasts as new PopupTemplate()
                                title: "Exportaciones",
                                content: [
                                    {
                                        type: "fields",
                                        fieldInfos: [
                                            {
                                                fieldName: "producto_minero",
                                                label: "Producto Minero"
                                            },
                                            {
                                                fieldName: "cantidad_vendida",
                                                label: "Cantidad Vendida"
                                            },
                                            {
                                                fieldName: "valor_quetzales",
                                                label: "Valor (Q)"
                                            },
                                            {
                                                fieldName: "destino",
                                                label: "Destino"
                                            }
                                        ]
                                    }
                                ]
                            }
                        });

                        this.graphicsLayer.add(polylineGraphic);
                    }
                }
            }

            if (dataset[0].export_bruto.length > 0) {
                for (let i = 0; i < dataset[0].export_bruto.length; i++) {

                    let destino = "No disponible";

                    let exportation = dataset[0].export_bruto[i];

                    let producto_minero = exportation.producto_minero;
                    let cantidad_vendida = rounded(exportation.cantidad_vendida) + " " + exportation.cantidad_vendida_dimensional;
                    let valor_quetzales = exportation.valor_quetzales;
                    if (exportation.destino[0]) {
                        destino = exportation.destino[0].name;
                    }

                    let attributes = {
                        "producto_minero": producto_minero,
                        "cantidad_vendida": cantidad_vendida,
                        "valor_quetzales": addCommas(valor_quetzales),
                        "destino": destino
                    }

                    simpleLineSymbol.color = extendedPalette[i];

                    if (exportation.destino[0]) {
                        let polyline = new Polyline({
                            paths: [
                                [-89.522713,14.628434], //Longitude, latitude
                                [exportation.destino[0].longitude, exportation.destino[0].latitude + (i * 0.2)] //Longitude, latitude
                            ],
                            spatialReference: this.spatialReference
                        });

                        let denseGeometry = geometryEngine.geodesicDensify(polyline, 10000)

                        let polylineGraphic = new Graphic({
                            attributes: attributes,
                            geometry: denseGeometry,
                            symbol: simpleLineSymbol,
                            popupTemplate: {
                                // autocasts as new PopupTemplate()
                                title: "Exportaciones",
                                content: [
                                    {
                                        type: "fields",
                                        fieldInfos: [
                                            {
                                                fieldName: "producto_minero",
                                                label: "Producto Minero"
                                            },
                                            {
                                                fieldName: "cantidad_vendida",
                                                label: "Cantidad Vendida"
                                            },
                                            {
                                                fieldName: "valor_quetzales",
                                                label: "Valor (Q)"
                                            },
                                            {
                                                fieldName: "destino",
                                                label: "Destino"
                                            }
                                        ]
                                    }
                                ]
                            }
                        });

                        this.graphicsLayer.add(polylineGraphic);
                    }
                }
            }

            this.map.add(this.graphicsLayer);

        });
    }

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
        if (this.view) {
            // destroy the map view
            this.view.container = null;
        }
    }

    render() {
        let myWidth = 0;
        let myHeight = 0;
        let legendWidth = 0;

        if (isMobile) {
            myWidth = (window.innerWidth - 40);
            myHeight = (window.innerWidth - 40);
            legendWidth = window.innerWidth - 40;
        }
        else {
            myWidth = window.innerWidth - 80;
            myHeight = (window.innerWidth - 100) / 2;
            legendWidth = (window.innerWidth - 100) / 2;
        }

        const value = this.state.selectedYear;

        return (
            <div style={{position: 'relative'}}>
                <div className="project-module-header">
                    <h1 className="section-title white">Exportaciones</h1>
                    <TooltipModal title="Exportaciones" text={tooltips.tooltip_exportaciones} />
                    <Select
                        className="project-module-select"
                        styles={customStyles}
                        options={this.state.selectYears}
                        placeholder={"Año"}
                        value={value}
                        onChange={e => this.changeSelect(e)}
                    />
                </div>
                <div className={styles.webmap} ref={this.mapRef} />
                <SourceBox source={tooltips.source_mem} />
                <div className="printables">
                    {this.state.printables}
                </div>
            </div>
        );
    }
}

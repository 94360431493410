import React from 'react';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  Hint,
  ChartLabel,
  DiscreteColorLegend,
} from 'react-vis';
import {isMobile} from 'react-device-detect';
import axios from 'axios';
import SourceBox from './SourceBox';
import TooltipModal from './TooltipModal';
import tooltips from './assets/tooltips';


function addCommas(nStr)
{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}


const colorArray = [
    "#004d40",
    "#00695c",
    "#00796b",
    "#00897b",
    "#009688",
    "#4db6ac",
    "#80cbc4",
    "#b2dfdb",
    "#e0f2f1",
    "#004d40",
    "#00695c",
    "#00796b",
    "#00897b",
    "#009688",
    "#4db6ac",
    "#80cbc4",
    "#b2dfdb",
    "#e0f2f1",
    "#004d40",
    "#00695c",
    "#00796b",
    "#00897b",
    "#009688",
    "#4db6ac",
    "#80cbc4",
    "#b2dfdb",
    "#e0f2f1",
];
const VolunteerColorArray = [
    "#e65100",
    "#ef6c00",
    "#f57c00",
    "#fb8c00",
    "#ff9800",
    "#ffa726",
    "#ffb74d",
    "#ffcc80",
    "#ffe0b2",
    "#e65100",
    "#ef6c00",
    "#f57c00",
    "#fb8c00",
    "#ff9800",
    "#ffa726",
    "#ffb74d",
    "#ffcc80",
    "#ffe0b2",
    "#e65100",
    "#ef6c00",
    "#f57c00",
    "#fb8c00",
    "#ff9800",
    "#ffa726",
    "#ffb74d",
    "#ffcc80",
    "#ffe0b2",
];

function sortProperties(obj) {
  // convert object into array
    var sortable=[];
    for(var key in obj)
        if(obj.hasOwnProperty(key))
            sortable.push([key, obj[key]]); // each item is an array in format [key, value]

    // sort items by value
    sortable.sort(function(a, b)
    {
      return b[1] - a[1]; // compare numbers
    });

    return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
}

export default class GovernanceRoyaltiesStackbar extends React.Component {
    state = {
        dataset: [],
        years: [],
        highest: 0,
        vbs: [],
        value: {x:null, y:null, label: ""},
        hoveredCell: false,
        legends: [],
    }

    async getData() {
        let res = await axios.get(`https://collector.oiegt.org/api/regalias-canones`);
        this.setState({dataset: res.data});
        const dataset = res.data.filter(function(obj) {
            return obj.tipo_documento !== "Canon de Superficie";
        }, {});

        const years = dataset.reduce(function(obj, v) {
            obj[v.anio] = (obj[v.anio] || 0) + 1;
            // return the updated object
            return obj;
        }, {});

        const yearSubArrays = dataset.reduce( (acc, obj) => {
            acc[obj.anio] = acc[obj.anio] || [];
            acc[obj.anio].push(obj);
            return acc;
        }, {});

        const amountsperyear = dataset.reduce(function(obj, v) {
            obj[v.anio] = (obj[v.anio] || []).concat(v);
            // return the updated object
            return obj;
        }, {});

        const amounts = Object.entries(amountsperyear);

        var sortedYears = sortProperties(years);

        var highest = 0;

        if (sortedYears.length > 0) {
            highest = sortedYears[0][1];
        }

        var vbs = [];
        var x = Object.keys(amounts).length;
        var y = highest;
        const keys = Object.keys(yearSubArrays)

        for (const key of keys){
            let Rvbs = []
            let Vvbs = []

            for (let j = 0; j < yearSubArrays[key].length; j++){
                if (yearSubArrays[key][j] != null){
                    if (yearSubArrays[key][j]["tipo_documento"] === "Regalias"){
                        Rvbs.push(
                            <VerticalBarSeries
                                cluster={yearSubArrays[key][j]["tipo_documento"]}
                                colorType="literal"
                                key={`sr_Rvbs_${key}_${j}`}
                                data={
                                    [{
                                        x: yearSubArrays[key][j]["anio"],
                                        y: yearSubArrays[key][j]["monto"],
                                        label:yearSubArrays[key][j]["detalle"],
                                        tipo_documento:yearSubArrays[key][j]["tipo_documento"],
                                        // tipo_documento: "Regalías",
                                        color: ""
                                    }]
                                }
                                onValueMouseOver={v =>
                                    this.setState({hoveredCell: v.x && v.y ? v : false})
                                }
                                onValueMouseOut={v =>
                                    this.setState({hoveredCell: false})
                                }
                            />
                        );
                    } else {
                        Vvbs.push(
                            <VerticalBarSeries
                                cluster={yearSubArrays[key][j]["tipo_documento"]}
                                colorType="literal"
                                key={`sr_Vvbs_${key}_${j}`}
                                data={
                                    [{
                                        x: yearSubArrays[key][j]["anio"],
                                        y: yearSubArrays[key][j]["monto"],
                                        label:yearSubArrays[key][j]["detalle"],
                                        tipo_documento:yearSubArrays[key][j]["tipo_documento"],
                                        color: ""
                                    }]
                                }
                                onValueMouseOver={v =>
                                    this.setState({hoveredCell: v.x && v.y ? v : false})
                                }
                                onValueMouseOut={v =>
                                    this.setState({hoveredCell: false})
                                }
                            />
                        );
                    }

                }
            }
            if(Rvbs.length >= 0){
                for(let i = 0; i < Rvbs.length; i++) {
                    Rvbs[i].props.data[0].color = colorArray[i]
                    vbs.push(Rvbs[i])
                }
            }
            if(Vvbs.length >= 0){
                for(let i = 0; i < Vvbs.length; i++) {
                    Vvbs[i].props.data[0].color = VolunteerColorArray[i]
                    vbs.push(Vvbs[i])
                }
            }
        }

        const formattedLegends = [{title: "Regalías", color:colorArray[5], strokeWidth:10},
        {title: "Regalías Voluntarias", color:VolunteerColorArray[5], strokeWidth:10}]
        this.setState({
            years: years,
            highest: highest,
            vbs: vbs,
            legends: formattedLegends
        });

    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const {hoveredCell} = this.state;
        let myWidth = 0;
        let myHeight = 0;

        if (isMobile) {
            myWidth = window.innerWidth - 80;
            myHeight = window.innerHeight / 2;
        }
        else {
            myWidth = window.innerWidth - 200;
            myHeight = window.innerHeight - 250;
        }
        return (
            <div>
            <div className="project-module-header">
            <h1 className="section-title white">Regalías</h1>
            <TooltipModal title="Regalías y Regalías Voluntarias" text={tooltips.tooltip_regalias} extraText1={tooltips.tooltips_regalias_voluntarias} />
            </div>
                <XYPlot
                    margin={{left: 100, top: 50, bottom: 50}}
                    width={myWidth}
                    height={myHeight}
                    stackBy="y"
                    xType="ordinal"
                    className="vis"
                >
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    {/* Cambios a los AXES para tener Labels VER DOC DE ChartLabel*/}
                    <XAxis />
                    <YAxis />
                    <ChartLabel
                    text="Año"
                    className="alt-x-label"
                    includeMargin={true}
                    xPercent={0.5}
                    yPercent={0.73}
                    style={{
                        textAnchor: "start",
                        fontWeight: "bold",
                        fontSize: 16,
                        }}
                    />
                    <ChartLabel
                    text="Quetzales"
                    className="alt-y-label"
                    includeMargin={true}
                    xPercent={0.015}
                    yPercent={0.3}
                    style={{
                    textAnchor: "start",
                    fontWeight: "bold",
                    transform: 'rotate(-90)',
                    fontSize: 16,
                    }}
                    />
                    {this.state.vbs}
                    {hoveredCell ? (
                        <Hint
                            value={hoveredCell}
                            align={{horizontal: 'auto', vertical: 'auto'}}
                        >
                            <div className="hint">
                                <h5>{hoveredCell.tipo_documento}</h5>
                                <p>{hoveredCell.proyecto_extractivo}</p>
                                <p>{hoveredCell.label}</p>
                                <p>
                                    Q. <span>{addCommas((hoveredCell.y - (hoveredCell.y0 != null ? hoveredCell.y0 : 0 )).toFixed(2))}</span>
                                </p>
                            </div>
                        </Hint>
                    ) : null}
                </XYPlot>
                <DiscreteColorLegend
                    className="colorLegend"
                    width={myWidth}
                    items={this.state.legends}
                    orientation="vertical"
                />
                <SourceBox source={tooltips.source_mem} />
            </div>
        );
    }
}

import React, { useState }  from 'react';
import { loadModules } from 'esri-loader';
import {isMobile} from 'react-device-detect';
import axios from 'axios';
import styles from './ExportationsMap.module.css';
import { range } from 'd3';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Select from "react-select";
import SourceBox from "./SourceBox";
import TooltipModal from "./TooltipModal"
import tooltips from './assets/tooltips';

import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';

function addCommas(nStr)
{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : '#CCC',
        backgroundColor: '#1F2121'
    }),
    control: styles => ({
        ...styles,
        color: 'white',
        backgroundColor: '#1F2121',
        width: '120px'
    }),
    menu: styles => ({
        ...styles,
        backgroundColor: '#1F2121',
        border: '1px solid white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'white'
    })
}

const colorArray = [
    "#2b5676",
    "#a34d48",
    "#3e729e",
    "#526728",
    "#711f2f",
    "#326440",
    "#692f1c",
    "#2a6c69",
    "#8d5154",
    "#17324d",
    "#856030",
    "#17324d",
    "#514522",
    "#17324d",
    "#2a425f",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d",
    "#17324d"
];

const rounded = num => {
    if (num > 1000000000) {
        return Math.round(num / 100000000) / 10 + "Bn";
    } else if (num > 1000000) {
        return Math.round(num / 100000) / 10 + "M";
    } else {
        return Math.round(num / 100) / 10 + "K";
    }
};

export default class GovernanceExportationsMap extends React.Component {

    state = {
        dataset: [],
        // locale: "es"
    }

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.sliderRef = React.createRef();
    }

    async getData() {

        let res = await axios.get(`https://collector.oiegt.org/api/exportaciones`);

        const dataset = res.data.filter(function(obj) {
            obj.export_bruto = obj.export_bruto.filter(function(subobj) {
                if (subobj.destino[0]) {
                    return subobj;
                }
            }, []);
            obj.export_procesado = obj.export_procesado.filter(function(subobj) {
                if (subobj.destino[0]) {
                    return subobj;
                }
            }, []);
            if (obj.export_procesado.length > 0 || obj.export_bruto.length > 0) {
                return obj;
            }
        }, {});

        const amountsperyear = dataset.reduce(function(obj, v) {
            obj[v.anio] = (obj[v.anio] || []).concat(v);
            // return the updated object
            return obj;
        }, {});

        const years = Object.keys(amountsperyear).map(function(obj) {
            return obj;
        });

        const selectYears = years.map(function(obj) {
            const options = {};

            options.value = obj.toString();
            options.label = obj.toString();

            return options;
        });

        const separatedYear = this.filterYear(dataset, years[0]);

        var printables = [];

        this.setState({
            dataset: dataset,
            selectYears: selectYears,
            selectedYear: selectYears[0],
            separatedYear: separatedYear,
            printables: printables
        });

        // lazy load the required ArcGIS API for JavaScript modules and CSS
        loadModules([
            "esri/Map",
            "esri/views/ui/DefaultUI",
            "esri/views/MapView",
            "esri/layers/FeatureLayer",
            "esri/symbols/PictureMarkerSymbol",
            "esri/Graphic",
            "esri/geometry/Polyline",
            "esri/geometry/geometryEngine",
            "esri/geometry/support/geodesicUtils",
            "esri/layers/GraphicsLayer",
            "esri/config",
            "dojo/domReady!"
        ], { css: true })
        .then(([
            ArcGISMap,
            DefaultUI,
            MapView,
            FeatureLayer,
            PictureMarkerSymbol,
            Graphic,
            GraphicsLayer,
            Polyline,
            geometryEngine,
            geodesicUtils,
            esriConfig
        ]) => {
            esriConfig.request.corsDetection = false;

            this.map = new ArcGISMap({
                basemap: 'gray'
            });

            this.view = new MapView({
                container: this.mapRef.current,
                map: this.map,
                center: [-89.522713,16.628434], //Longitude, latitude
                zoom: 3,
                navigation: {
                    mouseWheelZoomEnabled: false,
                    browserTouchPanEnabled: false
                }
            });

            this.spatialReference = this.view.spatialReference;

            this.view.ui.move("zoom", "bottom-right");

            this.createLines(separatedYear);
        });

    }

    changeSelect(e) {
        let dataset = this.state.dataset;
        let year = parseInt(e.value);
        const separatedYear = this.filterYear(dataset, year);
        this.map.remove(this.graphicsLayer);
        this.createLines(separatedYear);
        this.setState({
            selectedYear: e,
            separatedYear: separatedYear
        })
    }

    filterYear(array, year) {
        const yearObj = array.filter(function(obj) {
            return obj.anio === year*1;
        }, {});

        return yearObj;
    }

    createLines(dataset) {

        loadModules([
            "esri/Map",
            "esri/views/ui/DefaultUI",
            "esri/views/MapView",
            "esri/layers/FeatureLayer",
            "esri/symbols/PictureMarkerSymbol",
            "esri/Graphic",
            "esri/layers/GraphicsLayer",
            "esri/geometry/Polyline",
            "esri/geometry/geometryEngine",
            "esri/geometry/support/geodesicUtils",
            "esri/config"
        ], { css: true })
        .then(([
            ArcGISMap,
            DefaultUI,
            MapView,
            FeatureLayer,
            PictureMarkerSymbol,
            Graphic,
            GraphicsLayer,
            Polyline,
            geometryEngine,
            geodesicUtils,
            esriConfig
        ]) => {

            this.graphicsLayer = new GraphicsLayer();

            this.map.add(this.graphicsLayer);

            const simpleLineSymbol = {
               type: "simple-line",
               width: 2
            };

            for (let i = 0; i < dataset.length; i++) {
                if (dataset[i].export_procesado.length > 0) {
                    for (let j = 0; j < dataset[i].export_procesado.length; j++) {

                        let exportation = dataset[i].export_procesado[j];

                        let id_der_minero = dataset[i].id_der_minero;
                        let producto_minero = exportation.producto_minero;
                        let cantidad_vendida = rounded(exportation.cantidad_vendida) + " " + exportation.cantidad_vendida_dimensional;
                        let valor_quetzales = exportation.valor_quetzales;
                        let destino = exportation.destino[0].name;

                        let attributes = {
                            "id_der_minero": id_der_minero,
                            "producto_minero": producto_minero,
                            "cantidad_vendida": cantidad_vendida,
                            "valor_quetzales": addCommas(valor_quetzales),
                            "destino": destino,
                            "tipo_de_exportacion": "Procesada"
                        }

                        simpleLineSymbol.color = colorArray[j];

                        let polyline = new Polyline({
                            paths: [
                                [-89.522713,14.628434], //Longitude, latitude
                                [exportation.destino[0].longitude, exportation.destino[0].latitude + (i * 0.2)] //Longitude, latitude
                            ],
                            spatialReference: this.spatialReference
                        });

                        let denseGeometry = geometryEngine.geodesicDensify(polyline, 10000)

                        let polylineGraphic = new Graphic({
                            attributes: attributes,
                            geometry: denseGeometry,
                            symbol: simpleLineSymbol,
                            popupTemplate: {
                                // autocasts as new PopupTemplate()
                                title: "Exportaciones",
                                content: [
                                    {
                                        type: "fields",
                                        fieldInfos: [
                                            {
                                                fieldName: "id_der_minero",
                                                label: "ID Derecho Minero"
                                            },
                                            {
                                                fieldName: "producto_minero",
                                                label: "Producto Minero"
                                            },
                                            {
                                                fieldName: "cantidad_vendida",
                                                label: "Cantidad Vendida"
                                            },
                                            {
                                                fieldName: "valor_quetzales",
                                                label: "Valor (Q)"
                                            },
                                            {
                                                fieldName: "destino",
                                                label: "Destino"
                                            },
                                            {
                                                fieldName: "tipo_de_exportacion",
                                                label: "Tipo de Exportación"
                                            }
                                        ]
                                    }
                                ]
                            }
                        });

                        this.graphicsLayer.add(polylineGraphic);
                    }
                }

                if (dataset[i].export_bruto.length > 0) {
                    console.log("gonna happen");
                    for (let j = 0; j < dataset[i].export_bruto.length; j++) {

                        let exportation = dataset[i].export_bruto[j];

                        let id_der_minero = dataset[i].id_der_minero;
                        let producto_minero = exportation.producto_minero;
                        let cantidad_vendida = rounded(exportation.cantidad_vendida);
                        let valor_quetzales = exportation.valor_quetzales;
                        let destino = exportation.destino[0].name;

                        let attributes = {
                            "id_der_minero": id_der_minero,
                            "producto_minero": producto_minero,
                            "cantidad_vendida": cantidad_vendida,
                            "valor_quetzales": addCommas(valor_quetzales),
                            "destino": destino,
                            "tipo_de_exportacion": "En bruto"
                        }

                        simpleLineSymbol.color = colorArray[j];

                        let polyline = new Polyline({
                            paths: [
                                [-89.522713,14.628434], //Longitude, latitude
                                [exportation.destino[0].longitude, exportation.destino[0].latitude + (i * 0.2)] //Longitude, latitude
                            ],
                            spatialReference: this.spatialReference
                        });

                        let denseGeometry = geometryEngine.geodesicDensify(polyline, 10000)

                        let polylineGraphic = new Graphic({
                            attributes: attributes,
                            geometry: denseGeometry,
                            symbol: simpleLineSymbol,
                            popupTemplate: {
                                // autocasts as new PopupTemplate()
                                title: "Exportaciones",
                                content: [
                                    {
                                        type: "fields",
                                        fieldInfos: [
                                            {
                                                fieldName: "id_der_minero",
                                                label: "ID Derecho Minero"
                                            },
                                            {
                                                fieldName: "producto_minero",
                                                label: "Producto Minero"
                                            },
                                            {
                                                fieldName: "cantidad_vendida",
                                                label: "Cantidad Vendida"
                                            },
                                            {
                                                fieldName: "valor_quetzales",
                                                label: "Valor (Q)"
                                            },
                                            {
                                                fieldName: "destino",
                                                label: "Destino"
                                            },
                                            {
                                                fieldName: "tipo_de_exportacion",
                                                label: "Tipo de Exportación"
                                            }
                                        ]
                                    }
                                ]
                            }
                        });

                        this.graphicsLayer.add(polylineGraphic);
                    }
                }
            }


        });
    }

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
        if (this.view) {
            // destroy the map view
            this.view.container = null;
        }
    }

    render() {
        let myWidth = 0;
        let myHeight = 0;
        let legendWidth = 0;

        if (isMobile) {
            myWidth = (window.innerWidth - 40);
            myHeight = (window.innerWidth - 40);
            legendWidth = window.innerWidth - 40;
        }
        else {
            myWidth = window.innerWidth - 80;
            myHeight = (window.innerWidth - 100) / 2;
            legendWidth = (window.innerWidth - 100) / 2;
        }

        const value = this.state.selectedYear;

        return (
            <div style={{position: 'relative'}}>
                <div className="project-module-header">
                    <h1 className="section-title white">Exportaciones</h1>
                    <TooltipModal title="Exportaciones" text={tooltips.tooltip_exportaciones} />
                    <Select
                        className="project-module-select"
                        styles={customStyles}
                        options={this.state.selectYears}
                        placeholder={"Año"}
                        value={value}
                        onChange={e => this.changeSelect(e)}
                    />
                </div>
                <div className={styles.webmap} ref={this.mapRef} />
                <SourceBox source={tooltips.source_mem} />
            </div>
        );
    }
}

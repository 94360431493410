import React from 'react';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  Hint,
  ChartLabel,
  DiscreteColorLegend,
} from 'react-vis';

import axios from 'axios';
import SourceBox from './SourceBox'
import tooltips from './assets/tooltips'
import TooltipModal from "./TooltipModal"
import {isMobile} from 'react-device-detect';
import './colorLegend.css';

function addCommas(nStr)
{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

const colorArray = [
    "#a34d48",
    "#2b5676",
    "#3e713e",
    "#526728",
    "#711f2f",
    "#326440",
    "#692f1c",
    "#2a6c69",
    "#8d5154",
    "#17324d",
    "#856030",
    "#17324d",
    "#514522",
    "#17324d",
    "#2a425f",
    "#17324d",
    "#17224d",
    "#f7324d",
    "#37324d",
    "#77324d",
    "#1a324d",
    "#12324d",
    "#13324d",
    "#1f324d",
    "#1c324d",
    "#fbb24d",
    "#27224d",
    "#17524d",
    "#37d24d",
    "#17324d"
];

function sortProperties(obj) {
  // convert object into array
    var sortable=[];
    for(var key in obj)
        if(obj.hasOwnProperty(key))
            sortable.push([key, obj[key]]); // each item is an array in format [key, value]

    // sort items by value
    sortable.sort(function(a, b)
    {
      return b[1] - a[1]; // compare numbers
    });

    return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
}

export default class CanonsStackbar extends React.Component {
    state = {
        dataset: [],
        years: [],
        highest: 0,
        vbs: [],
        printables: [],
        value: {x:null, y:null, label: ""},
        hoveredCell: false,
        legends: [],
    }

    async getData() {
        let res = await axios.get(`https://collector.oiegt.org/api/regalias-canones/${this.props.projectId}`);

        if (res.data.length > 0) {

            const dataset = res.data.filter(function(obj) {
                return obj.tipo_documento === "Canon de Superficie";
            }, {});

            const years = dataset.reduce(function(obj, v) {
                obj[v.anio] = (obj[v.anio] || 0) + 1;
                // return the updated object
                return obj;
            }, {});

            const amountsperyear = dataset.reduce(function(obj, v) {
                obj[v.anio] = (obj[v.anio] || []).concat(v);
                // return the updated object
                return obj;
            }, {});

            const amounts = Object.entries(amountsperyear);

            var sortedYears = sortProperties(years);

            var highest = sortedYears[0][1];

            var vbs = [];
            var printables = [];
            var x = Object.keys(amounts).length;
            var y = highest;
            let legend = new Set();
            let formattedLegends = [];
            let vbars = [];
            for (let i = 0; i < y; i++) {
                for (let j = 0; j < x; j++) {
                    if (amounts[j][1][i] != null) {
                        vbars.push({
                            x: amounts[j][1][i]["anio"],
                            y: amounts[j][1][i]["monto"],
                            label:amounts[j][1][i]["detalle"],
                            tipo_documento:amounts[j][1][i]["tipo_documento"],
                            color:""
                        });
                        legend.add(amounts[j][1][i]["detalle"])
                    }
                    else {
                        vbars.push({x: parseInt(amounts[j][0]), y: 0});
                    }
                }

                vbs.push(
                    <VerticalBarSeries
                        key={`s_vbs_${i}`}
                        data={vbars.slice()}
                        colorType="literal"
                        onValueMouseOver={v =>
                            this.setState({hoveredCell: v.x && v.y ? v : false})
                        }
                        onValueMouseOut={v =>
                            this.setState({hoveredCell: false})
                        }
                    />
                );
            }

            for (var i = 0; i < dataset.length; i++) {
                printables.push(
                    <div key={`prtbl_${i}`}>
                        <h2>{dataset[i].detalle}</h2>
                        <p>Monto: Q. {addCommas(dataset[i].monto.toFixed(2))}</p>
                        <p>Año: {dataset[i].anio}</p>
                    </div>
                );
            }

            const legends = Array.from(legend)

            formattedLegends = legends.map((x, index) => ({title: x !== null ? x : "Indefinido", color:colorArray[index], strokeWidth:10}))
            for (let i = 0; i < vbs.length; i++){
                for (let k = 0; k < vbs[i].props.data.length;k++){
                    for (let j = 0; j < formattedLegends.length; j++){
                        if (vbs[i].props.data[k].label === formattedLegends[j].title){
                            vbs[i].props.data[k].color = formattedLegends[j].color;
                        }
                    }
                }
            }

            this.setState({
                dataset: res.data,
                years: years,
                highest: highest,
                vbs: vbs,
                printables: printables,
                legends: formattedLegends,
            });

        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        let legendWidth = 0;
        if (isMobile) {
            legendWidth = window.innerWidth;
        }
        else {
            legendWidth = (window.innerWidth - 100) / 2;

        }
        const {hoveredCell} = this.state;
        const {vbs} = this.state;
        return (
            <div>
                <div className="project-module-header">
                    <h1 className="section-title white">Cánones</h1>
                    <TooltipModal title="Cánones" text={tooltips.tooltip_canones}  />
                </div>
                {vbs.length > 0 ?
                    (
                        <XYPlot
                            margin={{left: 100, top: 50, bottom: 50}}
                            width={window.innerWidth - 200}
                            height={window.innerHeight - 250}
                            stackBy="y"
                            xType="ordinal"
                            className="vis"
                        >
                            <VerticalGridLines />
                            <HorizontalGridLines />
                            {/* Cambios a los AXES para tener Labels VER DOC DE ChartLabel*/}
                            <XAxis />
                            <YAxis />
                            <ChartLabel
                            text="Año"
                            className="alt-x-label"
                            includeMargin={true}
                            xPercent={0.5}
                            yPercent={0.77}
                            style={{
                                textAnchor: "start",
                                fontWeight: "bold",
                                fontSize: 16,
                                }}
                            />
                            <ChartLabel
                            text="Quetzales"
                            className="alt-y-label"
                            includeMargin={true}
                            xPercent={0.015}
                            yPercent={0.3}
                            style={{
                            textAnchor: "start",
                            fontWeight: "bold",
                            transform: 'rotate(-90)',
                            fontSize: 16,
                            }}
                            />
                            {this.state.vbs}
                            {hoveredCell ? (
                                <Hint
                                    value={hoveredCell}
                                    align={{horizontal: 'auto', vertical: 'auto'}}
                                >
                                    <div className="hint">
                                        <h5>{hoveredCell.label}</h5>
                                        <p>{hoveredCell.tipo_documento}</p>
                                        <p>
                                            Q. <span>{addCommas((hoveredCell.y - (hoveredCell.y0 != null ? hoveredCell.y0 : 0 )).toFixed(2))}</span>
                                        </p>
                                    </div>
                                </Hint>
                            ) : null}
                        </XYPlot>
                    ) :
                    (
                        <div className="still-looking">
                            <img src={require('./assets/images/oie-stilllooking.svg')} />
                        </div>
                    )
                }
                {/* Agregando color Legend*/}
                <DiscreteColorLegend
                    className="colorLegend"
                    width={legendWidth}
                    items={this.state.legends}
                    orientation="vertical"
                />
                <SourceBox source={tooltips.source_mem} />
                <div className="printables">
                    {this.state.printables}
                </div>
            </div>
        );
    }
}

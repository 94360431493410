import React from 'react';
import {
  Link
} from "react-router-dom";
import logo from './assets/images/oie-logo.svg';
import './Header.css';
import './Hamburger.css';

import {isMobile} from 'react-device-detect';

export default class Header extends React.Component {
    scrollY = 0;
    ticking = false;

    state = {
        smallHeader: false
    }

    constructor(props) {
        super(props);
        this.header = React.createRef();
        this.mainNav = React.createRef();
        this.mainNavBtn = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    };

    handleScroll = () => {
        this.scrollY = window.scrollY;
        this.requestTick();
    }

    requestTick = () => {
        if (!this.ticking) {
            requestAnimationFrame(this.update);
        }
        this.ticking = true;
    }

    toggleNav = (refresh) => {
        const btn = this.mainNavBtn.current;
        const nav = this.mainNav.current;
        const header = this.header.current;
        //e.preventDefault();
        if (header.offsetWidth < 1350) {
            if (btn.classList.contains("is-active") === true) {
                btn.classList.remove("opened");
                btn.classList.add("closed");
                btn.classList.remove("is-active");
                nav.style.width = "0px";
            }
            else {
                btn.classList.remove("closed");
                btn.classList.add("opened");
                btn.classList.add("is-active");
                nav.style.width = "320px";
            }
        }
    }

    update = () => {
        this.ticking = false;
        if (this.scrollY > 200) {
            this.setState({smallHeader: true});
        }
        else {
            this.setState({smallHeader: false});
        }
    }

    render() {
        const {smallHeader} = this.state;

        return (
            <header id="masthead" className={`${smallHeader ? "small-header" : ""}`} ref={this.header} >
                <div className="site-branding">
                    <Link id="siteLogo" className="main-logo" to="/"><span>OIE</span></Link>
                </div>
                <nav className="main-navigation" ref={this.mainNav}>
                    <ul>
                        {/*
                        <li>
                            <Link to="/" onClick={this.toggleNav}>Inicio</Link>
                        </li>
                        */}
                        <li>
                            <Link to="/proyectos" onClick={this.toggleNav}>Geoportal</Link>
                        </li>
                        <li><p>Extractivismo <span className="arrow">▼</span></p>
                            <ul className="submenu">
                                <li>
                                    <Link to="/gobernanza" onClick={this.toggleNav}>Industria Extractiva</Link>
                                </li>
                                <li>
                                    <Link to="/gobernanza-corporativa" onClick={this.toggleNav}>Gobernanza Corporativa</Link>
                                </li>
                            </ul>
                        </li>
                        {/*
                        <li>
                            <Link to="/gobernanza-socioambiental" onClick={this.toggleNav}>Gobernanza Socioambiental</Link>
                        </li>
                        */}
                        <li>
                            <Link to="/monitoreo-comunitario" onClick={this.toggleNav}>Monitoreo Comunitario</Link>
                        </li>
                        <li>
                            <Link to="/biblioteca" onClick={this.toggleNav}>Biblioteca</Link>
                        </li>
                        {/* <li>
                            <Link to="/catalogo" onClick={this.toggleNav}>Catálogo</Link>
                            <a href="/catalogo">Catálogo</a>
                        </li> */}
                        <li>
                            <Link to="/acerca-de" onClick={this.toggleNav}>Acerca del OIE</Link>
                        </li>
                    </ul>
                </nav>
                <div className="menu-btn-wrapper" onClick={this.toggleNav} ref={this.mainNavBtn}>
                    <button id="menu_btn" className="c-hamburger c-hamburger--htx menu-btn closed" aria-controls="primary-menu" aria-expanded="false">
                        <span>menu</span>
                    </button>
                </div>
            </header>
      );
    }
}

import React, { useMemo } from 'react';
import axios from 'axios';
import {isMobile} from 'react-device-detect';
import { Line, Bar, BarStack } from '@visx/shape';
import { SeriesPoint } from '@visx/shape/lib/types';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import cityTemperature, { CityTemperature } from '@visx/mock-data/lib/mocks/cityTemperature';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { timeParse, timeFormat } from 'd3-time-format';
import { withTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { LegendOrdinal } from '@visx/legend';

import ParentSize from '@visx/responsive/lib/components/ParentSize';

import Select from "react-select";

import { GradientTealBlue, LinearGradient } from '@visx/gradient';

import TooltipModal from './TooltipModal';
import tooltips from './assets/tooltips'

const color1 = '#A5230E';
const color2 = '#E2281A';
const color3 = '#EDD926';
const color4 = '#111111';
const background = '#333333';
const background2 = '#964d18';
const accentColor = '#2eff8b';
const accentColorDark = '#75daad';
const defaultMargin = { top: 40, left: 120, right: 40, bottom: 140 };
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

function addCommas(nStr)
{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : '#CCC',
        backgroundColor: '#1F2121'
    }),
    control: styles => ({
        ...styles,
        color: 'white',
        backgroundColor: '#1F2121',
        width: '120px'
    }),
    menu: styles => ({
        ...styles,
        backgroundColor: '#1F2121',
        border: '1px solid white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'white'
    })
}

const olddata = cityTemperature.slice(0, 12);

export default class PetrolGeneralExportationsChart extends React.Component {
    constructor(props) {
      super(props);
    }

    state = {
        isLoaded: false,
        width: isMobile ? window.innerWidth - 40 : window.innerWidth - 100,
        height: 440,
        selectYears: [],
        selectedYear: {}
    }

    async getData() {

        let res = await axios.get(`https://collector.oiegt.org/api/exportacion-petroleo`);

        Promise.all([res]).then((responses) => {
            if (res.data.length > 0) {
                const keys = Object.keys(responses[0].data[0]).filter(function(d) {
                    if (d !== 'mes' && d !== 'total') {
                        return d;
                    }
                });

                let data = responses[0].data.map(function(item) {
                    let newItem = {}
                    keys.forEach(function(key) {
                        newItem[key] = item[key];
                    });
                    return newItem;
                });

                let years = [...new Set(data.map(item => item.anio))];
                years.sort();

                const selectYears = years.map(function(obj) {
                    const options = {};

                    options.value = obj.toString();
                    options.label = obj.toString();

                    return options;
                });

                this.selectYear(responses[0].data, years[years.length-1]);
                this.setState({
                    dataset: responses[0].data,
                    selectYears: selectYears,
                    selectedYear: selectYears[selectYears.length-1],
                });
            }
            this.setState({
                isLoaded: true
            });
        });
    }

    selectYear(dataset, year) {
        let finalData = [];

        const keys = Object.keys(dataset[0]).filter(function(d) {
            if (d !== 'mes' && d !== 'total') {
                return d;
            }
        });

        let data = dataset.map(function(item) {
            let newItem = {}
            keys.forEach(function(key) {
                newItem[key] = item[key];
            });
            return newItem;
        });


        let years = [...new Set(data.map(item => item.anio))];
        years.sort();

        years.forEach(function(year) {
            let singleYear = data.filter(function(item) {
                if (item.anio === year) {
                    return item;
                }
            });
            let newItem = {
                anio: year
            }
            keys.forEach(function(key) {
                if (key !== "anio") {
                    let singleYearProfit = singleYear.reduce((acc, obj) => {
                        acc += Number(obj[key]);
                        return acc;
                    }, 0);
                    newItem[key] = singleYearProfit;
                }
            });

            finalData.push(newItem);
        });

        let selectedYear = this.filterYear(finalData, year);

        let newFinalData = [];
        newFinalData.push(finalData[finalData.length-1]);


        let pairedData = [];

        keys.forEach(function(key) {
            if (key !== "anio" && key !== "mes" && key !== "total") {
                let newItem = {}
                newItem.category = key;
                // newItem.quantity = finalData[finalData.length-1][key];
                newItem.quantity = selectedYear[0][key];
                pairedData.push(newItem);
            }
        });

        this.setState({
            finalData: pairedData
        });
    }

    componentDidMount() {
        this.getData();
    }

    filterYear(array, year) {
        const yearObj = array.filter(function(obj) {
            return obj.anio === year;
        }, {});

        return yearObj;
    }

    changeSelect(e) {
        let dataset = this.state.dataset;
        let year = parseInt(e.value);
        this.selectYear(dataset, year);
        // const separatedYear = this.filterYear(dataset, year);
        this.setState({
            selectedYear: e,
            // separatedYear: separatedYear
        })
    }

    render () {
        if (this.state.isLoaded && this.state.finalData) {
            if (this.state.dataset.length > 0) {
                return (
                    <div>
                        <div className="project-module-header">
                            <h1 className="section-title white">Exportaciones Petróleo</h1>
                                <Select
                                    className="project-module-select"
                                    styles={customStyles}
                                    options={this.state.selectYears}
                                    placeholder={"Año"}
                                    value={this.state.selectedYear}
                                    onChange={e => this.changeSelect(e)}
                                />
                        </div>
                        <ParentSize>
                            {({ width, height }) => {
                                return (
                                    <ChartWithTooltip data={this.state.finalData} width={width} height={400} selectYears={this.state.selectYears} selectedYear={this.state.selectedYear} />
                                );
                            }}
                        </ParentSize>
                    </div>
                )

            }
            else {
                return (
                    <div className="still-looking">
                        <img src={require('./assets/images/oie-stilllooking.svg')} />
                    </div>
                )
            }
        }
        else {
            return (
                <div>Cargando...</div>
            )
        }
    }
}


const ChartWithTooltip = withTooltip(
    ({
        data,
        width,
        height,
        events = true,
        selectYears,
        selectedYear,
        tooltipOpen,
        tooltipLeft,
        tooltipTop,
        tooltipData,
        hideTooltip,
        showTooltip,
    }) => {

        let tooltipTimeout;

        let margin;

        // bounds
        if (width > 900) {
            margin = { top: 40, left: 100, right: 100, bottom: 120 };
        }
        else {
            margin = { top: 40, left: 50, right: 50, bottom: 140 };
        }

        let xMax = width - margin.left - margin.right;
        let yMax = height - margin.top - margin.bottom;

        const getCategory = (d) => d.category;
        const getQuantity = (d) => Number(d.quantity);

        // scales, memoize for performance
        const xScale = useMemo(
            () =>
            scaleBand({
                range: [0, xMax],
                domain: data.map(getCategory),
                padding: 0.4,
            }),
            [xMax],
        );

        const yScale = useMemo(
            () =>
            scaleLinear({
                range: [yMax, 0],
                round: true,
                nice: true,
                // domain: [0, Math.max(...data.map(getQuantity))],
                domain: [0, 20000000],
            }),
            [yMax],
        );

        return width < 10 ? null : (
            <div>
                <svg width={width} height={height}>
                    <rect width={width} height={height} fill="url(#area-background-gradient)" rx={14} />
                    <LinearGradient id="area-background-gradient" from={background} to={background2} />
                    <LinearGradient id="bar-gradient" from={color1} to={color4} toOpacity={1} />
                    <Group top={margin.top} left={margin.left}>

                        {data.map(d => {
                            const category = getCategory(d);
                            const barWidth = xScale.bandwidth();
                            const barHeight = yMax - (yScale(getQuantity(d)) ?? 0);
                            const barX = xScale(category);
                            const barY = yMax - barHeight;
                            return (
                                <Bar
                                    key={`bar-${category}`}
                                    x={barX}
                                    y={barY}
                                    width={barWidth}
                                    height={barHeight}
                                    fill="url(#bar-gradient)"
                                    onMouseLeave={() => {
                                        tooltipTimeout = window.setTimeout(() => {
                                            hideTooltip();
                                        }, 300);
                                    }}
                                    onMouseMove={() => {
                                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                                        showTooltip({
                                            tooltipData: d,
                                            tooltipTop: barY + 50,
                                            tooltipLeft: barX + 100,
                                        });
                                    }}
                                    />
                            );
                        })}

                        <AxisBottom
                            top={yMax}
                            scale={xScale}
                            stroke={color3}
                            tickValues={data.map(getCategory)}
                            >
                            {props => {
                                let rotate, anchor;
                                if (width > 900) {
                                    rotate = 45;
                                    anchor = "start";
                                }
                                else {
                                    rotate = 90;
                                    anchor = "start";
                                }
                                const tickLabelSize = 10;
                                const tickRotate = rotate;
                                const tickColor = '#8e205f';
                                const axisCenter = (props.axisToPoint.x - props.axisFromPoint.x) / 2;
                                return (

                                    <g className="my-custom-bottom-axis">
                                        <line class="visx-line visx-axis-line" x1={props.axisFromPoint.x} y1="0" x2={props.axisToPoint.x} y2="0" fill="transparent" shape-rendering="crispEdges" stroke={color4} stroke-width="1"></line>
                                        {props.ticks.map((tick, i) => {
                                            const tickX = tick.to.x;
                                            const tickY = tick.to.y + tickLabelSize + props.tickLength;
                                            return (
                                                <Group
                                                    key={`vx-tick-${tick.value}-${i}`}
                                                    className={'vx-axis-tick'}
                                                    >
                                                    <Line
                                                        from={tick.from}
                                                        to={tick.to}
                                                        stroke={tickColor}
                                                        />
                                                    <text
                                                        transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                                                        fontSize={tickLabelSize}
                                                        textAnchor={anchor}
                                                        fill={tickColor}
                                                        >
                                                        {tick.formattedValue}
                                                    </text>
                                                </Group>
                                            );
                                        })}
                                        <text
                                            textAnchor="middle"
                                            transform={`translate(${axisCenter}, 50)`}
                                            fontSize="8"
                                            >
                                            {props.label}
                                        </text>
                                    </g>
                                );
                            }}
                        </AxisBottom>

                        <AxisLeft
                            left={0}
                            label={"Barriles"}
                            labelOffset={70}
                            scale={yScale}
                            >
                            {props => {
                                let rotate, label, tickLabelSize;
                                const tickColor = '#8e205f';
                                const axisCenter = (props.axisFromPoint.y - props.axisToPoint.y) / 2 * -1;

                                if (width > 900) {
                                    tickLabelSize = 10;
                                    rotate = 0;
                                    label =
                                    <text
                                        textAnchor="middle"
                                        x={axisCenter}
                                        y={-82}
                                        transform={`rotate(${-90})`}
                                        fontSize="12"
                                        >
                                        {props.label}
                                    </text>
                                }
                                else {
                                    tickLabelSize = 8;
                                    rotate = -45;
                                    label =
                                    <text
                                        textAnchor="end"
                                        x={0}
                                        y={-10}
                                        fontSize="10"
                                        >
                                        {props.label}
                                    </text>
                                }

                                const tickRotate = rotate;

                                return (

                                    <g className="my-custom-bottom-axis">
                                        <line class="visx-line visx-axis-line" x1="0" y1={props.axisFromPoint.y} x2="0" y2={props.axisToPoint.y} fill="transparent" shape-rendering="crispEdges" stroke={color4} stroke-width="1"></line>
                                        {props.ticks.map((tick, i) => {
                                            const tickX = -8;
                                            // const tickY = tick.to.y + (props.axisFromPoint.y / props.numTicks);
                                            const tickY = tick.to.y;
                                            return (
                                                <Group
                                                    key={`vx-tick-${tick.value}-${i}`}
                                                    className={'vx-axis-tick'}
                                                    >
                                                    <Line
                                                        from={tick.from}
                                                        to={tick.to}
                                                        stroke={tickColor}
                                                        />
                                                    <text
                                                        transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                                                        fontSize={tickLabelSize}
                                                        textAnchor="end"
                                                        fill={tickColor}
                                                        >
                                                        {tick.formattedValue}
                                                    </text>
                                                </Group>
                                            );
                                        })}
                                        {label}
                                    </g>
                                );
                            }}
                        </AxisLeft>

                    </Group>
                </svg>
                <div
                    style={{
                        position: 'absolute',
                        top: margin.top / 2 - 10,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '14px',
                    }}
                    >
                </div>
                {tooltipOpen && tooltipData && (
                    <Tooltip className="visxTooltip" top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
                        <div style={{ color: color1 }}>
                            <strong>Barriles</strong>
                        </div>
                        <div>{addCommas(tooltipData.quantity.toFixed(2))}</div>
                    </Tooltip>
                )}
            </div>
        );
    },
);

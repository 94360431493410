import React from 'react';
import Timeline from './Timeline';
import axios from 'axios';
import './ProjectInformation.css';
import logo from './assets/images/oie-logo.svg';

import TooltipModal from './TooltipModal';
import tooltips from './assets/tooltips';

function addCommas(nStr)
{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

export default class ProjectInformation extends React.Component {
    state = {
        dataset: [],
        cuencas: [],
        indicator: []
    };

    async getData() {

        let cuencas = [];
        let municipios = [];
        let departamentos = [];

        if (this.props.projectType === "Minero") {

            let res = await axios.get(`https://collector.oiegt.org/api/proyecto-extractivo_/${this.props.projectId}`);
            let timeline = await axios.get(`https://collector.oiegt.org/api/linea-tiempo/${this.props.projectId}`);

            console.log("are we here?");

            Promise.all([res, timeline]).then((responses) => {
                this.setState({
                    dataset: responses[0].data
                });
                if (responses[1].data.length > 0) {
                    this.setState({
                        timeline: responses[1].data
                    });
                }
            });

            if (res.data.id_cuenca != null) {
                for (let i = 0; i < res.data.id_cuenca.length; i++) {
                    cuencas.push(<p key={`c_${i}`}>{ res.data.id_cuenca[i] }</p>);
                }
            }

            if (res.data.id_municipio != null) {
                for (let i = 0; i < res.data.id_municipio.length; i++) {
                    municipios.push(<p key={`m_${i}`}>{ res.data.id_municipio[i] }</p>);
                }
            }

            if (res.data.id_departamentos != null) {
                for (let i = 0; i < res.data.id_departamentos.length; i++) {
                    departamentos.push(<p key={`d_${i}`}>{ res.data.id_departamentos[i] }</p>);
                }
            }
        }
        else {

            let res = await axios.get(`https://collector.oiegt.org/api/proyecto-extractivo-petrolero_/${this.props.projectId}`);
            let timeline = await axios.get(`https://collector.oiegt.org/api/linea-tiempo-petroleo/${this.props.projectId}`);
            let indicator = await axios.get(`https://collector.oiegt.org/api/indicador-petroleo/${this.props.projectId}`,  { validateStatus: false });

            let calls = [res, timeline, indicator];

            let campos_petroleros = [];

            Promise.all(calls)
            .then((responses) => {
                console.log("indicator", responses[2].data);
                this.setState({
                    dataset: responses[0].data,
                    indicator: responses[2].data
                });
                if (responses[1].data.length > 0) {
                    this.setState({
                        timeline: responses[1].data
                    });
                }
            });

            if (res.data.id_cuenca != null) {
                for (let i = 0; i < res.data.id_cuenca.length; i++) {
                    cuencas.push(<p key={`c_${i}`}>{ res.data.id_cuenca[i].name }</p>);
                }
            }

            if (res.data.id_municipio != null) {
                for (let i = 0; i < res.data.id_municipio.length; i++) {
                    municipios.push(<p key={`m_${i}`}>{ res.data.id_municipio[i].name }</p>);
                }
            }

            if (res.data.id_departamentos != null) {
                for (let i = 0; i < res.data.id_departamentos.length; i++) {
                    departamentos.push(<p key={`d_${i}`}>{ res.data.id_departamentos[i].name }</p>);
                }
            }

            if (res.data.campos_petroleros != null) {
                for (let i = 0; i < res.data.campos_petroleros.length; i++) {
                    let municipios = [];
                    let departamentos = [];

                    for (let j = 0; j < res.data.campos_petroleros[i].id_municipio.length; j++) {
                        municipios.push(<p key={`m_${j}`}>{ res.data.campos_petroleros[i].id_municipio[j].name }</p>);
                    }

                    for (let j = 0; j < res.data.campos_petroleros[i].id_departamentos.length; j++) {
                        departamentos.push(<p key={`m_${j}`}>{ res.data.campos_petroleros[i].id_departamentos[j].name }</p>);
                    }

                    campos_petroleros.push(
                        <div>
                            <p key={`d_${i}`}>{ res.data.campos_petroleros[i].name }</p>
                            <div class="project-information-subcontent">
                                <h3>Municipios</h3>
                                { municipios }
                                <h3>Departamentos</h3>
                                { departamentos }
                            </div>
                        </div>
                    );
                }
            }

            this.setState({
                // dataset: res.data,
                campos_petroleros: campos_petroleros
                // timeline: timeline.data
            });

        }

        this.setState({
            // dataset: res.data,
            cuencas: cuencas,
            municipios: municipios,
            departamentos: departamentos
            // timeline: timeline.data
        });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        let projectType = this.props.projectType;
        let isSolicitud = this.props.isSolicitud;
        const data = this.state.dataset;
        console.log("ProjectInformation Data", data);
        const cuencas = this.state.cuencas;
        const municipios = this.state.municipios;
        const departamentos = this.state.departamentos;
        const campos_petroleros = this.state.campos_petroleros;

        const indicator = this.state.indicator;

        let timeline;

        let eias = [];

        if (data.estudio_impacto_ambiental) {
            console.log("are we even here?");
            if (this.props.licenseType === "Exploración") {
                console.log("Exploración")
            }
            else {
                console.log("it's an else");
                console.log("length", data.estudio_impacto_ambiental.length);
                for (let i = 0; i < data.estudio_impacto_ambiental.length; i++) {
                    console.log("for EIA", data.estudio_impacto_ambiental[i]);
                    eias.push(
                        <div className="project-information-content">
                            <div className="project-information-subcontent">
                                <h3>ID EIA</h3>
                                <p>{ data.estudio_impacto_ambiental[i].id_eia }</p>
                                <h3>Fecha de aprobación EIA</h3>
                                <p>{ data.estudio_impacto_ambiental[i].fecha_aprobacion_eia ? data.estudio_impacto_ambiental[i].fecha_aprobacion_eia : "N/D" }</p>
                            </div>
                            <div className="project-information-subcontent">
                                <h3>Consultora Ambiental</h3>
                                <p>{ data.estudio_impacto_ambiental[i].id_consultora }</p>
                            </div>
                        </div>
                    )
                }
            }
        }

        console.log("eias", eias);

        if (this.state.timeline) {
            timeline = <Timeline projectId={this.props.projectId} dataset={this.state.timeline} />;
        }

        if (projectType === "Minero") {
            return (
                <div className="projectInformationWrapper">
                    <img src={logo} className="printables-logo" alt="logo" />
                    <div className="projectInformation">
                        <div className="projectInformationPrimary">
                            <div className="project-information-content">
                                <h1>{ data.nombre_derecho_minero }</h1>
                                <div>
                                    <h3>Código MEM</h3>
                                    {isSolicitud ? <p className="content-large">{ data.id_display_solicitud }</p> : <p className="content-large">{ data.id_derecho_minero }</p>}
                                </div>
                                <div>
                                    <h3>Estatus</h3>
                                    <p className="content-large uppercase">{ this.state.dataset.status }</p>
                                    <h3>Empresa Transnacional</h3>
                                    <p>{ data.id_transnacional }</p>
                                    <h3>Empresa Nacional</h3>
                                    <p>{ data.id_nacional }</p>
                                    <h3>Área total</h3>
                                    <p>{ data.area_km2 } KM&sup2;</p>
                                </div>
                                <div className="project-information-subcontent">
                                    <h3>Tipo de licencia</h3>
                                    <p>{ data.tipo_licencia }</p>
                                    <h3>Fecha de solicitud</h3>
                                    <p>{ data.fecha_solicitud }</p>
                                    {!isSolicitud ? <h3>Fecha de otorgamiento</h3> : null}
                                    {!isSolicitud ? <p>{ data.fecha_otorgamiento }</p> : null}
                                    <h3>Departamentos</h3>
                                    <div>
                                        { departamentos }
                                    </div>
                                    <h3>Municipios</h3>
                                    <div>
                                        { municipios }
                                    </div>
                                    <h3>Cuencas</h3>
                                    <div>
                                        { cuencas }
                                    </div>
                                </div>
                                <div className="project-information-subcontent">
                                </div>
                                <div className="project-information-subcontent">
                                </div>
                            </div>
                        </div>
                        <div className="projectInformationEia">
                            <h2>{this.props.licenseType === "Exploración" ? "Datos de Estudio Ambiental Inicial" : "Evaluación de Impacto Ambiental"}</h2>
                            <div className="project-information-content">
                                <div className="project-information-subcontent">
                                    <h3>Número de expediente MARN</h3>
                                    <p>{ data.expediente_marn }</p>
                                </div>
                            </div>
                            <p><strong>EIAs</strong></p>
                            { eias }
                        </div>
                    </div>
                    <div className="projectTimeline">
                        {timeline}
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="projectInformationWrapper">
                    <img src={logo} className="printables-logo" alt="logo" />
                    <div className="projectInformation">
                        <div className="project-information-content">
                            <h1>{ data.id_proyecto }<TooltipModal title="Hidrocarburos" text={tooltips.tooltip_petrolero} /></h1>
                            <div>
                                <h3>Código MEM</h3>
                                <p className="content-large">{ data.id_contrato }</p>
                            </div>
                            <div>
                                <h3>Estatus</h3>
                                <p className="content-large uppercase">{ data.status }</p>
                                <h3>Empresa Nacional</h3>
                                <p>{ data.id_nacional }</p>
                            </div>
                            <div className="project-information-subcontent">
                                <h3>Tipo de licencia</h3>
                                <p>{ data.tipo_licencia }</p>
                                <h3>Fecha de inicio</h3>
                                <p>{ data.fecha_inicio }</p>
                                <h3>Fecha de caducidad</h3>
                                <p>{ data.fecha_caducidad }</p>
                            </div>
                            <div>
                                <h3>Campos Petroleros</h3>
                                { campos_petroleros }
                            </div>
                            <div className="project-information-subcontent">

                            </div>
                        </div>
                    </div>
                    <div className="projectTimeline">
                        {timeline}
                    </div>
                    <div className="petrolEarnings">
                        <div>
                            <h2>Ingresos totales</h2>
                            <p>{indicator.ingresos ? `USD ${addCommas(Number(indicator.ingresos).toFixed(2))}` : "-"}</p>
                        </div>
                        <div>
                            <h2>Costos recuperables</h2>
                            <p>{indicator.costos ? `USD ${addCommas(Number(indicator.costos).toFixed(2))}` : "-"}</p>
                        </div>
                        <div>
                            <h2>Relación costos / ingresos</h2>
                            <p>{indicator.indicador ? `USD ${addCommas(Number(indicator.indicador).toFixed(2))}` : "-"}</p>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
